const BusinessType = {
    CORPORATION: 'corporation',
    BUSINESS_INDIVIDUAL: 'businessIndividual',
    PERSON: 'person',
    FOREIGN_ENTITY: 'foreignEntity',
    INVESTMENT_FUND: 'investmentFund',
    RUS_STATE: 'rusState',
    RUS_REGION: 'rusRegion',
    RUS_MUNIC: 'rusMunicip',
    UNKNOWN: 'unknown'
};

export default BusinessType;
