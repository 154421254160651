import { parseDateFromNullableString } from '../utils/utils';
import LicenseAndTrademarkValidity from './LicenseAndTrademarkValidity'; // Assuming this is an existing enum

class Trademark {
    constructor({
        number,
        link,
        issueDate,
        validTill,
        imageLink,
        validity = LicenseAndTrademarkValidity.undefined,
    }) {
        this.number = number;
        this.link = link;
        this.issueDate = issueDate;
        this.validTill = validTill;
        this.imageLink = imageLink;
        this.validity = validity;
    }

    static fromJson(jsonData) {
        return new Trademark({
            number: jsonData['ID'].toString(),
            link: jsonData['URL'],
            issueDate: parseDateFromNullableString(jsonData['ДатаРег']),
            validTill: parseDateFromNullableString(jsonData['ДатаОконч']),
            imageLink: jsonData['imageLink'] || null,
        });
    }
}

export default Trademark;
