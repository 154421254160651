// components/AnalysisSection.js
import React from 'react';
import { Container, Grid, Typography, Box, Paper, Divider } from '@mui/material';

function AnalysisSection() {
    return (
        <Box sx={{ backgroundColor: 'white', py: 4 }}>
            <Container maxWidth="lg">
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <Box sx={{ height: '20px' }} /> {/* Spacer */}
                    </Grid>
                    <Grid item xs={12} md={7}>
                        <Typography variant="body1" gutterBottom>
                            «Уже более 10 лет мы разрабатываем и совершенствуем методики анализа банковских выписок
                            при проведении финансового анализа, проверке сделок и due diligence.
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            Проверенные и успешно работающие методики мы запаковали
                            в онлайн-сервис на базе ИИ, а опытные аналитики готовы
                            включиться в конкретный кейс при необходимости.
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            ФинРадар анализирует банковские выписки
                            за считанные минуты, сверяет с данными в разных источниках,
                            обеспечивая объективные результаты
                            даже при отсутствии других учетных документов».
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={5}>
                        <Paper elevation={0} sx={{ display: 'flex', alignItems: 'center', p: 2 }}>
                            <img
                                src="https://brpadvice.ru/wp-content/uploads/2019/03/photo-new.jpg"
                                alt="Виктор Рыбцев, финансовое моделирование"
                                style={{ width: '100%', maxWidth: '150px', marginRight: '20px' }}
                            />
                            <Box>
                                <Typography variant="h6">Виктор Рыбцев</Typography>
                                <Typography variant="body2">
                                    Руководитель BRP ADVICE. Автор методики анализа. Кандидат экономических наук. MBA, MiBA.
                                </Typography>
                            </Box>
                        </Paper>
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={{ height: '20px' }} /> {/* Spacer */}
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
}

export default AnalysisSection;
