import React from 'react';
import { Field } from 'formik';
import { myLaunchURL } from '../../utils/utils';

const TermsCheckbox = ({ agreedToTerms, toggleAgreedToTerms }) => (
    <div className="terms">
        <label>
            <Field type="checkbox" name="agreedToTerms" checked={agreedToTerms} onChange={toggleAgreedToTerms} className='pointer' />
            Принимаю условия&nbsp;<span onClick={() => myLaunchURL({ link: 'urlEULA' })}>соглашения</span>&nbsp;и&nbsp;<span onClick={() => myLaunchURL({ link: 'urlPrivacyPolicy' })}>политику ПД</span>
        </label>
        {!agreedToTerms && <div className="error-message">Согласие обязательно</div>}
    </div>
);

export default TermsCheckbox;
