import React from 'react';
import { Pie, Bar } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Box, Typography, Grid, Table, TableBody, TableCell, TableContainer, TableRow, Paper, TablePagination } from '@mui/material';
import { formatDoubleToPaddedString, shorthenOrgTypes } from '../../../utils/utils';

// Register necessary chart.js components
ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement, ChartDataLabels);

const CounterpartiesWidget = ({ counterparties, direction, totals, pagination }) => {
    if (!counterparties || !counterparties.length) {
        return <Typography variant="body2">Нет данных для отображения</Typography>;
    }

    // Calculate total values based on direction
    const totalValue = direction === 'Inflows'
        ? totals?.TotalInflows || 0
        : direction === 'Outflows'
            ? totals?.TotalOutflows || 0
            : totals?.TotalSumflows || 0;

    // Colors for charts and legend
    const colors = [
        '#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF',
        '#FF9F40', '#FFCD56', '#C9CBCF', '#4D5360', '#36A2EB',
        '#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF',
        '#FF9F40', '#FFCD56', '#C9CBCF', '#4D5360', '#36A2EB',
        '#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF',
        '#FF9F40', '#FFCD56', '#C9CBCF', '#4D5360', '#36A2EB',
    ];

    // Prepare data for charts
    const selectedDataValues = counterparties.map((counterparty) =>
        direction === 'Inflows' ? counterparty.Inflows :
            direction === 'Outflows' ? counterparty.Outflows :
                counterparty.Sumflows
    );
    const selectedLabels = counterparties.map((counterparty) =>
        shorthenOrgTypes(
            ((counterparty.Counterparty && counterparty.Counterparty != 'ИНН не указан') ? counterparty.Counterparty + ' ' : '')
            + ' '
            + (counterparty.CounterpartyIdentifier ?
                (
                    (counterparty.Counterparty && counterparty.Counterparty != 'ИНН не указан')
                        ? `(ИНН ${counterparty.CounterpartyIdentifier})`
                        : `${counterparty.CounterpartyIdentifier} (ИНН не указан)`
                )
                : '')
        )

    );

    // Calculate "Другие контрагенты" as remaining total amount
    const selectedTotal = selectedDataValues.reduce((sum, value) => sum + value, 0);
    const otherValue = totalValue - selectedTotal;

    // Final data values and labels for "Другие контрагенты" category
    const dataValues = otherValue > 0 ? [...selectedDataValues, otherValue] : selectedDataValues;
    const labels = otherValue > 0 ? [...selectedLabels, 'Другие контрагенты'] : selectedLabels;
    const backgroundColors = otherValue > 0 ? [...colors.slice(0, selectedDataValues.length), '#CCCCCC'] : colors;

    // Chart data configurations
    const pieData = {
        labels,
        datasets: [
            {
                data: dataValues,
                backgroundColor: backgroundColors,
                hoverBackgroundColor: backgroundColors,
            },
        ],
    };

    const barData = {
        labels,
        datasets: [
            {
                data: dataValues.map(value => (value / totalValue) * 100),
                backgroundColor: backgroundColors,
                hoverBackgroundColor: backgroundColors,
            },
        ],
    };

    // Chart options
    const chartOptions = {
        plugins: {
            tooltip: {
                callbacks: {
                    label: (tooltipItem) => {
                        const label = tooltipItem.label || '';
                        const value = dataValues[tooltipItem.dataIndex];
                        const formattedValue = formatDoubleToPaddedString({ number: value, roundDigits: 2 });
                        return `${label}: ${formattedValue}`;
                    }
                }
            },
            legend: { display: false },
            datalabels: { display: false },
        },
        responsive: true,
        maintainAspectRatio: false,
    };

    const barOptions = {
        ...chartOptions,
        indexAxis: 'y',
        scales: {
            x: {
                max: 100,
                title: {
                    display: true,
                    text: 'Процент от потока'
                },
                ticks: {
                    callback: (value) => `${value}%`
                },
            },
            y: {
                ticks: { display: false },
                title: { display: false },
            }
        },
        plugins: {
            ...chartOptions.plugins,
            datalabels: {
                anchor: 'end',
                align: 'end',
                formatter: (value) => `${value.toFixed(1)}%`,
                color: '#555',
                font: {
                    size: 10,
                    weight: 'bold',
                }
            }
        }
    };

    return (
        <Box sx={{ width: '100%', py: 3, px: 0, my: 0, mx: 0 }}>
            <Typography variant="h6" align="start" sx={{ mx: 3, mb: 3 }}>
                {direction === 'Inflows' ? 'Визуализация поступлений'
                    : direction === 'Outflows' ? 'Визуализация платежей'
                        : 'Визуализация суммарных оборотов'}
            </Typography>
            <Grid container spacing={2} justifyContent="center" alignItems="start" sx={{ px: 4 }}>
                {/* Bar Chart */}
                <Grid item xs={12} md={4}>
                    <Box sx={{ width: '100%', height: 400 }}>
                        <Bar data={barData} options={barOptions} />
                    </Box>
                </Grid>

                {/* Pie Chart */}
                <Grid item xs={12} md={4}>
                    <Box sx={{ width: '100%', height: 400 }}>
                        <Pie data={pieData} options={chartOptions} />
                    </Box>
                </Grid>

                {/* Legend Table with pagination */}
                <Grid item xs={12} md={4}>
                    <TableContainer component={Paper}>
                        <Table stickyHeader>
                            <TableBody>
                                {labels.map((label, index) => (
                                    <TableRow key={index}>
                                        <TableCell sx={{ width: '32px', px: 0, py: '6px' }}>
                                            <Box sx={{ width: '16px', height: '10px', mx: '8px', bgcolor: backgroundColors[index] }} />
                                        </TableCell>
                                        <TableCell sx={{ width: '=calc( 100% - 24px)', px: 0, fontSize: 'x-small', my: '0px', py: '0px' }}>{label}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                        <TablePagination
                            component="div"
                            count={pagination.count}
                            rowsPerPage={pagination.rowsPerPage}
                            page={pagination.page}
                            onPageChange={pagination.onPageChange}
                            rowsPerPageOptions={pagination.rowsPerPageOptions}
                        />
                    </TableContainer>
                </Grid>
            </Grid>
        </Box>
    );
};

export default CounterpartiesWidget;
