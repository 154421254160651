import React from 'react';
import CircleLoader from "react-spinners/CircleLoader";

const SubmitButton = ({ isSubmitting, loading, authState, className }) => (
    loading ? (
        <div className='spinner-div'>
            <CircleLoader color="#8A4B9A" className='spinner' size={45} />
        </div>
    )
        :
        (<button type="submit" disabled={isSubmitting || loading} className={className} >
            {authState === 'signIn' ? 'Войти'
                : authState === 'signUp' ? 'Зарегистироваться'
                    : authState === 'verifyEmail' ? 'Подтвердить email'
                        : authState === 'recoveryRequest' ? 'Восстановить пароль'
                            : authState === 'passwordReset' ? 'Создать пароль'
                                : 'Готово'}
        </button>)
);

export default SubmitButton;
