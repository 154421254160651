import React, { createContext, useState, useContext } from 'react';
import { getToken } from '../utils/storage';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(!!getToken());
    const [intendedUrl, setIntendedUrl] = useState('/search');

    const login = () => {
        setIsAuthenticated(true);
        const targetUrl = intendedUrl || '/search';
        setIntendedUrl('/');
        window.location.href = targetUrl; // Redirect after login
    };

    const logout = () => {
        setIsAuthenticated(false);
        setIntendedUrl('/');
    };

    const setIntended = (url) => {
        if (url.includes('signin')) {
            return
        }
        setIntendedUrl(url)
    };

    return (
        <AuthContext.Provider value={{ isAuthenticated, login, logout, setIntended }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);
