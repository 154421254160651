import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    components: {
        MuiTypography: {
            styleOverrides: {
                h3: ({ ownerState }) => ({
                    ...({
                        fontSize: 'x-large',
                    }),
                }),
                h4: ({ ownerState }) => ({
                    ...({
                        fontSize: 'large',
                    }),
                }),
            },
        },
        MuiAppBar: {
            styleOverrides: {
                root: ({ ownerState }) => ({
                    ...({
                        backgroundColor: '#f7f7f7',
                        color: '#202020',
                    }),
                }),
            },
        },
        MuiSwipeableDrawer: {
            styleOverrides: {
                root: ({ ownerState }) => ({
                    ...({
                        backgroundColor: '#f7f7f7',
                        color: '#202020',
                    }),
                }),
            },
        },
        MuiDrawer: {
            styleOverrides: {
                root: ({ ownerState }) => ({
                    ...({
                        backgroundColor: '#f7f7f7',
                        color: '#202020',
                    }),
                }),
                paper: ({ ownerState }) => ({
                    ...({
                        backgroundColor: '#f7f7f7',
                        color: '#202020',
                    }),
                }),
            },
        },
        MuiTableRow: {
            variants: [
                {
                    props: { variant: 'oddCashAnalysis' },
                    style: {
                        backgroundColor: '#f9f9f9',
                        color: 'black',
                    },
                },
                {
                    props: { variant: 'evenCashAnalysis' },
                    style: {
                        backgroundColor: 'transparent',
                        color: 'black',
                    },
                },
            ],
        },
        MuiTableCell: {
            variants: [
                {
                    props: { variant: 'cashAnalysis' },
                    style: {
                        fontSize: 'small',
                        backgroundColor: null,  // Keeps default background
                        color: 'black',
                    },
                },
                {
                    props: { variant: 'cashAnalysisHead' },
                    style: {
                        fontSize: 'small',
                        fontWeight: '800',
                        backgroundColor: 'lavender',
                        color: 'black',
                    },
                },
            ],
        },
    },
    palette: {
        primary: {
            main: '#1976D2', // App Bar, Buttons, Links
        },
        secondary: {
            main: '#424242', // Drawer background, secondary buttons
        },
        background: {
            default: '#FaFaFa', // Main background
            paper: '#f5f5f5', // Cards, Modals
        },
        text: {
            primary: '#212121', // Main text
            secondary: '#757575', // Secondary text
            disabled: '#BDBDBD', // Disabled text
        },
        info: {
            main: '#E3F2FD', // Information messages
        },
        success: {
            main: '#C8E6C9', // Success messages
        },
        warning: {
            main: '#FFF9C4', // Warning messages
        },
        error: {
            main: '#FFCDD2', // Error messages
        },
    },
    typography: {
        h1: {
            color: '#212121', // Titles
        },
        h2: {
            color: '#212121', // Titles
        },
        h3: {
            color: '#212121', // Titles
        },
        body1: {
            color: '#212121', // Main text
        },
        body2: {
            color: '#757575', // Secondary text
        },
    },
});

export default theme;

export const slicerTheme = createTheme({
    components: {
        MuiIconButton: {
            variants: [
                {
                    props: { variant: 'slicerOn' },
                    style: {
                        backgroundColor: '#d0e2cd',
                    },
                },
                {
                    props: { variant: 'slicerOff' },
                    style: {
                        backgroundColor: '#fff',
                    },
                },
            ],
            styleOverrides: {
                root: {
                    borderWidth: '2px',
                    border: '#d0e2cd',
                    borderStyle: 'solid',
                },
            },
        },
        MuiButton: {
            variants: [
                {
                    props: { variant: 'slicerOn' },
                    style: {
                        backgroundColor: '#d0e2cd',
                        width: '100%',
                        borderRadius: '5px',
                        borderWidth: '2px',
                        border: '#d0e2cd',
                        borderStyle: 'solid',
                        fontSize: 'x-small',
                        justifyContent: 'left',
                        textWrap: 'nowrap',
                        textOverflow: 'ellipsis',
                        display: 'flow',
                        overflow: 'hidden',
                        textAlign: 'left',
                        paddingLeft: '8px',
                        paddingRight: '8px',
                        whiteSpace: 'nowrap',
                    },
                },
                {
                    props: { variant: 'slicerOff' },
                    style: {
                        backgroundColor: '#fff',
                        width: '100%',
                        borderRadius: '5px',
                        borderWidth: '2px',
                        border: '#d0e2cd',
                        borderStyle: 'solid',
                        fontSize: 'x-small',
                        justifyContent: 'left',
                        textWrap: 'nowrap',
                        textOverflow: 'ellipsis',
                        display: 'flow',
                        overflow: 'hidden',
                        textAlign: 'left',
                        paddingLeft: '8px',
                        paddingRight: '8px',
                        whiteSpace: 'nowrap',
                    },
                },
            ],
        },
        MuiCard: {
            variants: [
                {
                    props: { variant: 'slicer' },
                    style: {
                        width: '100%',
                        backgroundColor: '#fff',
                        borderRadius: '5px',
                        borderWidth: '2px',
                        border: '#8a4b9a',
                        borderStyle: 'solid',
                        marginBottom: '25px',
                    },
                },
            ],
        },
        MuiCardHeader: {
            styleOverrides: {
                root: {
                    paddingBottom: 0,
                },
                title: {
                    fontSize: 'medium', // Custom styles for the content
                },
            },
        },
    },
});
