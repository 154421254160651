import React, { useState, useEffect } from 'react';
import InputBase from '@mui/material/InputBase';
import { styled, useTheme, alpha } from '@mui/material/styles';


const StyledInputBase = styled(InputBase)(({ theme }) => {
    return {
        color: 'inherit',
        '& .MuiInputBase-input': {
            padding: theme.spacing(1, 1, 1, 0),
            // vertical padding + font size from searchIcon
            paddingLeft: `calc(1em + ${theme.spacing(4)})`,
            [theme.breakpoints.up('md')]: {
                paddingLeft: 150,
            },
            transition: theme.transitions.create('width'),
            borderBottom: '#8A4B9A',
            borderWidth: '0 0 2px 0',
            borderStyle: 'solid',
        },
    }
});

export default StyledInputBase;