const BusinessStatus = {
    ACTIVE: 'active',
    ACTIVE_DECREASING_SHARES: 'activeDecreasingShares',
    ACTIVE_RELOCATING: 'activeRelocating',
    LIQUIDATING: 'liquidating',
    LIQUIDATED: 'liquidated',
    BANKRUPT: 'bankrupt',
    REORGANIZING: 'reorganizing',
    OTHER: 'other',
};

export default BusinessStatus;
