import React, { useState } from 'react';
import { Field, ErrorMessage } from 'formik';
import { FiEye, FiEyeOff, FiAlertTriangle } from 'react-icons/fi';

const TextField = ({ name, type = 'text', placeholder, togglePasswordVisibility, showPassword, className, labelIcon, error, touched }) => {
    const [showError, setShowError] = useState(false);
    const toggleErrorVisibility = (newVal = !showError) => {
        setShowError(newVal);
    };

    return (
        <div className={error && touched ? className + ' text-field-error' : className}>
            <label className="label-icon" htmlFor={name}>
                {labelIcon}
            </label>
            <Field type={type} name={name} id={name} placeholder={placeholder} autoComplete={name} />
            {error && touched ?
                <div className="icon error tooltip-parent" onClick={toggleErrorVisibility} onMouseEnter={toggleErrorVisibility} onMouseLeave={() => toggleErrorVisibility(false)}>
                    <FiAlertTriangle />
                    <div className={!showError ? 'tooltip' : 'tooltip tooltip-visible'}>
                        <ErrorMessage name={name} />
                    </div>
                </div>
                : ''}
            {name === 'password' && (
                <div className="icon" onClick={togglePasswordVisibility}>
                    {showPassword ? <FiEyeOff /> : <FiEye />}
                </div>
            )}
        </div>)
};

export default TextField;
