import React, { useState } from 'react';
import { Box, Select, MenuItem, CircularProgress } from '@mui/material';
import { CheckCircle, Cancel } from '@mui/icons-material';
import { transactionTypes } from '../../../utils/vars';
import ApiManager from '../../../utils/api';
import { myDebugPrint } from '../../../utils/debug';
import { useSnackbar } from '../../../context/SnackbarContext';
import CircleLoader from 'react-spinners/CircleLoader';

const TransactionTypeEditor = ({ projectId, paymentId, currentType, onSave, onCancel }) => {
    const [newType, setNewType] = useState(currentType);
    const [isLoading, setIsLoading] = useState(false);
    const showSnackbar = useSnackbar();

    const handleSave = async () => {
        setIsLoading(true);
        try {
            const data = await ApiManager.cashEditPayment({
                projectId,
                paymentId,
                transactionType: newType
            });
            if (data && data.result) {
                showSnackbar('Платеж обновлен!', 'success')
                onSave(newType);
            } else {
                showSnackbar('Не удалось обновить тип платежа!', 'error')
            }
        } catch (error) {
            myDebugPrint('Error updating transaction type:', error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', fontSize: 'small' }}>
            <Select
                value={newType}
                onChange={(e) => setNewType(e.target.value)}
                disabled={isLoading}
                sx={{ fontSize: 'small' }}
            >
                {transactionTypes.map((type) => (
                    <MenuItem key={type} value={type} sx={{ fontSize: 'small' }}>
                        {type}
                    </MenuItem>
                ))}
            </Select>
            {!isLoading ? (
                <>
                    <CheckCircle
                        onClick={handleSave}
                        style={{ marginLeft: '20px', color: 'green', fontSize: 35, cursor: 'pointer' }}
                    />
                    <Cancel
                        onClick={onCancel}
                        style={{ marginLeft: '20px', color: 'red', fontSize: 35, cursor: 'pointer' }}
                    />
                </>
            ) : (
                <CircleLoader color="#8A4B9A" className='spinner' size={35} />
            )}
        </Box>
    );
};

export default TransactionTypeEditor;
