class Subdivision {
    constructor({
        type,
        name,
        taxReason,
        ruAddress,
        foreignCountry,
        foreignAddress,
    }) {
        this.type = type;
        this.name = name;
        this.taxReason = taxReason;
        this.ruAddress = ruAddress;
        this.foreignCountry = foreignCountry;
        this.foreignAddress = foreignAddress;
    }

    toString() {
        return `${this.type}: ${this.name ? `${this.name}. ` : ''}${this.taxReason ? `КПП: ${this.taxReason}. ` : ''}${this.ruAddress ? `${this.ruAddress}. ` : ''}${this.foreignCountry ? `${this.foreignCountry}. ` : ''}${this.foreignAddress ? `${this.foreignAddress}. ` : ''}`;
    }

    static fromJson(jsonData, type) {
        let myType = type;
        if (myType === 'Представ') myType = 'Представительство';

        return new Subdivision({
            type: myType,
            name: jsonData['НаимПолн'],
            taxReason: jsonData['КПП'],
            ruAddress: jsonData['Адрес'],
            foreignCountry: jsonData['Страна'],
            foreignAddress: jsonData['ИнАдрес'],
        });
    }
}

export default Subdivision;
