import BusinessType from './BusinessType';
import { parseDateFromNullableString } from '../utils/utils';

const DEFAULT_NAME = 'Сведения скрыты или отсутствуют';
const DEFAULT_CORPORATION_POSITION = 'Управляющая организация';

class CEO {
    constructor({
        name,
        ceoSince,
        ceoTill,
        ceoTaxNumber,
        ceoOGRN,
        ceoBusinessType = BusinessType.PERSON,
        ceoRestrictedAccess = false,
        fullName,
        foreignCountry,
        foreignAddress,
        foreignRegNumber,
        foreignRegDate,
        inaccuracy = false,
        inaccuracyDesc,
        positionType,
        positionName,
        massDirector = false,
        disqualification = false,
        disqStartDate,
        disqEndDate,
        sex,
        citizenship
    }) {
        this.name = name;
        this.ceoSince = ceoSince;
        this.ceoTill = ceoTill;
        this.ceoTaxNumber = ceoTaxNumber;
        this.ceoOGRN = ceoOGRN;
        this.ceoBusinessType = ceoBusinessType;
        this.ceoRestrictedAccess = ceoRestrictedAccess;
        this.fullName = fullName;
        this.foreignCountry = foreignCountry;
        this.foreignAddress = foreignAddress;
        this.foreignRegNumber = foreignRegNumber;
        this.foreignRegDate = foreignRegDate;
        this.inaccuracy = inaccuracy;
        this.inaccuracyDesc = inaccuracyDesc;
        this.positionType = positionType;
        this.positionName = positionName;
        this.massDirector = massDirector;
        this.disqualification = disqualification;
        this.disqStartDate = disqStartDate;
        this.disqEndDate = disqEndDate;
        this.sex = sex;
        this.citizenship = citizenship;
    }

    static fromJson(jsonCEOs) {
        const parseBusinessType = (type) => {
            return type?.toLowerCase() === 'org' ? BusinessType.CORPORATION : BusinessType.PERSON;
        };

        const bType = parseBusinessType(jsonCEOs['Type'] ?? '');

        return new CEO({
            name: jsonCEOs['Name'] ?? jsonCEOs['FullName'] ?? DEFAULT_NAME,
            ceoSince: parseDateFromNullableString(jsonCEOs['RecordDate']),
            ceoTaxNumber: jsonCEOs['CEOTaxNumber'],
            ceoOGRN: jsonCEOs['CEOOGRN'],
            ceoBusinessType: bType,
            ceoRestrictedAccess: (parseInt(jsonCEOs['RestrictedAccess'], 10)) === 1,
            fullName: jsonCEOs['FullName'],
            foreignCountry: jsonCEOs['ForeignCountry'],
            foreignAddress: jsonCEOs['ForeignAddress'],
            foreignRegNumber: jsonCEOs['ForeignRegNumber'],
            foreignRegDate: parseDateFromNullableString(jsonCEOs['ForeignRegDate']),
            inaccuracy: (parseInt(jsonCEOs['Inaccuracy'], 10)) === 1,
            inaccuracyDesc: jsonCEOs['InaccuracyDesc'],
            positionType: jsonCEOs['PositionType'],
            positionName: jsonCEOs['PositionName'] ?? (bType === BusinessType.CORPORATION ? DEFAULT_CORPORATION_POSITION : ''),
            massDirector: (parseInt(jsonCEOs['MassDirector'], 10)) === 1,
            disqualification: (parseInt(jsonCEOs['Disqualification'], 10)) === 1,
            disqStartDate: parseDateFromNullableString(jsonCEOs['DisqStartDate']),
            disqEndDate: parseDateFromNullableString(jsonCEOs['DisqEndDate']),
            sex: jsonCEOs['Sex'],
            citizenship: jsonCEOs['Citizenship']
        });
    }
}

export default CEO;
