import { parseDateFromNullableString } from '../utils/utils';

class SMEMeasure {
    constructor({
        supportDate,
        type,
        form,
        sponsorName,
        sponsorTaxNumber,
        amount,
        violation = false,
    }) {
        this.supportDate = supportDate;
        this.type = type;
        this.form = form;
        this.sponsorName = sponsorName;
        this.sponsorTaxNumber = sponsorTaxNumber;
        this.amount = amount;
        this.violation = violation;
    }

    static fromJson(jsonData) {
        return new SMEMeasure({
            supportDate: parseDateFromNullableString(jsonData['Дата']),
            type: jsonData['Тип'],
            form: jsonData['Форма'],
            sponsorName: jsonData['НаимОрг'],
            sponsorTaxNumber: jsonData['ИНН'],
            amount: jsonData['Размер'] ? jsonData['Размер'].toString() : '',
            violation: jsonData['Наруш']?.toString().toLowerCase() === 'true',
        });
    }
}

export default SMEMeasure;
