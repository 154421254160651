// src/context/NavigationContext.js
import React, { createContext, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { animateScroll as scroll } from 'react-scroll';

const NavigationContext = createContext();

export const NavigationProvider = ({ children }) => {
    const navigate = useNavigate();

    const handleNavigateToOrderForm = () => {
        navigate('/');
        setTimeout(() => {
            scroll.scrollTo(document.getElementById('form').offsetTop);
        }, 300);
    };

    return (
        <NavigationContext.Provider value={{ handleNavigateToOrderForm }}>
            {children}
        </NavigationContext.Provider>
    );
};

export const useNavigation = () => useContext(NavigationContext);
