// src/pages/Search.js
import React, { useState, useEffect } from 'react';
import DashboardSkeleton from '../components/dashboard/DashboardSkeleton';
import SearchResults from '../components/SearchResults';
import { myDebugPrint } from '../utils/debug';
import ApiManager from '../utils/api';
import { useSnackbar } from '../context/SnackbarContext';
import MyPlan from '../components/MyPlan';
import { Typography } from '@mui/material';
import { useParams, useSearchParams, useNavigate, createSearchParams } from "react-router-dom";
import CircleLoader from 'react-spinners/CircleLoader';
import BusinessDetailsSkeleton from '../components/dashboard/BusinessDetailsSkeleton';

const MyPlanPage = () => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const [myData, setMyData] = useState(null);

    const [isLoading, setIsLoading] = useState(true);

    const showSnackbar = useSnackbar();

    const handleSearch = async (query) => {
        if (!query || query.length < 2) { return false; }

        navigate({
            pathname: "/search",
            search: createSearchParams({
                q: query
            }).toString(),
        });
    };

    const handleError = ({ errorMessage }) => {
        showSnackbar(errorMessage, 'error');
    };

    const handleFetch = async (signal) => {
        try {
            setIsLoading(true);
            const response = await ApiManager.myPlan({});
            if (!signal || !signal.aborted) {
                setMyData(response);
            }
        } catch (e) {
            if (!signal || !signal.aborted) {
                myDebugPrint(`MyPlanPage handleFetch error: ${e}, ${JSON.stringify(e)}`);
                handleError({ errorMessage: 'Ошибка при выполнении запроса. Повторите попытку через минуту. Связь с поддержкой info@brpadvice.ru' });
            }
        } finally {
            if (!signal || !signal.aborted) {
                setIsLoading(false);
            }
        }

    };

    useEffect(() => {
        const controller = new AbortController();
        handleFetch(controller.signal);

        return () => {
            controller.abort(); // Cancel the previous search if searchParams change
        };
    }, []);

    return (
        <DashboardSkeleton
            handleSearch={handleSearch}
            initialQuery={''}
            isSearching={false}
            isCorp={false}
        >
            {isLoading &&
                <CircleLoader color="#8A4B9A" className='spinner' size={45} />
            }
            {!isLoading && myData &&
                <BusinessDetailsSkeleton
                    entityName=''
                    projectNameEditFunction={() => { }}
                    projectNameEditIsFetching={() => { }}
                    forceEditNameState={() => { }}
                    h1='Мой аккаунт'
                    mainContent={[
                        <MyPlan
                            data={myData}
                        />
                    ]}
                />
            }
            {!isLoading && !myData &&
                <Typography variant='body2'>
                    Ошибка при выполнении запроса. Обновите страницу через минуту. Связь с поддержкой info@brpadvice.ru
                </Typography>
            }
        </DashboardSkeleton>
    );
};

export default MyPlanPage;
