import React from 'react';
import { Box } from '@mui/material';
import { YMInitializer } from 'react-yandex-metrika';

const MetrikaWrapper = ({ children }) => {
    return (
        <Box>
            <YMInitializer accounts={[98047225]} options={{
                clickmap: true,
                trackLinks: true,
                accurateTrackBounce: true,
                webvisor: true,
            }}
                version="2"
            />
            {children}
        </Box>
    );
}

export default MetrikaWrapper;