import * as Yup from 'yup';

const authValidationSchema = (authState) => {
    const commonSchema = {
        email: Yup.string().email('Это не email').required('Обязательное поле'),
    };

    switch (authState) {
        case 'signIn':
            return Yup.object({
                ...commonSchema,
                password: Yup.string()
                    .required('Обязательное поле')
                    .min(8, "Слишком короткий"),
            });
        case 'signUp':
            return Yup.object({
                ...commonSchema,
                password: Yup.string()
                    .required('Обязательное поле')
                    .matches(
                        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9!@#\$%\^&\*])(?=.{8,})/,
                        "Должен быть не менее 8 символов, включать прописные и строчные английские буквы, а также цифру или специальный символ."
                    ),
            });
        case 'recoveryRequest':
            return Yup.object({
                ...commonSchema,
            });
        case 'passwordReset':
        case 'verifyEmail':
            return Yup.object({
                ...commonSchema,
                password: Yup.string()
                    .required('Обязательное поле')
                    .matches(
                        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9!@#\$%\^&\*])(?=.{8,})/,
                        "Должен быть не менее 8 символов, включать прописные и строчные английские буквы, а также цифру или специальный символ."
                    ),
                token: Yup.string().min(5, 'Неверный токен').required('Обязательное поле'),
            });
        default:
            return Yup.object(commonSchema);
    }
};

export default authValidationSchema;
