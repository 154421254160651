import { myDebugPrint } from '../utils/debug';
import { roundTo, formatDoubleToPaddedString } from '../utils/utils';
// import NumberFormat from 'number-format.js';
import { Intl } from 'intl';

class RecentFinance {
    constructor({
        indicatorName,
        measureName,
        division,
        roundDigits,
        lastYearNumber,
        lastYearVal,
        prevYearNumber,
        prevYearVal,
        lastYearProposition,
        prevYearProposition,
        negativeImpact = false,
    }) {
        this.indicatorName = indicatorName;
        this.measureName = measureName;
        this.division = division;
        this.roundDigits = roundDigits;
        this.lastYearNumber = lastYearNumber;
        this.lastYearVal = lastYearVal;
        this.prevYearNumber = prevYearNumber;
        this.prevYearVal = prevYearVal;
        this.lastYearProposition = lastYearProposition;
        this.prevYearProposition = prevYearProposition;
        this.negativeImpact = negativeImpact;
    }

    buildFullString({ inPercent = true } = {}) {
        const lastY = this.buildLastYearOnly();
        if (this.prevYearVal === null || this.prevYearNumber === null) return lastY;
        const comparison = this.buildComparison({ inPercent }) || '';
        return `${lastY} (${comparison})`;
    }

    buildLastYearOnly() {
        const tmpVal = roundTo(this.lastYearVal / this.division, this.roundDigits);
        const decimalPart = this.roundDigits > 0 ? `.${'0'.repeat(this.roundDigits)}` : '';
        const format = `,###${decimalPart}`;
        // const f = new NumberFormat(format, 'ru_RU');
        try {
            // const f = new Intl.NumberFormat('ru-RU', { style: 'decimal' }).format(tmpVal);
            // const valueToType = f.format(tmpVal);
            const valueToType = formatDoubleToPaddedString({ number: tmpVal, roundDigits: this.roundDigits });
            return `${this.lastYearProposition || ''}${this.lastYearNumber} = ${valueToType} ${this.measureName}`;
        } catch (e) {
            myDebugPrint(`error on building last year financial data: ${e}`);
            return '';
        }
    }

    buildComparison({ inPercent = true } = {}) {
        if (this.prevYearVal === null || this.prevYearNumber === null) return null;
        if (roundTo(this.prevYearVal / this.division, this.roundDigits) === 0 &&
            roundTo(this.lastYearVal / this.division, this.roundDigits) === 0) {
            return `${this.prevYearProposition || ''}${this.prevYearNumber} также 0`;
        }

        const diffMap = this.deltaRate() || {};
        const differenceRate = diffMap.val || 0;
        const differenceStr = diffMap.str || '';
        const difference = this.delta() || 0;
        const directionUp = !this.negativeImpact ? 'больше' : 'хуже';
        const directionDown = !this.negativeImpact ? 'меньше' : 'лучше';
        const differenceString = inPercent ? differenceStr : Math.abs(difference).toString();

        if (Math.abs(differenceRate) < 1) {
            return `~ как ${this.prevYearProposition || ''}${this.prevYearNumber}`;
        } else if (difference < 0) {
            return `${directionDown} ${this.prevYearNumber} на ${differenceString}${inPercent ? '%' : ''}`;
        } else {
            return `${directionUp} ${this.prevYearNumber} на ${differenceString}${inPercent ? '%' : ''}`;
        }
    }

    deltaRate() {
        if (this.prevYearVal === null) return null;
        const exactValue = (this.lastYearVal / this.prevYearVal) * 100 - 100;
        let roundedValue = 0;
        let res = '';

        if (Math.abs(exactValue) < 10) {
            roundedValue = roundTo(exactValue, 1);
            res = formatDoubleToPaddedString({ number: exactValue, roundDigits: 1 });
        } else {
            roundedValue = roundTo(exactValue, 0);
            res = formatDoubleToPaddedString({ number: exactValue, roundDigits: 0 });
        }

        return { str: res, val: roundedValue };
    }

    delta() {
        if (this.prevYearVal === null) return null;
        if (this.deltaRate() === 0) return 0;
        let roundedDiff = roundTo(this.lastYearVal - this.prevYearVal, this.roundDigits);
        if (roundedDiff !== 0) return roundedDiff;
        const roundedDiffTwo = roundTo(this.lastYearVal - this.prevYearVal, this.roundDigits + 2);
        if (Math.abs(roundedDiff) < 45) return roundedDiffTwo / 100;
        return roundTo(this.lastYearVal - this.prevYearVal, this.roundDigits + 1) / 10;
    }
}

export default RecentFinance;
