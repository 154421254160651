import Cookies from 'js-cookie';

// Store JWT in an httpOnly cookie
export const storeToken = (token) => {
    localStorage.setItem('jwt', token);
};


export const getToken = () => {
    return localStorage.getItem('jwt');
};

export const removeToken = () => {
    localStorage.removeItem('jwt');
};

// Store email in localStorage (less sensitive, but still consider risks)
export const storeEmail = (email) => {
    localStorage.setItem('email', email);
};

// Store email in localStorage (less sensitive, but still consider risks)
export const storeUsername = (email) => {
    localStorage.setItem('email', email);
};


// Store email in localStorage (less sensitive, but still consider risks)
export const storeName = (name) => {
    localStorage.setItem('name', name);
};

export const getEmail = () => {
    return localStorage.getItem('email');
};


export const removeEmail = () => {
    localStorage.removeItem('email');
};

export const cleanAuth = () => {
    removeToken();
    removeEmail();
};
