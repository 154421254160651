// components/ContactSection.js
import React from 'react';
import { Container, Grid, Typography, Link, Box } from '@mui/material';
import { styled } from '@mui/system';

const Spacer = styled('div')(({ height }) => ({
    height: height || '20px',
}));

function ContactSection() {
    return (
        <Box sx={{ backgroundColor: '#132036', py: 4 }}>
            <Container maxWidth="lg">
                <Spacer height="20px" />
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <Box>
                            <Link href="https://finradar.brpadvice.ru/">
                                <img
                                    src="/assets/images/finradar - logo  white.png"
                                    alt="ФИНРАДАР"
                                    className="logo-image-authscreen"
                                    style={{ width: '100%', maxWidth: '200px', height: 'auto' }}
                                />
                            </Link>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}></Grid>
                </Grid>
                <Spacer height="40px" />
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={4}>
                        <Typography variant="body1" color='white'>
                            Проект группы BRP ADVICE. Санкт-Петербург, Москва.
                        </Typography>
                        <Typography variant="body1" color='white'>
                            Телефон: +7(812)983-49-76, +7(499)394-63-64, по РФ – 8(800)700-97-86
                        </Typography>
                        <Typography variant="body1" color='white'>
                            Email: info@brpadvice.ru
                        </Typography>
                    </Grid>
                </Grid>
                <Spacer height="40px" />
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <Box>
                            <Link href="https://brpadvice.ru/?from=finradar" target="_blank">
                                <img
                                    src="/assets/images/brp advice - logo white.png"
                                    alt="BRP ADVICE. Виктор Рыбцев и партнеры"
                                    style={{ width: '100%', maxWidth: '200px', height: 'auto' }}
                                />
                            </Link>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}></Grid>
                </Grid>
                <Spacer height="40px" />
            </Container>
        </Box>
    );
}

export default ContactSection;
