class KadParty {
    constructor({
        taxNumber,
        name,
        address,
    }) {
        this.taxNumber = taxNumber;
        this.name = name;
        this.address = address;
    }

    getPartyNameInn() {
        let res = this.name || 'Наименование скрыто';
        if (this.taxNumber && this.taxNumber.length >= 10) {
            res = `${res} (ИНН: ${this.taxNumber})`;
        }
        return res;
    }
}

export default KadParty;
