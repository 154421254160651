import React, { useState, useRef } from 'react';
import { Typography, Box } from '@mui/material';
import { formatDate, buildTextLine, capitalizeFirstLetter } from '../../utils/utils';
import BusinessDetailsSkeleton from './BusinessDetailsSkeleton';
import MyFilterButton from '../common/MyFilterButton';
import Entity from '../../classes/Entity';
import './styles/Dashboard.css';

const SMESection = ({ entity, analytics }) => {
    const [violationFilter, setViolationFilter] = useState(null);
    const scrollController = useRef(null);

    const scrollToStart = () => {
        scrollController.current?.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const toggleFilter = (newFilter) => {
        setViolationFilter((prev) => (newFilter === prev || newFilter == null ? null : newFilter));
        scrollToStart();
    };

    const buildSMEMeasuresWidgets = () => {
        const widgets = [];
        const { smeMeasures_all, smeMeasures_wo_violation, smeMeasures_violation } = entity;

        const smeMeasures_filtered = violationFilter === true
            ? smeMeasures_violation
            : violationFilter === false
                ? smeMeasures_wo_violation
                : smeMeasures_all;

        let noData;
        if (!smeMeasures_all || smeMeasures_all.length === 0) {
            noData = 'Записи о недавних мерах поддержки микро-, малого и среднего бизнеса не найдены.\n';
        }

        if (noData) {
            widgets.push(buildTextLine({ bulletShow: false, content: noData }));
        } else if (smeMeasures_filtered) {
            smeMeasures_filtered.forEach((measure) => {
                const title = `${measure.form
                    ? capitalizeFirstLetter(measure.form.toLowerCase())
                    : ''}${measure.type
                        && measure.form
                        && measure.type.toLowerCase() !== measure.form.toLowerCase() ? ' (' : ''
                    }${measure.type && measure.type.toLowerCase() !== measure.form.toLowerCase() ? measure.type.toLowerCase() : ''}${measure.type && measure.form && measure.type.toLowerCase() !== measure.form.toLowerCase() ? ')' : ''
                    }`;

                widgets.push(buildTextLine({
                    bulletShow: true,
                    bulletColor: measure.violation ? 'red' : null,
                    title: title,
                    content: '',
                }));

                widgets.push(buildTextLine({
                    bulletShow: false,
                    content: `${(measure.sponsorName || measure.sponsorTaxNumber) ? 'Провайдер: ' : ''}${measure.sponsorName ? `${measure.sponsorName} ` : ''}${measure.sponsorTaxNumber ? `(ИНН ${measure.sponsorTaxNumber})` : ''
                        }`,
                }));

                if (measure.amount) {
                    widgets.push(buildTextLine({
                        bulletShow: false,
                        content: `${measure.supportDate ? `${formatDate(measure.supportDate)}. ` : ''}Количество: ${measure.amount}`,
                    }));
                }

                if (measure.violation) {
                    widgets.push(buildTextLine({
                        bulletShow: false,
                        title: 'Выявлены нарушения',
                        titleColor: 'red',
                        content: measure.amount.toString(),
                    }));
                }

                widgets.push(<Box height={20} />);
            });
        }

        return widgets;
    };

    const buildWidgetsAfterTitleBeforeFilters = () => {
        const widgets = [];
        const { smeString, smeSince, smeTill, smeMeasures_all } = entity;

        if (!smeString) {
            widgets.push(buildTextLine({
                title: 'Не найдено',
                content: ' в реестре МСП',
            }));
        } else {
            widgets.push(buildTextLine({
                title: `${capitalizeFirstLetter(smeString.toLowerCase())}. `,
                titleColor: !smeTill || new Date(smeTill) >= new Date() ? 'green' : 'orange',
                content: `В реестре МСП${smeSince ? ` с ${formatDate(smeSince)}` : ''}${smeTill ? ` по ${formatDate(smeTill)}` : ''}`,
            }));
            widgets.push(<Box height={20} />);
        }

        if (smeMeasures_all && smeMeasures_all.length > 0) {
            widgets.push(buildTextLine({
                bulletShow: false,
                title: `Найдено примененных мер поддержки: ${smeMeasures_all.length}`,
                content: '',
            }));
        }

        return widgets;
    };

    const buildFilteringWidgets = () => {
        const widgets = [];
        const { smeMeasures_all } = entity;

        if (smeMeasures_all && smeMeasures_all.length > 1) {
            widgets.push(
                <Box display="flex">
                    <MyFilterButton
                        onClick={() => toggleFilter(null)}
                        title="Все"
                        active={violationFilter === null}
                    />
                    <MyFilterButton
                        onClick={() => toggleFilter(false)}
                        title="Без нарушений"
                        active={violationFilter === false}
                    />
                    <MyFilterButton
                        onClick={() => toggleFilter(true)}
                        title="С нарушениями"
                        active={violationFilter === true}
                    />
                </Box>
            );
        }

        return widgets;
    };

    return (
        <BusinessDetailsSkeleton
            entityName={entity.name}
            h1="Реестр МСП и меры поддержки МСП"
            h1tooltip={null}
            widgetsAfterTitleBeforeFilters={buildWidgetsAfterTitleBeforeFilters()}
            filteringWidgets={buildFilteringWidgets()}
            scrollController={scrollController}
            mainContent={buildSMEMeasuresWidgets()}
        />
    );
};

export default SMESection;
