// src/components/CommonCorpSection.js
import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import Entity from '../../classes/Entity';
import {
    formatDate, timeDifference, getStatusColor,
    buildTextLine, formatDoubleToPaddedString, TextWithNewlines,
    capitalizeFirstLetter,
    parseAndFormatDate,
    parseDate
} from '../../utils/utils';
import BusinessDetailsSkeleton from './BusinessDetailsSkeleton';
import { myDebugPrint } from '../../utils/debug';
import MyDataLine from '../common/MyDataLine';
import CEO from '../../classes/CEO';
import Shareholder from '../../classes/Shareholder';

const CorpCommonSection = ({ analytics, entity, handleSearch, callbackOpenCompanyFull, limitsRest }) => {

    const [showAllActivities, setShowAllActivities] = useState(false);

    const toggleAllActivities = () => {
        setShowAllActivities(!showAllActivities);
    };

    const dateReg = formatDate(entity.registrationDate);
    // const dateOgrn = formatDate(entity.dateOgrn);
    const dateLiquid = formatDate(entity.liquidationDate);
    const sinceDateReg = timeDifference(entity.registrationDate);
    const sinceDateLiquid = timeDifference(entity.liquidationDate);
    // const dateCEO = formatDate(entity.ceoSince);
    // const sinceDateCEO = timeDifference(entity.ceoSince)?.replace(' назад', '');
    // const screenWidth = window.innerWidth;
    // const shrinkTables = screenWidth < 600;

    const buildCeos = () => {
        const ceosToBuild = [...entity.ceos]
        if (source && source.History && source.History['Руководитель']) {
            for (const [dates, ceo] of Object.entries(source.History['Руководитель'])) {
                const datesArray = dates.split('~')
                const thisCeo = new CEO({
                    ceoSince: parseDate({ dateString: datesArray[0].trim(), format: 'yyyy-MM-dd' }),
                    ceoTill: datesArray.length > 1 ? parseDate({ dateString: datesArray[1].trim(), format: 'yyyy-MM-dd' }) : null,
                    name: ceo['ФИОПолн'],
                    ceoTaxNumber: ceo['ИННФЛ'],
                    positionName: ceo['Должн'],
                    inaccuracy: ceo['ПризнНедДанДолжнФЛ'] && ceo['ПризнНедДанДолжнФЛ']['Код'] ? ceo['ПризнНедДанДолжнФЛ']['Код'] : null,
                    inaccuracyDesc: ceo['ПризнНедДанДолжнФЛ'] && ceo['ПризнНедДанДолжнФЛ']['Текст'] ? ceo['ПризнНедДанДолжнФЛ']['Текст'] : null,
                    disqualification: ceo['ДатаОкончДискв'] ? 1 : null,
                    disqEndDate: ceo['ДатаОкончДискв'],
                })
                ceosToBuild.push(thisCeo);
            }
        }
        if (ceosToBuild.length > 0) {
            ceosToBuild.sort((a, b) => b.ceoSince - a.ceoSince);
        }
        return ceosToBuild.map((element) => {
            const dateCEO = formatDate(element.ceoSince);
            const sinceDateCEO = timeDifference(element.ceoSince)?.replace(' назад', '');
            const datesWhenCeo = dateCEO
                ? (element.ceoTill
                    ? `\nс ${dateCEO} по ${formatDate(element.ceoTill)}`
                    : `\nс ${dateCEO} (${sinceDateCEO} назад)`
                )
                : '';
            const inn = element.ceoTaxNumber
                ? (
                    <span>
                        &nbsp;(ИНН&nbsp;
                        {
                            <a
                                href={`search?q=${element.ceoTaxNumber}`}
                                className='pseudoHrefWithColor decorationNone'
                                target='_blank'
                            >
                                {element.ceoTaxNumber}
                            </a>
                        }
                        )
                    </span>
                )
                : '';
            const title = element.positionName ? ` - ${element.positionName.toLowerCase()}` : '';
            const inaccuracyText = element.inaccuracy
                ? `\n${element.inaccuracyDesc || 'Сведения недостоверны'}`
                : '';
            let disqText = '';
            if (element.disqualification) {
                disqText = '\nДисквалифицированное лицо';
                if (element.disqStartDate || element.disqEndDate) {
                    const disqDates = [];
                    if (element.disqStartDate) disqDates.push(`с ${formatDate(element.disqStartDate)}`);
                    if (element.disqEndDate) disqDates.push(`по ${formatDate(element.disqEndDate)}`);
                    disqText = `${disqText} (${disqDates.join(' ')})`;
                }
            }
            return (
                <MyDataLine
                    key={`${element.name},${inn},${dateCEO}`}
                    bulletShow={true}
                    bulletColor={(element.inaccuracy || element.disqualification)
                        ? 'red'
                        : (element.massDirector ? 'orange' : 'black')
                    }
                    title={element.name}
                    titleLink={`search?q=${element.name}`}
                    content={
                        <span>
                            {inn}{title}{datesWhenCeo}{inaccuracyText}{disqText}
                        </span>
                    }
                />
            )
        });
    };

    const buildShareholders = () => {
        const holdersToBuild = [...entity.shareholders]
        if (source && source.History && source.History['Учредители']) {
            source.History['Учредители'].map(element => {
                const thisHolder = new Shareholder({
                    name: element['УчрЮЛ']
                        ? element['УчрЮЛ']['НаимСокрЮЛ']
                        : (
                            element['УчрФЛ']
                                ? element['УчрФЛ']['ФИОПолн']
                                : (
                                    element['УчрИН']
                                        ? element['УчрИН']['НаимПолнЮЛ']
                                        : (
                                            element['УчрРФСубМО']
                                                ? element['УчрРФСубМО']
                                                : (
                                                    element['СвОргОсущПр'] && element['СвОргОсущПр']['УчрЮЛ']
                                                        ? element['СвОргОсущПр'] && element['СвОргОсущПр']['УчрЮЛ']['НаимСокрЮЛ']
                                                        : null
                                                )
                                        )
                                )
                        ),
                    fullName: element['УчрЮЛ']
                        ? element['УчрЮЛ']['НаимСокрЮЛ']
                        : (
                            element['УчрФЛ']
                                ? element['УчрФЛ']['ФИОПолн']
                                : (
                                    element['УчрИН']
                                        ? element['УчрИН']['НаимПолнЮЛ']
                                        : null
                                )
                        ),
                    shareholderINN: element['УчрЮЛ']
                        ? element['УчрЮЛ']['ИНН']
                        : (
                            element['УчрФЛ']
                                ? element['УчрФЛ']['ИННФЛ']
                                : (
                                    element['СвОргОсущПр'] && element['СвОргОсущПр']['УчрЮЛ']
                                        ? element['СвОргОсущПр'] && element['СвОргОсущПр']['УчрЮЛ']['ИНН']
                                        : null
                                )
                        ),
                    shareholderOGRN: element['УчрЮЛ']
                        ? element['УчрЮЛ']['ОГРН']
                        : (
                            element['СвОргОсущПр'] && element['СвОргОсущПр']['УчрЮЛ']
                                ? element['СвОргОсущПр'] && element['СвОргОсущПр']['УчрЮЛ']['ОГРН']
                                : null
                        ),
                    shareNominalValue: element['СуммаУК'],
                    sharePercentage: element['Процент'],
                    since: parseDate({ dateString: element['Дата'], format: 'yyyy-MM-dd' }),
                    shareholderTill: parseDate({ dateString: element['ДатаОконч'], format: 'yyyy-MM-dd' }),
                })
                holdersToBuild.push(thisHolder);
            })
        }
        if (holdersToBuild.length > 0) {
            holdersToBuild.sort((a, b) => (b.shareholderTill || Date.now()) - a.shareholderTill);
        }
        return holdersToBuild.map(element => {
            const dateHolder = formatDate(element.since);
            const sinceDateHolder = timeDifference(element.since)?.replace(' назад', '');
            const datesWhenHolder = dateHolder
                ? (element.shareholderTill
                    ? `\ с ${dateHolder} по ${formatDate(element.shareholderTill)}`
                    : `\ с ${dateHolder} (${sinceDateHolder} назад).`)
                : '.';
            const inn = element.shareholderINN
                ? (
                    <span>
                        &nbsp;(ИНН&nbsp;
                        {
                            <a
                                href={`search?q=${element.shareholderINN}`}
                                className='pseudoHrefWithColor decorationNone'
                                target='_blank'
                            >
                                {element.shareholderINN}
                            </a>
                        }
                        )
                    </span>
                )
                : '';
            const inaccuracyText = element.inaccuracy
                ? `\n${element.inaccuracyDescription || 'Сведения недостоверны'} `
                : '';
            const sharePercent = (element.sharePercentage ?? -1) > 0
                ? `Доля ${element.sharePercentage}%`
                : null;
            const shareNominalValue = (element.shareNominalValue ?? -1) > 0
                ? `номинальная стоимость ${formatDoubleToPaddedString({ number: element.shareNominalValue })} руб.`
                : null;
            let shareString = '';
            if (sharePercent || shareNominalValue) {
                if (sharePercent && shareNominalValue) {
                    shareString = `\n${sharePercent}, ${shareNominalValue} `;
                } else {
                    shareString = `\n${sharePercent || shareNominalValue} `;
                }
            }
            return (
                <MyDataLine
                    key={`shareholders,${element.shareholderINN},${element.name},${dateHolder} `}
                    bulletShow={true}
                    bulletColor={element.inaccuracy
                        ? 'red'
                        : (element.massShareholderIndicator ? 'orange' : 'black')
                    }
                    title={element.name}
                    titleLink={`search?q=${element.shareholderINN || element.name}`}
                    content={<span>{inn}{datesWhenHolder}{shareString}{inaccuracyText}</span>}
                />
            );
        });
    };

    const buildCEOWidget = () => {
        return (
            <Box>
                <div style={{ height: 20 }} />
                <MyDataLine
                    title='Руководители'
                    content=' (лица, имеющие право действовать без доверенности):'
                />
                {!entity.ceos || entity.ceos.length === 0
                    ? <MyDataLine content='сведения отсутствуют' />
                    : buildCeos()
                }
            </Box>
        );
    };

    const buildShareholderWidget = () => {
        return (
            <>
                <div style={{ height: 20 }} />
                {entity.actualRegisteredCapitalName &&
                    <MyDataLine
                        title={capitalizeFirstLetter(entity.actualRegisteredCapitalName.toLowerCase()) + ': '}
                        content={
                            (
                                ((entity.actualRegisteredCapital ?? -1) >= 0)
                                    ? formatDoubleToPaddedString({ number: entity.actualRegisteredCapital }) + ' руб.'
                                    : 'сведения отсутствуют'
                            ) + (
                                (source.ShareCapital && source.ShareCapital['Дата'])
                                    ? ` (с ${parseAndFormatDate({ dateString: source.ShareCapital['Дата'], sourceFormat: 'yyyy-MM-dd' })})`
                                    : ''
                            )
                        }
                    />
                }
                <div style={{ height: 20 }} />
                <MyDataLine title='Учредители' content=' (участники, акционеры):' />
                {!entity.shareholders || entity.shareholders.length === 0
                    ? <MyDataLine content='сведения отсутствуют' />
                    : buildShareholders()
                }
            </>
        );
    };

    const source = entity.source;

    return (
        <BusinessDetailsSkeleton
            limitsRest={limitsRest}
            callbackOpenCompanyFull={callbackOpenCompanyFull}
            entityName={entity.name}
            widgetsBeforeTitle={null}
            h1="Общие сведения"
            widgetsAfterTitleBeforeFilters={[
                <MyDataLine
                    key={entity.name + entity.status}
                    bulletShow={true}
                    bulletColor={getStatusColor(entity.status)}
                    content={
                        (entity.statusName || '')
                        + (entity.statusName && entity.liquidationReason ? ' - ' : '')
                        + (entity.liquidationReason || '')
                        + (entity.liquidationDateDue ? ` (срок ликвидации - ${entity.liquidationDateDue})` : '')
                    }
                />
            ]}
            filteringWidgets={null}
            widgetsAfterFilters={null}
            mainContent={[]}
        >
            <Box>
                {source.MKF && <MyDataLine content={source.MKF} />}
                {entity.fullName && <MyDataLine title='Полное наименование: ' content={entity.fullName} />}
                {entity.fullName !== entity.name && <MyDataLine title='Краткое наименование: ' content={entity.name} />}
                {entity.latinName && <MyDataLine title='Наименование на иностранном языке: ' content={entity.latinName} />}
                {entity.latinNameFull && <MyDataLine title='Полное наименование на иностранном языке: ' content={entity.latinNameFull} />}
                {dateReg && <MyDataLine title='Образовано: ' content={`${dateReg} (${sinceDateReg})`} />}
                {source.TypeOfCreation && <MyDataLine title='Способ образованиия: ' content={source.TypeOfCreation} />}
                {dateLiquid && <MyDataLine title='Ликвидировано: ' content={`${dateLiquid} (${sinceDateLiquid})`} />}
                <MyDataLine title='ИНН:  ' content={entity.taxNumber} />
                {entity.taxReason && <MyDataLine title='КПП:  ' content={entity.taxReason} />}
                {entity.stateNumber &&
                    <MyDataLine
                        title='ОГРН: '
                        content={
                            entity.stateNumber
                            + (
                                (entity.dateOgrn && entity.registrationDate && entity.dateOgrn !== entity.registrationDate)
                                    ? ` от ${formatDate(entity.dateOgrn)} ` : ''
                            )
                        }
                    />
                }
                {entity.type === 'corporation' &&
                    <Box>

                        {buildCEOWidget()}
                        {buildShareholderWidget()}
                        <div style={{ height: 20 }} />
                        <Typography variant="body2" component="div">
                            <span>
                                Для доступа к историческим сведениям откройте карточку в режиме полного доступа
                            </span>
                        </Typography>

                    </Box>
                }
                {(source.CharterTypicalNumber || source.CorporateAgreement) &&
                    <Box>
                        <div style={{ height: 20 }} />
                        {source.CharterTypicalNumber && <MyDataLine title='Редакция типового устава: ' content={source.CharterTypicalNumber} />}
                        {source.CorporateAgreement &&
                            <MyDataLine title='Сведения о корпоративном договоре: '
                                content={
                                    source.CorporateAgreement.toLowerCase().replace(
                                        'непропорционально',
                                        'непропорционально (корпоративным договором предусмотрен объем правомочий участников хозяйственного общества непропорционально размерам принадлежащих им долей в уставном капитале хозяйственного общества)'
                                    ).replace(
                                        'ограничения',
                                        'ограничения (корпоративным договором предусмотрены ограничения и условия отчуждения долей (акций))'
                                    )
                                }
                            />
                        }
                    </Box>
                }
                <div style={{ height: 20 }} />
                {entity.address &&
                    <MyDataLine
                        bulletShow={entity.actualAddressInvalidity === 1}
                        bulletColor='red'
                        title='Адрес: '
                        content={entity.address + ' '}
                        moreSpans={
                            <span>
                                <a
                                    href={`https://yandex.ru/maps/?text=${entity.address}`}
                                    className='pseudoHrefWithColor decorationNone'
                                    target="_blank"
                                    rel="noopener noreferrer" >
                                    (на карте)
                                </a >
                                {
                                    entity.actualAddressInvalidity === 1 &&
                                    <span>
                                        {entity.actualAddressInvalidityReason || 'Сведения об адресе недостоверны'}
                                    </span>
                                }
                            </span >
                        }
                    />
                }
                {
                    (
                        (entity.contacts_web && entity.contacts_web.length > 0)
                        || (entity.contacts_phone && entity.contacts_phone.length > 0)
                        || (entity.contacts_email && entity.contacts_email.length > 0)
                    )
                    &&
                    <Box>
                        <div style={{ height: 20 }} />
                        <MyDataLine
                            title='Контакты: '
                            content=''
                            tooltipShow={true}
                            tooltipText="Добавлены пользователями или найдены в открытых источниках."
                        />
                        {entity.contacts_phone && entity.contacts_phone.length > 0 &&
                            <MyDataLine
                                content={'Тел: ' + entity.contacts_phone.join(', ')}
                            />
                        }
                        {entity.contacts_email && entity.contacts_email.length > 0 &&
                            <MyDataLine content={'Email: ' + entity.contacts_email.join(', ')}
                            />
                        }
                        {entity.contacts_web && entity.contacts_web.length > 0 &&
                            <Box>
                                {entity.contacts_web.map((webPage) => {
                                    return (
                                        <MyDataLine
                                            key={webPage}
                                            url={webPage}
                                            content={webPage}
                                        />
                                    )
                                })}
                            </Box>
                        }
                    </Box>
                }
                <div style={{ height: 20 }} />
                <Typography variant="body2" component="div">
                    <div>
                        {showAllActivities && entity.buildAllActivities().length > 200 &&
                            <span onClick={toggleAllActivities} className='pseudoHrefWithColor decorationNone'>
                                Свернуть ОКВЭД<br />
                            </span>}
                        {!showAllActivities
                            ? <span>
                                <span className='pHeaderBusinessDetails'>
                                    Основной вид деятельности:
                                </span> {entity.activityName || 'не указан'}
                            </span>
                            : <span> <TextWithNewlines text={entity.buildAllActivities()} /></span>
                        }
                        <span onClick={toggleAllActivities} className='pseudoHrefWithColor decorationNone'>
                            <br /> {showAllActivities ? 'Свернуть ОКВЭД' : 'Все ОКВЭД'}
                        </span>
                    </div>
                </Typography>
                <div style={{ height: 20 }} />
                {
                    !source.TaxOfficeObj && (entity.taxOfficeCode || entity.taxOfficeName) &&
                    <MyDataLine
                        title='ИФНС:  '
                        content={
                            (entity.taxOfficeCode && entity.taxOfficeName)
                                ? `${entity.taxOfficeCode} (${entity.taxOfficeName})`
                                : entity.taxOfficeCode || entity.taxOfficeName
                        }
                    />
                }
                {
                    source.TaxOfficeObj &&
                    <Box>
                        {source.TaxOfficeObj["Рег"] &&
                            <MyDataLine
                                title='ИФНС регистрации:  '
                                content={
                                    source.TaxOfficeObj["Рег"]
                                    + (
                                        source.TaxOfficeObj["РегДата"]
                                            ? (', ' + parseAndFormatDate({ dateString: source.TaxOfficeObj["РегДата"], sourceFormat: 'yyyy-MM-dd' }))
                                            : ''
                                    )
                                }
                            />
                        }
                        {source.TaxOfficeObj["Учет"] &&
                            <MyDataLine
                                title='ИФНС учета:  '
                                content={
                                    source.TaxOfficeObj["Учет"]
                                    + (
                                        source.TaxOfficeObj["УчетДата"]
                                            ? (', ' + parseAndFormatDate({ dateString: source.TaxOfficeObj["УчетДата"], sourceFormat: 'yyyy-MM-dd' }))
                                            : ''
                                    )
                                }
                            />
                        }
                    </Box>
                }
                <div style={{ height: 20 }} />
                {entity.okpo && <MyDataLine title='ОКПО:  ' content={entity.okpo} />}
                {entity.pensionNumber &&
                    <MyDataLine title='ПФР:   '
                        content={entity.pensionNumber +
                            (source.PensionObject && source.PensionObject['ДатаРегПФ']
                                ? ', зарегистрирован в ПФР ' + parseAndFormatDate({ dateString: source.PensionObject['ДатаРегПФ'], sourceFormat: 'yyyy-MM-dd' })
                                : ''
                            )
                        }
                    />
                }
                {source.PensionObject && source.PensionObject['КодПФ']
                    && <MyDataLine title='ОПФР:   ' content={source.PensionObject['КодПФ']} />}

                {entity.socialNumber &&
                    <MyDataLine title='ФСС:   '
                        content={entity.socialNumber +
                            (source.SocialObject && source.SocialObject['ДатаРегФСС']
                                ? ', зарегистрирован в ФСС ' + parseAndFormatDate({ dateString: source.SocialObject['ДатаРегФСС'], sourceFormat: 'yyyy-MM-dd' })
                                : ''
                            )
                        }
                    />
                }
                {source.SocialObject && source.SocialObject['КодФСС']
                    && <MyDataLine title='ОФСС:   ' content={source.SocialObject['КодФСС']} />}

                {entity.okato && <MyDataLine title='ОКАТО: ' content={entity.okato} />}
                {entity.oktmo && <MyDataLine title='ОКТМО: ' content={entity.oktmo} />}
                {entity.okogu && <MyDataLine title='ОКОГУ: ' content={entity.okogu} />}
                {entity.okopf && <MyDataLine title='ОКОПФ: ' content={entity.okopf} />}
                {entity.okfs && <MyDataLine title='ОКФС:  ' content={entity.okfs} />}
                <div style={{ height: 20 }} />
                {
                    entity.nonExistingReports && entity.nonExistingReports.length > 0 &&
                    <MyDataLine
                        bulletShow={true}
                        bulletColor='red'
                        title='Финансовая отчетность'
                        content={' за ' +
                            + entity.nonExistingReports.join(', ') + ' '
                            + entity.nonExistingReports.length > 1 ? 'годы' : 'год'
                        + ' может быть не сдана в ФНС (Росстат).'
                        }
                    />
                }
                {entity.finRepRows && <MyDataLine title='Прибыль: ' content={entity.buildRecentNetProfit()} />}
                {entity.finRepRows && <MyDataLine title='Выручка: ' content={entity.buildRecentRevenue()} />}
                {entity.finRepRows && <MyDataLine title='Активы:  ' content={entity.buildRecentAssets()} />}
                <div style={{ height: 20 }} />
                {source.headcount && <MyDataLine title='Численность персонала:  ' content={source.headcount + ' чел.'} />}
                <div style={{ height: 20 }} />
                {(source.isbad_supplier === 1
                    || source.isbad_disqualified === 1
                    || source.isbad_mass_CEO === 1
                    || source.isbad_mass_shareholders === 1
                    || source.isbad_finance === 1
                    || source.isbad_sanctions === 1
                ) &&
                    <Box>
                        <MyDataLine title='Особые отметки' />
                        {source.isbad_supplier === 1
                            && <MyDataLine bulletShow={true} bulletColor='red' content='Имеются записи в реестре недобросовестных поставщиков' />
                        }
                        {source.isbad_disqualified === 1
                            && <MyDataLine bulletShow={true} bulletColor='red' content='Имеются записи о дисквалификации должностных лиц' />
                        }
                        {source.isbad_finance === 1
                            && <MyDataLine bulletShow={true} bulletColor='red' content='Имеются записи о нелегальной деятельности на финансовом рынке согласно данным ЦБ РФ' />
                        }
                        {source.isbad_mass_CEO === 1
                            && <MyDataLine bulletShow={true} bulletColor='orange' content='Имеются записи о массовом руководителе' />
                        }
                        {source.isbad_mass_shareholders === 1
                            && <MyDataLine bulletShow={true} bulletColor='orange' content='Имеются записи о массовом учредителе' />
                        }
                        {source.isbad_sanctions === 1
                            && <MyDataLine
                                bulletShow={true}
                                bulletColor='black'
                                content={
                                    'Санкционные списки'
                                    + (
                                        source.isbad_sanctions_text && source.isbad_sanctions_text.length > 0
                                            ? (': ' + source.isbad_sanctions_text.join(', '))
                                            : ''
                                    )
                                }
                            />
                        }
                        <div style={{ height: 20 }} />
                    </Box>
                }
            </Box >
        </BusinessDetailsSkeleton >
    );
};

export default CorpCommonSection;
