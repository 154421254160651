// components/AnalysisDetailsSection.js
import React from 'react';
import { Container, Box } from '@mui/material';
import AnalysisDetails from './AnalysisDetails';
import Spacer from './Spacer';

function AnalysisDetailsSection() {
    return (
        <Box sx={{ backgroundColor: 'white', py: 4 }}>
            <Container maxWidth="lg">
                <Spacer height="20px" />
                <AnalysisDetails />
                <Spacer height="20px" />
            </Container>
        </Box>
    );
}

export default AnalysisDetailsSection;
