import React, { useState, useRef } from 'react';
import { Typography, Box } from '@mui/material';
import { formatDate, buildTextLine } from '../../utils/utils';
import LicenseAndTrademarkValidity from '../../classes/LicenseAndTrademarkValidity';
import BusinessDetailsSkeleton from './BusinessDetailsSkeleton';
import MyFilterButton from '../common/MyFilterButton';
import Entity from '../../classes/Entity';
import './styles/Dashboard.css';

const TrademarkSection = ({ entity, analytics }) => {
    const [trademarkFilter, setTrademarkFilter] = useState(LicenseAndTrademarkValidity.UNDEFINED);
    const scrollController = useRef(null);

    const scrollToStart = () => {
        scrollController.current?.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const toggleFilter = (newFilter) => {
        setTrademarkFilter((prev) => (newFilter === prev || newFilter == null ? LicenseAndTrademarkValidity.UNDEFINED : newFilter));
        scrollToStart();
    };

    const buildTrademarksWidgets = () => {
        const widgets = [];
        const { trademarks_all, trademarks_valid, trademarks_passed, trademarks_unknown } = entity;

        const trademarks_filtered = trademarkFilter === LicenseAndTrademarkValidity.UNDEFINED
            ? [...(trademarks_valid ?? []), ...(trademarks_passed ?? []), ...(trademarks_unknown ?? [])]
            : trademarkFilter === LicenseAndTrademarkValidity.VALID
                ? trademarks_valid
                : trademarkFilter === LicenseAndTrademarkValidity.PASSED
                    ? trademarks_passed
                    : trademarks_unknown;

        let noData;
        if (!trademarks_all || trademarks_all.length === 0) {
            noData = 'Записи о товарных знаках (знаках обслуживания) не найдены.\nЭто значит, что, скорее всего, действующих знаков нет.';
        }

        if (noData) {
            widgets.push(buildTextLine({ bulletShow: false, content: noData }));
        } else if (trademarks_filtered) {
            trademarks_filtered.forEach((trademark) => {
                const isValid = trademark.validity === LicenseAndTrademarkValidity.VALID;
                const isPassed = trademark.validity === LicenseAndTrademarkValidity.PASSED;
                const title = isValid
                    ? `Действует до ${formatDate(trademark.validTill)}`
                    : isPassed
                        ? `Истек ${formatDate(trademark.validTill)}`
                        : 'Срок не указан';

                widgets.push(buildTextLine({
                    bulletShow: true,
                    bulletColor: isValid ? 'green' : isPassed ? 'red' : null,
                    title: title,
                    content: '',
                }));

                widgets.push(
                    buildTextLine({
                        bulletShow: false,
                        url: trademark.link,
                        content: 'Открыть на ФИПС',
                    })
                );

                widgets.push(buildTextLine({
                    bulletShow: false,
                    title: null,
                    content: `№${trademark.number}${trademark.issueDate ? `. Действует с ${formatDate(trademark.issueDate)}` : ''}`,
                }));

                widgets.push(<Box height={20} />);
            });
        }

        return widgets;
    };

    const buildWidgetsAfterTitleBeforeFilters = () => {
        const widgets = [];
        const { trademarks_all } = entity;

        if (trademarks_all && trademarks_all.length > 0) {
            widgets.push(buildTextLine({
                bulletShow: false,
                title: `Найдено знаков: ${trademarks_all.length}`,
                content: '',
            }));
        }
        return widgets;
    };

    const buildFilteringWidgets = () => {
        const widgets = [];
        const { trademarks_all } = entity;

        if (trademarks_all && trademarks_all.length > 1) {
            widgets.push(
                <Box display="flex">
                    <MyFilterButton
                        onClick={() => toggleFilter(LicenseAndTrademarkValidity.UNDEFINED)}
                        title="Все"
                        active={trademarkFilter === LicenseAndTrademarkValidity.UNDEFINED}
                    />
                    <MyFilterButton
                        onClick={() => toggleFilter(LicenseAndTrademarkValidity.VALID)}
                        title="Действует"
                        active={trademarkFilter === LicenseAndTrademarkValidity.VALID}
                    />
                    <MyFilterButton
                        onClick={() => toggleFilter(LicenseAndTrademarkValidity.PASSED)}
                        title="Истек"
                        active={trademarkFilter === LicenseAndTrademarkValidity.PASSED}
                    />
                    <MyFilterButton
                        onClick={() => toggleFilter(LicenseAndTrademarkValidity.UNKNOWN)}
                        title="Срок не указан"
                        active={trademarkFilter === LicenseAndTrademarkValidity.UNKNOWN}
                    />
                </Box>
            );
        }
        return widgets;
    };

    return (
        <BusinessDetailsSkeleton
            entityName={entity.name}
            h1="Товарные знаки и знаки обслуживания"
            h1tooltip={null}
            widgetsAfterTitleBeforeFilters={buildWidgetsAfterTitleBeforeFilters()}
            filteringWidgets={buildFilteringWidgets()}
            scrollController={scrollController}
            mainContent={buildTrademarksWidgets()}
        />
    );
};

export default TrademarkSection;
