import React, { useRef } from 'react';
import { Typography, Box } from '@mui/material';
import { buildTextLine } from '../../utils/utils';
import BusinessDetailsSkeleton from './BusinessDetailsSkeleton';
import Entity from '../../classes/Entity';
import './styles/Dashboard.css';

const SubdivisionSection = ({ entity, analytics }) => {
    const scrollController = useRef(null);

    const scrollToStart = () => {
        scrollController.current?.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const buildSubdivisionsWidgets = () => {
        const widgets = [];
        const { subdivisions } = entity;

        let noData;
        if (!subdivisions || subdivisions.length === 0) {
            noData = 'Записи о филиалах и представительствах не найдены.\nЭто значит, что, скорее всего, их нет.';
        }

        if (noData) {
            widgets.push(buildTextLine({ bulletShow: false, content: noData }));
        } else {
            subdivisions.forEach((element) => {
                widgets.push(buildTextLine({
                    bulletShow: true,
                    content: element.toString(),
                }));
            });
        }

        return widgets;
    };

    const buildWidgetsAfterTitleBeforeFilters = () => {
        const widgets = [];
        const { subdivisions } = entity;

        if (subdivisions && subdivisions.length > 0) {
            widgets.push(buildTextLine({
                bulletShow: false,
                title: `Найдено филиалов и представительств: ${subdivisions.length}`,
                content: '',
            }));
        }

        return widgets;
    };

    return (
        <BusinessDetailsSkeleton
            entityName={entity.name}
            h1="Филиалы и представительства"
            h1tooltip={null}
            widgetsAfterTitleBeforeFilters={buildWidgetsAfterTitleBeforeFilters()}
            filteringWidgets={[]}
            widgetsAfterFilters={null}
            scrollController={scrollController}
            mainContent={buildSubdivisionsWidgets()}
        />
    );
};

export default SubdivisionSection;
