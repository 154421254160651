import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Box, Button, Typography, List, ListItem } from '@mui/material';
import { useSnackbar } from '../../context/SnackbarContext';
import { myDebugPrint } from '../../utils/debug';
import { FiDelete } from 'react-icons/fi';

const MyFileUpload = ({ dragDropCTA, handleUpload, isUploading }) => {
    const [acceptedFiles, setAcceptedFiles] = useState([]);
    const showSnackbar = useSnackbar();

    const onDrop = useCallback((newAcceptedFiles, rejectedFiles) => {
        if (rejectedFiles.length) {
            showSnackbar('Разрешены только файлы .txt', 'error');
        }
        const updatedFiles = [...acceptedFiles, ...newAcceptedFiles];

        // Sort files by name in ascending order
        updatedFiles.sort((a, b) => a.name.localeCompare(b.name));

        setAcceptedFiles(updatedFiles);

        newAcceptedFiles.forEach(file => {
            myDebugPrint(`file: ${file.name}`);
        });
    }, [acceptedFiles, showSnackbar]);

    const handleFileRemove = (index) => {
        myDebugPrint(`remove file at ${index}`);
        const newAccepted = acceptedFiles.filter((_, elIndex) => elIndex !== index);
        setAcceptedFiles(newAccepted);
    }

    const checkForDuplicates = (file, index, array) => {
        return array.filter(f => f.name === file.name && f.size === file.size).length > 1;
    }

    const { getRootProps, getInputProps, fileRejections } = useDropzone({
        onDrop,
        accept: 'text/plain',
        multiple: true,
    });

    if (isUploading) {
        return <Box></Box>;
    }
    return (
        isUploading ? <Box></Box>
            :
            <Box>
                <Box
                    {...getRootProps()}
                    sx={{
                        border: '2px dashed grey',
                        borderRadius: '8px',
                        padding: '20px',
                        textAlign: 'start',
                        cursor: 'pointer',
                        backgroundColor: '#ededfa'
                    }}
                >
                    <input {...getInputProps()} />
                    <Typography variant="h6">
                        {dragDropCTA ?? 'Перетащите файлы'}
                    </Typography>
                    {/* <Typography variant="body2" sx={{ marginBottom: 2 }}>
                    {'или'}
                </Typography>
                <Button variant="contained" component="span">
                    Выберите файлы вручную
                </Button> */}
                </Box>
                {acceptedFiles && acceptedFiles.length > 0 && (
                    <Box mt={2}>
                        <Typography variant="h6">Проверьте список файлов, подготовленных к загрузке:</Typography>
                        <List>
                            {acceptedFiles.map((file, index) => (
                                <ListItem key={`${file.name}-${index}`}>
                                    <Typography variant='body2' sx={{ color: 'black' }}>
                                        {file.name} {checkForDuplicates(file, index, acceptedFiles) && (
                                            <span style={{ color: 'red' }}>(Такой файл не один!)</span>
                                        )}
                                    </Typography>
                                    <Typography variant='body2' sx={{ color: 'red', paddingLeft: '5px', cursor: 'pointer' }} onClick={() => handleFileRemove(index)}>
                                        ⌫ Удалить
                                    </Typography>
                                </ListItem>
                            ))}
                        </List>
                        <Button variant="contained" component="span" color='success' onClick={() => handleUpload(acceptedFiles)}>
                            Отправить на анализ
                        </Button>
                    </Box>
                )}
                {/* {fileRejections.length > 0 && (
                <Box mt={2} color="red">
                    <Typography variant="h6">Отклоненные файлы:</Typography>
                    <List>
                        {fileRejections.map(({ file, errors }) => (
                            <ListItem key={file.name}>
                                {file.name} - {errors.map(e => e.message).join(', ')}
                            </ListItem>
                        ))}
                    </List>
                </Box>
            )} */}
            </Box>
    );
};

export default MyFileUpload;
