import { parseDateFromNullableString } from '../utils/utils';

class TaxesPaid {
    constructor({
        year,
        paymentsByTaxes,
        totalPaid,
        totalOverdue,
        overdueDate,
        socialTaxes,
        propertyTaxes,
        transportTaxes,
        profitTaxes,
        otherTaxes,
        vatTaxes,
    }) {
        this.year = year;
        this.paymentsByTaxes = paymentsByTaxes;
        this.totalPaid = totalPaid;
        this.totalOverdue = totalOverdue;
        this.overdueDate = overdueDate;
        this.socialTaxes = socialTaxes;
        this.propertyTaxes = propertyTaxes;
        this.transportTaxes = transportTaxes;
        this.profitTaxes = profitTaxes;
        this.otherTaxes = otherTaxes;
        this.vatTaxes = vatTaxes;
    }

    static fromJson(jsonData) {
        const parsingTaxes = new TaxesPaid({
            year: parseInt(jsonData['СведУплГод'], 10),
            totalPaid: jsonData['СумУпл'] ? parseFloat(jsonData['СумУпл']) : null,
            totalOverdue: jsonData['СумНедоим'] ? parseFloat(jsonData['СумНедоим']) : null,
            overdueDate: parseDateFromNullableString(jsonData['НедоимДата']),
        });

        if (Array.isArray(jsonData['СведУпл'])) {
            jsonData['СведУпл'].forEach((taxPaid) => {
                const taxName = taxPaid['Наим'].toString();
                const amount = parseFloat(taxPaid['Сумма']) || 0;
                if (amount !== 0) {
                    parsingTaxes.paymentsByTaxes = parsingTaxes.paymentsByTaxes || {};
                    parsingTaxes.paymentsByTaxes[taxName] = amount;

                    if (taxName.startsWith('Налог на имущество')) {
                        parsingTaxes.propertyTaxes = parsingTaxes.propertyTaxes || {};
                        parsingTaxes.propertyTaxes[taxName] = amount;
                    } else if (taxName.startsWith('Транспортный налог')) {
                        parsingTaxes.transportTaxes = parsingTaxes.transportTaxes || {};
                        parsingTaxes.transportTaxes[taxName] = amount;
                    } else if (taxName.startsWith('Страховые взносы') || taxName.startsWith('Страховые и другие взносы')) {
                        parsingTaxes.socialTaxes = parsingTaxes.socialTaxes || {};
                        parsingTaxes.socialTaxes[taxName] = amount;
                    } else if (taxName.startsWith('Налог на прибыль') || taxName.startsWith('Налог, взимаемый в связи с  применением упрощенной')) {
                        parsingTaxes.profitTaxes = parsingTaxes.profitTaxes || {};
                        parsingTaxes.profitTaxes[taxName] = amount;
                    } else if (taxName.startsWith('Налог на добавленную стоимость') || taxName.startsWith('НДС')) {
                        parsingTaxes.vatTaxes = parsingTaxes.vatTaxes || {};
                        parsingTaxes.vatTaxes[taxName] = amount;
                    } else {
                        parsingTaxes.otherTaxes = parsingTaxes.otherTaxes || {};
                        parsingTaxes.otherTaxes[taxName] = amount;
                    }
                }
            });
        }

        return parsingTaxes;
    }
}

export default TaxesPaid;
