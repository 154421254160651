import React, { useState, useEffect } from 'react';
import { Box, Typography, List, ListItem, Pagination } from '@mui/material';
import BusinessCard from './BusinessCard';
import PersonCard from './PersonCard';
import CircleLoader from 'react-spinners/CircleLoader';
import { useNavigate, useLocation } from 'react-router-dom';
import MyHistory from './MyHistory';

const SearchResults = ({ entities, person, nothingFound, isSearching }) => {
    const navigate = useNavigate();
    const location = useLocation();

    const entitiesPerPage = 10;

    // Extract 'page' query parameter from URL
    const getPageFromQuery = () => {
        const params = new URLSearchParams(location.search);
        const pageParam = parseInt(params.get('page'), 10);
        return isNaN(pageParam) || pageParam < 1 ? 1 : pageParam;
    };

    const [page, setPage] = useState(getPageFromQuery());

    // Update 'page' state when URL changes
    useEffect(() => {
        const currentPage = getPageFromQuery();
        if (currentPage !== page) {
            setPage(currentPage);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.search]);

    // Update URL when 'page' state changes
    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const currentPageInURL = params.get('page');

        // Check if the current URL reflects the desired state
        if (page === 1 && currentPageInURL) {
            params.delete('page');
            navigate(`${location.pathname}?${params.toString()}`, { replace: true });
        } else if (page !== 1 && currentPageInURL !== String(page)) {
            params.set('page', page);
            navigate(`${location.pathname}?${params.toString()}`, { replace: false });
        }

        // Scroll to top smoothly
        window.scrollTo({ top: 0, behavior: 'smooth' });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page]);

    const handlePageChange = (event, value) => {
        setPage(value);
    };

    const startIndex = (page - 1) * entitiesPerPage;
    const endIndex = startIndex + entitiesPerPage;
    const paginatedEntities = entities ? entities.slice(startIndex, endIndex) : [];

    return (
        <Box sx={{ padding: 2, maxWidth: 800, width: '100%', justifyContent: 'left' }}>
            <Box sx={{ maxWidth: 800, width: '100%', textAlign: 'left', marginBottom: 0 }}>
                {nothingFound ? (
                    <Box sx={{ width: '100%' }}>
                        <Typography variant="body2">
                            Ничего не найдено, попробуйте уточнить запрос.
                        </Typography>
                    </Box>
                ) : !isSearching && !entities && !person ? (
                    <Box sx={{ width: '100%' }}>
                        <Typography variant="h2" sx={{ fontSize: '2.5em' }}>
                            Начните с поиска компании или ИП
                        </Typography>
                        <Typography variant="body2">
                            введите поисковый запрос в поле выше.
                        </Typography>
                        <Typography variant="h2" sx={{ fontSize: '2.5em', mt: 5 }}>
                            Создайте новый проект анализа выписок
                        </Typography>
                        <Typography variant="body2">
                            на <a style={{ textDecoration: 'none', cursor: 'pointer', color: '#1976D2' }}
                                href="\cash"
                            >
                                этой странице
                            </a>
                        </Typography>
                        <MyHistory />
                    </Box>
                ) : isSearching ? (
                    <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', gap: 2 }}>
                        <CircleLoader color="#8A4B9A" size={45} />
                        <Typography variant="body2">
                            Ищем в свежих базах ФНС, арбитражных судов, корпорации МСП и других источниках... Скоро здесь появятся нужные данные.
                        </Typography>
                    </Box>
                ) : null}
            </Box>

            {person && (
                <List sx={{ padding: 0 }}>
                    <ListItem sx={{ justifyContent: 'left', paddingLeft: 0 }}>
                        <Box sx={{ maxWidth: 800, width: '100%' }}>
                            <PersonCard person={person} />
                        </Box>
                    </ListItem>
                </List>
            )}

            {paginatedEntities.length > 0 && (
                <List sx={{ padding: 0 }}>
                    {paginatedEntities.map((entity, index) => (
                        <ListItem
                            key={startIndex + index}
                            sx={{ justifyContent: 'left', paddingTop: '10px', paddingLeft: 0, paddingRight: 0 }}
                        >
                            <Box sx={{ maxWidth: 800, width: '100%' }}>
                                <BusinessCard
                                    entity={entity}
                                    showCEO={false}
                                    showShareholders={false}
                                />
                            </Box>
                        </ListItem>
                    ))}
                </List>
            )}

            {entities && entities.length > entitiesPerPage && (
                <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 4 }}>
                    <Pagination
                        count={Math.ceil(entities.length / entitiesPerPage)}
                        page={page}
                        onChange={handlePageChange}
                        color="primary"
                    />
                </Box>
            )}

            {entities && entities.length === 100 && page === Math.ceil(100 / entitiesPerPage) && (
                <Box sx={{ width: '100%', textAlign: 'center', marginTop: 4 }}>
                    <Typography variant="body2">
                        Показаны только 100 организаций. Если нужные данные не найдены, попробуйте уточнить поисковый запрос.
                    </Typography>
                </Box>
            )}
        </Box>
    );
};

export default SearchResults;
