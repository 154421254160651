import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

const StatusIndicator = styled('div')(({ theme, status }) => ({
    width: 24,
    height: 24,
    borderRadius: '50%',
    backgroundColor: status === 'active' ? theme.palette.success.main : theme.palette.error.main,
}));

const PersonCard = ({ person }) => {
    return (
        <Box sx={{ maxWidth: '800', width: '100%', mb: 0 }}>
            <Card elevation={0} sx={{ backgroundColor: '#fafafa' }} >
                <CardContent sx={{ padding: 0 }}>
                    <Box display="flex" alignItems="flex-start">
                        <Box flexGrow={1}>
                            <Typography variant="h6" color="black">
                                {person.fullName}
                            </Typography>
                            <Typography variant="body2">ИНН {person.taxNumber}</Typography>
                            {person.trademarks && person.trademarks.length > 0 && (
                                <Typography variant="body2" color="success.main">
                                    Найдены торговые марки, связанные с этим человеком
                                </Typography>
                            )}
                            {person.massCEO && (
                                <Tooltip title="Реестр массовых директоров">
                                    <Typography variant="body2" color="error.main">
                                        Запись в реестре "массовых директоров" не означает, что этот человек "номинал". Однако, в отношении такого лица рекомендуется более тщательная проверка.
                                    </Typography>
                                </Tooltip>
                            )}
                            {person.massShares && (
                                <Tooltip title="Реестр массовых учредителей">
                                    <Typography variant="body2" color="error.main">
                                        Запись в реестре "массовых учредителей" не означает, что этот человек "номинал". Однако, в отношении такого лица рекомендуется более тщательная проверка.
                                    </Typography>
                                </Tooltip>
                            )}
                            {person.badSupplier && (
                                <Tooltip title="Реестр недобросовестных поставщиков">
                                    <Typography variant="body2" color="error.main">
                                        В отношении такого лица рекомендуется более тщательная проверка.
                                    </Typography>
                                </Tooltip>
                            )}
                            {person.sanctions && (
                                <Typography variant="body2" color="grey">
                                    Санкционные списки{person.sanctionsList ? `: ${person.sanctionsList.join(', ')}` : ''}
                                </Typography>
                            )}
                        </Box>
                        <Box ml={2}>
                            {(person.badSupplier || person.sanctions || person.massCEO || person.massShares) && (
                                <StatusIndicator status="bankrupt" />
                            )}
                        </Box>
                    </Box>
                </CardContent>
            </Card>
        </Box >
    );
};

export default PersonCard;
