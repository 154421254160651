// components/AnalysisDetails.js
import React from 'react';
import { Container, Grid, Typography, Box, Paper } from '@mui/material';
import AnalysisDetailsItem from './AnalysisDetailsItem';

const details = [
    {
        icon: 'fas fa-shower',
        title: 'Чистые обороты',
        description: 'Все поступления и платежи будут автоматически очищены от перераспределений по собственным счетам, а в случае анализа группы - обороты внутри группы попадут в отдельную группу',
    },
    {
        icon: 'fas fa-boxes-packing',
        title: 'Реальные потоки',
        description: 'Расчеты по займам и кредитам, возвраты, ошибочные платежи попадут в соответствующие категории. Оборот без таких операций позволит определить справедливые кредитные лимиты',
    },
    {
        icon: 'fas fa-user-friends',
        title: 'Вывод на ИП и физлиц',
        description: 'Распространенный способ вывода денежных средств из компании - завышенные зарплаты, снятие наличных, подотчетные и, конечно, переводы на специально-подобранных контрагентов-ИП',
    },
    {
        icon: 'fas fa-bezier-curve',
        title: 'Расчеты с аффилированными лицами',
        description: 'Сервис покажет как расчеты с юридически связанными компаниями, так и с организациями, которые могут быть экономически взаимозависимы с объектом анализа',
    },
    {
        icon: 'fas fa-retweet',
        title: 'Встречные взаиморасчеты',
        description: 'Одновременное заключение и сделок покупки, и сделок продажи с одним и тем же лицом может указывать на нерыночность отношений',
    },
    {
        icon: 'fas fa-biohazard',
        title: 'Совершение "особых" видов платежей',
        description: 'ФинРадар мгновенно покажет займы, дивиденды, цессии, платежи за третьих лиц другие необычные платежи',
    },
];

function AnalysisDetails() {
    return (
        <Box>
            <Typography variant="h2" gutterBottom
                sx={{
                    fontSize: '2.1em',
                    color: '#5E5C5C',
                    fontWeight: 'bold',
                }}>
                Что покажет анализ выписки
            </Typography>
            <Typography variant="body1" gutterBottom>
                Анализ банковских выписок в ФинРадаре
                позволяет быстро получить информацию
                о чистых оборотах и реальных поступлениях
                для оценки кредитного лимита.
            </Typography>
            <Typography variant="body1" gutterBottom>
                Сервис подсветит операции с аффилированными,
                подозрительными и контролируемыми контрагентами,
                поможет оперативно обнаружить подозрительные сделки,
                вывод активов, потенциальную дебиторскую задолженность,
                а также платежи и поступления, выходящие за рамки
                обычной хозяйственной деятельности.
            </Typography>
            <Grid container spacing={4}>
                {details.map((detail, index) => (
                    <Grid item xs={12} md={4} key={index}>
                        <Box
                            component={Paper}
                            elevation={0}
                            sx={{
                                p: 2,
                                display: 'flex',
                                flexDirection: 'column',
                                height: '100%',
                            }}
                        >
                            <Box sx={{ display: 'flex', alignItems: 'flex-start', mb: 2 }}>
                                <AnalysisDetailsItem {...detail} />
                            </Box>
                        </Box>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
}

export default AnalysisDetails;
