import React, { useState, useRef, useEffect } from 'react';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { MultiBackend } from 'react-dnd-multi-backend';
import { HTML5toTouch } from 'rdndmb-html5-to-touch';
import {
    Box,
    Typography,
    Snackbar,
    Alert,
    AppBar,
    Toolbar,
    Button,
    TextField,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    IconButton,
} from '@mui/material';
import { Close, Delete } from '@mui/icons-material';

const initialItems = [
    { id: 'viralMarketing', text: 'Viral Marketing' },
    { id: 'publicRelations', text: 'Public Relations' },
    { id: 'unconventionalPR', text: 'Unconventional PR' },
    { id: 'semPaidSearch', text: 'SEM / Paid Search' },
    { id: 'socialDisplayAds', text: 'Social & Display Ads' },
    { id: 'offlineAds', text: 'Offline Ads' },
    { id: 'seo', text: 'SEO' },
    { id: 'contentMarketing', text: 'Content Marketing' },
    { id: 'emailMarketing', text: 'Email Marketing' },
    { id: 'engineeringAsMarketing', text: 'Engineering as Marketing' },
    { id: 'targetingBlogs', text: 'Targeting Blogs' },
    { id: 'businessDevelopment', text: 'Business Development' },
    { id: 'proactiveSales', text: 'Proactive Sales' },
    { id: 'affiliatePrograms', text: 'Affiliate Programs' },
    { id: 'existingPlatforms', text: 'Existing Platforms' },
    { id: 'tradeShows', text: 'Trade Shows' },
    { id: 'offlineEvents', text: 'Offline Events' },
    { id: 'speakingEngagements', text: 'Speaking Engagements' },
    { id: 'communityBuilding', text: 'Community Building' },
];


const ItemTypes = {
    CHANNEL: 'channel',
};

const ChannelItem = ({
    id,
    index,
    moveChannel,
    circle,
    itemSize,
    itemColor,
    onDeleteItem,
    showDeleteIcons,
    language,
    translations,
}) => {
    const [{ isDragging }, drag] = useDrag({
        type: ItemTypes.CHANNEL,
        item: { id, fromCircle: circle },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    const channelText = translations[language][id] || id;

    return (
        <Box
            ref={drag}
            sx={{
                width: itemSize,
                height: itemSize,
                backgroundColor: itemColor || '#fff',
                borderRadius: '50%',
                opacity: isDragging ? 0.5 : 1,
                cursor: 'move',
                textAlign: 'center',
                boxShadow: 3,
                position: 'relative',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                userSelect: 'none',
            }}
        >
            {showDeleteIcons && (
                <IconButton
                    size="small"
                    sx={{
                        position: 'absolute',
                        top: itemSize * 0.05,
                        right: itemSize * 0.05,
                        backgroundColor: 'white',
                        '&:hover': {
                            backgroundColor: 'grey',
                        },
                    }}
                    onClick={(e) => {
                        e.stopPropagation();
                        onDeleteItem(id, circle);
                    }}
                >
                    <Delete fontSize="small" />
                </IconButton>
            )}
            <Typography variant="body2" sx={{ textAlign: 'center', px: 1 }}>
                {channelText}
            </Typography>
        </Box>
    );
};

const Circle = ({
    R_in,
    R_out,
    channels,
    moveChannel,
    circle,
    color,
    itemColor,
    itemSize,
    onDeleteItem,
    showDeleteIcons,
    language,
    translations,
}) => {
    const [, drop] = useDrop({
        accept: ItemTypes.CHANNEL,
        drop: (item) => moveChannel(item.id, item.fromCircle, circle),
    });

    const numItems = channels.length || 1;
    const angleStep = (2 * Math.PI) / numItems;

    const radius = R_out;
    const R_mid = (R_in + R_out) / 2;

    return (
        <Box
            ref={drop}
            sx={{
                position: 'absolute',
                width: radius * 2,
                height: radius * 2,
                borderRadius: '50%',
                backgroundColor: color,
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
            }}
        >
            {channels.map((channel, index) => {
                const angle = index * angleStep - Math.PI / 2;
                const x = radius + R_mid * Math.cos(angle) - itemSize / 2;
                const y = radius + R_mid * Math.sin(angle) - itemSize / 2;

                return (
                    <Box
                        key={channel}
                        sx={{
                            position: 'absolute',
                            left: x,
                            top: y,
                        }}
                    >
                        <ChannelItem
                            id={channel}
                            index={index}
                            moveChannel={moveChannel}
                            circle={circle}
                            itemSize={itemSize}
                            itemColor={itemColor}
                            onDeleteItem={onDeleteItem}
                            showDeleteIcons={showDeleteIcons}
                            language={language}
                            translations={translations}
                        />
                    </Box>
                );
            })}
        </Box>
    );
};


const BullseyeDiagram = () => {
    const containerRef = useRef(null);
    const [containerSize, setContainerSize] = useState(800);
    const [innerRadius, setInnerRadius] = useState(0);
    const [middleRadius, setMiddleRadius] = useState(0);
    const [outerRadius, setOuterRadius] = useState(0);
    const [itemSize, setItemSize] = useState(0);

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');

    // Circle colors
    const [outerCircleColor, setOuterCircleColor] = useState('#ADD8E6');
    const [middleCircleColor, setMiddleCircleColor] = useState('#90EE90');
    const [innerCircleColor, setInnerCircleColor] = useState('#FFB6C1');

    // Item colors
    const [outerItemColor, setOuterItemColor] = useState('#f0f8ff');
    const [middleItemColor, setMiddleItemColor] = useState('#e6e6fa');
    const [innerItemColor, setInnerItemColor] = useState('#ffe4e1');

    const [colorDialogOpen, setColorDialogOpen] = useState(false);

    const [newItemName, setNewItemName] = useState('');
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [itemToDelete, setItemToDelete] = useState(null);

    const [deletedInitialItems, setDeletedInitialItems] = useState([]);
    const [showDeleteIcons, setShowDeleteIcons] = useState(true);
    const [language, setLanguage] = useState('en'); // 'en' or 'ru'

    const toggleLanguage = () => {
        setLanguage((prevLanguage) => (prevLanguage === 'en' ? 'ru' : 'en'));
    };

    const translations = {
        en: {
            bullseyeDiagram: 'Bullseye Diagram',
            newItem: 'New Item',
            addItem: 'Add Item',
            reset: 'Reset',
            adjustColors: 'Adjust Colors',
            hideDeleteIcons: 'Hide Delete Icons',
            showDeleteIcons: 'Show Delete Icons',
            switchToRussian: 'Switch to Russian',
            switchToEnglish: 'Switch to English',
            deleteItemTitle: 'Delete Item',
            deleteItemContent: 'Are you sure you want to delete the item',
            yes: 'Yes',
            no: 'No',
            circleColors: 'Circle Colors',
            itemColors: 'Item Colors',
            outerCircleColor: 'Outer Circle Color',
            middleCircleColor: 'Middle Circle Color',
            innerCircleColor: 'Inner Circle Color',
            outerItemColor: 'Outer Item Color',
            middleItemColor: 'Middle Item Color',
            innerItemColor: 'Inner Item Color',
            close: 'Close',
            theCircleHasReachedLimit: 'The circle has reached its limit.',
            viralMarketing: 'Viral Marketing',
            publicRelations: 'Public Relations',
            unconventionalPR: 'Unconventional PR',
            semPaidSearch: 'SEM / Paid Search',
            socialDisplayAds: 'Social & Display Ads',
            offlineAds: 'Offline Ads',
            seo: 'SEO',
            contentMarketing: 'Content Marketing',
            emailMarketing: 'Email Marketing',
            engineeringAsMarketing: 'Engineering as Marketing',
            targetingBlogs: 'Targeting Blogs',
            businessDevelopment: 'Business Development',
            proactiveSales: 'Proactive Sales',
            affiliatePrograms: 'Affiliate Programs',
            existingPlatforms: 'Existing Platforms',
            tradeShows: 'Trade Shows',
            offlineEvents: 'Offline Events',
            speakingEngagements: 'Speaking Engagements',
            communityBuilding: 'Community Building',
        },
        ru: {
            bullseyeDiagram: 'Диаграмма Bullseye',
            newItem: 'Новый элемент',
            addItem: 'Добавить',
            reset: 'Сброс',
            adjustColors: 'Настроить цвета',
            hideDeleteIcons: 'Скрыть удаление',
            showDeleteIcons: 'Показать удаление',
            switchToRussian: 'Переключить на Русский',
            switchToEnglish: 'Switch to English',
            deleteItemTitle: 'Удалить элемент',
            deleteItemContent: 'Вы уверены, что хотите удалить элемент',
            yes: 'Да',
            no: 'Нет',
            circleColors: 'Цвета кругов',
            itemColors: 'Цвета элементов',
            outerCircleColor: 'Цвет внешнего круга',
            middleCircleColor: 'Цвет среднего круга',
            innerCircleColor: 'Цвет внутреннего круга',
            outerItemColor: 'Цвет элементов внешнего круга',
            middleItemColor: 'Цвет элементов среднего круга',
            innerItemColor: 'Цвет элементов внутреннего круга',
            close: 'Закрыть',
            theCircleHasReachedLimit: 'Круг достиг своего лимита.',
            viralMarketing: 'Вирусный маркетинг',
            publicRelations: 'Связи с общественностью',
            unconventionalPR: 'Нетрадиционный PR',
            semPaidSearch: 'SEM / Платный поиск',
            socialDisplayAds: 'Социальная и медийная реклама',
            offlineAds: 'Оффлайн реклама',
            seo: 'SEO',
            contentMarketing: 'Контент-маркетинг',
            emailMarketing: 'Email-маркетинг',
            engineeringAsMarketing: 'Инжиниринг как маркетинг',
            targetingBlogs: 'Таргетинг на блоги',
            businessDevelopment: 'Развитие бизнеса',
            proactiveSales: 'Проактивные продажи',
            affiliatePrograms: 'Партнерские программы',
            existingPlatforms: 'Существующие платформы',
            tradeShows: 'Выставки',
            offlineEvents: 'Оффлайн мероприятия',
            speakingEngagements: 'Публичные выступления',
            communityBuilding: 'Создание сообщества',
        },
    };


    useEffect(() => {
        const updateSize = () => {
            if (containerRef.current) {
                const width = containerRef.current.clientWidth;
                const height = containerRef.current.clientHeight;
                const size = Math.min(width, height, 800);
                setContainerSize(size);

                const innerR = size / 6;
                setInnerRadius(innerR);
                setMiddleRadius(2 * innerR);
                setOuterRadius(3 * innerR);

                const ringWidth = innerR;
                setItemSize(ringWidth * 0.9);
            }
        };
        updateSize();
        window.addEventListener('resize', updateSize);
        return () => window.removeEventListener('resize', updateSize);
    }, []);

    const [outerChannels, setOuterChannels] = useState(initialItems.map((item) => item.id));

    const [middleChannels, setMiddleChannels] = useState([]);
    const [innerChannels, setInnerChannels] = useState([]);

    const moveChannel = (id, fromCircle, toCircle) => {
        if (toCircle === fromCircle) return;

        const removeChannel = (channels) => channels.filter((c) => c !== id);
        const addChannel = (channels, limit) => {
            if (!channels.includes(id) && channels.length < limit) {
                return [...channels, id];
            } else if (channels.includes(id)) {
                // Channel is already in the target circle
                return channels;
            } else {
                return null; // Cannot add due to limit
            }
        };

        let canAdd = true;

        if (toCircle === 'outer') {
            setOuterChannels((prevChannels) => {
                const newChannels = addChannel(prevChannels, 100);
                if (newChannels) {
                    return newChannels;
                } else {
                    canAdd = false;
                    return prevChannels;
                }
            });
        } else if (toCircle === 'middle') {
            setMiddleChannels((prevChannels) => {
                const newChannels = addChannel(prevChannels, 6);
                if (newChannels) {
                    return newChannels;
                } else {
                    canAdd = false;
                    return prevChannels;
                }
            });
        } else if (toCircle === 'inner') {
            setInnerChannels((prevChannels) => {
                const newChannels = addChannel(prevChannels, 3);
                if (newChannels) {
                    return newChannels;
                } else {
                    canAdd = false;
                    return prevChannels;
                }
            });
        }

        if (canAdd) {
            // Remove from the original circle
            if (fromCircle === 'outer') {
                setOuterChannels((prevChannels) => removeChannel(prevChannels));
            } else if (fromCircle === 'middle') {
                setMiddleChannels((prevChannels) => removeChannel(prevChannels));
            } else if (fromCircle === 'inner') {
                setInnerChannels((prevChannels) => removeChannel(prevChannels));
            }
        } else {
            // If adding failed, show snackbar and ensure the channel is in its original circle
            setSnackbarMessage(`${translations[language].theCircleHasReachedLimit}`);
            setSnackbarOpen(true);
        }
    };

    const resetDiagram = () => {
        setOuterChannels(initialItems.map((item) => item.id));
        setMiddleChannels([]);
        setInnerChannels([]);
    };


    const addNewItem = (itemName) => {
        const newItemId = itemName.trim();
        setOuterChannels((prevChannels) => [...prevChannels, newItemId]);
    };

    const onDeleteItem = (id, circle) => {
        setItemToDelete({ id, circle });
        setDeleteDialogOpen(true);
    };

    const deleteItem = () => {
        const { id } = itemToDelete;

        setOuterChannels((prevChannels) => prevChannels.filter((c) => c !== id));
        setMiddleChannels((prevChannels) => prevChannels.filter((c) => c !== id));
        setInnerChannels((prevChannels) => prevChannels.filter((c) => c !== id));

        setDeleteDialogOpen(false);
        setItemToDelete(null);
    };

    const handleAddItem = () => {
        if (newItemName.trim()) {
            addNewItem(newItemName.trim());
            setNewItemName('');
        }
    };


    return (
        <DndProvider backend={MultiBackend} options={HTML5toTouch}>
            {/* Top Menu */}
            <AppBar position="static">
                <Toolbar>
                    <Typography variant="h6" sx={{ flexGrow: 1 }}>
                        {translations[language].bullseyeDiagram}
                    </Typography>
                    <TextField
                        label={translations[language].newItem}
                        variant="outlined"
                        size="small"
                        value={newItemName}
                        onChange={(e) => setNewItemName(e.target.value)}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                e.preventDefault(); // Prevent default behavior
                                handleAddItem();
                            }
                        }}
                        sx={{ backgroundColor: 'white', borderRadius: 1, mr: 1 }}
                    />
                    <Button color="inherit" onClick={handleAddItem}>
                        {translations[language].addItem}
                    </Button>
                    <Button color="inherit" onClick={() => setShowDeleteIcons(!showDeleteIcons)}>
                        {showDeleteIcons
                            ? translations[language].hideDeleteIcons : translations[language].showDeleteIcons}
                    </Button>
                    <Button color="inherit" onClick={resetDiagram}>
                        {translations[language].reset}
                    </Button>
                    <Button color="inherit" onClick={() => setColorDialogOpen(true)}>
                        {translations[language].adjustColors}
                    </Button>
                    <Button color="inherit" onClick={toggleLanguage}>
                        {language === 'en' ? 'RU' : 'EN'}
                    </Button>
                </Toolbar>
            </AppBar>

            {/* Diagram Container */}
            <Box
                ref={containerRef}
                sx={{
                    position: 'relative',
                    width: '100%',
                    height: '800px',
                    maxWidth: '800px',
                    margin: 'auto',
                    mt: 3, // Adds 20px margin-top
                    boxSizing: 'border-box',
                    overflow: 'hidden',
                }}
            >
                {innerRadius > 0 && (
                    <>
                        {/* Outer Circle */}
                        <Circle
                            R_in={middleRadius}
                            R_out={outerRadius}
                            channels={outerChannels}
                            moveChannel={moveChannel}
                            circle="outer"
                            color={outerCircleColor}
                            itemColor={outerItemColor}
                            itemSize={itemSize}
                            onDeleteItem={onDeleteItem}
                            showDeleteIcons={showDeleteIcons}
                            language={language}
                            translations={translations}
                        />
                        {/* Middle Circle */}
                        <Circle
                            R_in={innerRadius}
                            R_out={middleRadius}
                            channels={middleChannels}
                            moveChannel={moveChannel}
                            circle="middle"
                            color={middleCircleColor}
                            itemColor={middleItemColor}
                            itemSize={itemSize}
                            onDeleteItem={onDeleteItem}
                            showDeleteIcons={showDeleteIcons}
                            language={language}
                            translations={translations}
                        />
                        {/* Inner Circle */}
                        <Circle
                            R_in={0}
                            R_out={innerRadius}
                            channels={innerChannels}
                            moveChannel={moveChannel}
                            circle="inner"
                            color={innerCircleColor}
                            itemColor={innerItemColor}
                            itemSize={itemSize}
                            onDeleteItem={onDeleteItem}
                            showDeleteIcons={showDeleteIcons}
                            language={language}
                            translations={translations}
                        />
                    </>
                )}
            </Box>

            {/* Snackbar for Limit Exceeded */}
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={3000}
                onClose={() => setSnackbarOpen(false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert onClose={() => setSnackbarOpen(false)} severity="warning" sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>

            {/* Color Picker Dialog */}
            <Dialog open={colorDialogOpen} onClose={() => setColorDialogOpen(false)}>
                <DialogTitle>{translations[language].adjustColors}</DialogTitle>
                <DialogContent>
                    {/* Circle Colors */}
                    <Typography variant="subtitle1">{translations[language].circleColors}</Typography>
                    <TextField
                        label={translations[language].outerCircleColor}
                        type="color"
                        value={outerCircleColor}
                        onChange={(e) => setOuterCircleColor(e.target.value)}
                        fullWidth
                        margin="dense"
                    />
                    <TextField
                        label={translations[language].middleCircleColor}
                        type="color"
                        value={middleCircleColor}
                        onChange={(e) => setMiddleCircleColor(e.target.value)}
                        fullWidth
                        margin="dense"
                    />
                    <TextField
                        label={translations[language].innerCircleColor}
                        type="color"
                        value={innerCircleColor}
                        onChange={(e) => setInnerCircleColor(e.target.value)}
                        fullWidth
                        margin="dense"
                    />

                    {/* Item Colors */}
                    <Typography variant="subtitle1" sx={{ mt: 2 }}>
                        {translations[language].itemColors}
                    </Typography>
                    <TextField
                        label={translations[language].outerItemColor}
                        type="color"
                        value={outerItemColor}
                        onChange={(e) => setOuterItemColor(e.target.value)}
                        fullWidth
                        margin="dense"
                    />
                    <TextField
                        label={translations[language].middleItemColor}
                        type="color"
                        value={middleItemColor}
                        onChange={(e) => setMiddleItemColor(e.target.value)}
                        fullWidth
                        margin="dense"
                    />
                    <TextField
                        label={translations[language].innerItemColor}
                        type="color"
                        value={innerItemColor}
                        onChange={(e) => setInnerItemColor(e.target.value)}
                        fullWidth
                        margin="dense"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setColorDialogOpen(false)}>{translations[language].close}</Button>
                </DialogActions>
            </Dialog>

            {/* Delete Confirmation Dialog */}
            <Dialog open={deleteDialogOpen} onClose={() => setDeleteDialogOpen(false)}>
                <DialogTitle>{translations[language].deleteItemTitle}</DialogTitle>
                <DialogContent>
                    <Typography>
                        {`${translations[language].deleteItemContent} "${translations[language][itemToDelete?.id] || itemToDelete?.id}"?`}
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDeleteDialogOpen(false)}>{translations[language].no}</Button>
                    <Button onClick={deleteItem} color="error">
                        {translations[language].yes}
                    </Button>
                </DialogActions>
            </Dialog>
        </DndProvider>
    );
};

export default BullseyeDiagram;
