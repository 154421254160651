import { parseDateFromNullableString } from '../utils/utils';

class BadSupply {
    constructor({
        badListNumber,
        datePublication,
        dateConfirmation,
        clientShortName,
        clientFullName,
        clientTaxNumber,
        clientTaxReason,
        supplyNumber,
        supplyDescription,
        price,
    }) {
        this.badListNumber = badListNumber;
        this.datePublication = datePublication;
        this.dateConfirmation = dateConfirmation;
        this.clientShortName = clientShortName;
        this.clientFullName = clientFullName;
        this.clientTaxNumber = clientTaxNumber;
        this.clientTaxReason = clientTaxReason;
        this.supplyNumber = supplyNumber;
        this.supplyDescription = supplyDescription;
        this.price = price;
    }

    static fromJson(jsonData) {
        return new BadSupply({
            badListNumber: jsonData['РеестрНомер'] || '',
            datePublication: parseDateFromNullableString(jsonData['ДатаПуб']),
            dateConfirmation: parseDateFromNullableString(jsonData['ДатаУтв']),
            clientShortName: jsonData['ЗаказНаимСокр'],
            clientFullName: jsonData['ЗаказНаимПолн'],
            clientTaxNumber: jsonData['ЗаказИНН'],
            clientTaxReason: jsonData['ЗаказКПП'],
            supplyNumber: jsonData['ЗакупНомер'],
            supplyDescription: jsonData['ЗакупОпис'],
            price: jsonData['ЦенаКонтр'] ? parseInt(jsonData['ЦенаКонтр'], 10) : null,
        });
    }
}

export default BadSupply;
