
import {
    Business, MonetizationOnOutlined,
    PaymentsOutlined, Balance, BadgeOutlined,
    Copyright, AccountTree
} from '@mui/icons-material';
import { Shield } from '@mui/icons-material'; // Adjust this import based on your icon library

export const pCommon = 'common';
export const pSubdivisions = 'subdivisions';
export const pFinrep = 'finrep';
export const pTaxes = 'taxes';
// const pVisualization = 'visualization';
// const pAssets = 'assets';
// const pLiabilities = 'liabilities';
// const pRatios = 'ratios';
// const pBeneficiaries = 'beneficiaries';
export const pKad = 'kad';
export const pLicense = 'license';
export const pTrademark = 'trademark';
export const pSME = 'sme';

class CorpDrawerTaps {
    constructor(title, icon) {
        this.title = title;
        this.icon = icon;
    }
}

export const corpDrawerTaps = {
    [pCommon]: new CorpDrawerTaps('Общие сведения', Business),
    [pFinrep]: new CorpDrawerTaps('Финансовая отчетность', MonetizationOnOutlined),
    [pTaxes]: new CorpDrawerTaps('Налоги', PaymentsOutlined),
    [pKad]: new CorpDrawerTaps('Арбитражные дела', Balance),
    [pLicense]: new CorpDrawerTaps('Лицензии', BadgeOutlined),
    [pTrademark]: new CorpDrawerTaps('Товарные знаки', Copyright),
    [pSME]: new CorpDrawerTaps('Поддержка бизнеса', Shield),
    [pSubdivisions]: new CorpDrawerTaps('Филиалы', AccountTree),
};

export default corpDrawerTaps;


