// components/ReportStep.js
import React from 'react';
import { Grid, Typography, Box } from '@mui/material';
import Spacer from './Spacer';

function ReportStep({ stepNumber, title, description }) {
    return (
        <Box>
            <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
                {stepNumber}
            </Typography>
            <Spacer height="10px" />
            <Typography variant="h6">
                {title}
            </Typography>
            <Typography variant="body2" sx={{ mt: 1 }}>
                {description}
            </Typography>
        </Box>
    );
}

export default ReportStep;
