import { myDebugPrint } from '../../utils/debug';
import { formatDate, parseDate, parseDateFromNullableString } from '../../utils/utils';
import Entity from '../Entity';

class CashProjectClass {
    constructor({
        projectId,
        projectName,
        createdOn,
        updatedOn,
        processingStatus,
        statementsSummary,
        accountStatements,
        overalls_by_accounts,
        overalls_by_accounts_reorg,
        overalls_by_months,
        transactiontype,
        transactiontype_counterpartytype_counterparty,
        counterparty,
        errorsReport,
        myCompanies = [],
        earliestDate, //this one is for charts
        latestDate, //this one is for charts and not in use actually
        minDate, //this one is for filters and to display the analysis period
        maxDate, //this one is for filters and to display the analysis period
        openingBalance = [],
        closingBalance = [],
        collectedTaxNumbersAndAccounts,
        risks,
        overalls_closing_balances_by_months = {}
    }) {
        this.projectId = projectId;
        this.projectName = projectName;
        this.createdOn = createdOn;
        this.updatedOn = updatedOn;
        this.processingStatus = processingStatus;
        this.statementsSummary = statementsSummary;
        this.accountStatements = accountStatements;
        this.overalls_by_accounts = overalls_by_accounts;
        this.overalls_by_accounts_reorg = overalls_by_accounts_reorg;
        this.overalls_by_months = overalls_by_months;
        this.transactiontype = transactiontype;
        this.transactiontype_counterpartytype_counterparty = transactiontype_counterpartytype_counterparty;
        this.counterparty = counterparty;
        this.errorsReport = errorsReport;
        this.myCompanies = myCompanies;
        this.earliestDate = earliestDate;
        this.latestDate = latestDate;
        this.minDate = minDate;
        this.maxDate = maxDate;
        this.openingBalance = openingBalance;
        this.closingBalance = closingBalance;
        this.collectedTaxNumbersAndAccounts = collectedTaxNumbersAndAccounts;
        this.risks = risks;
        this.overalls_closing_balances_by_months = overalls_closing_balances_by_months;
    }

    static fromJson(jsonData) {
        const normalizedData = {};

        for (const key in jsonData) {
            if (jsonData.hasOwnProperty(key)) {
                normalizedData[key.toLowerCase()] = jsonData[key];
            }
        }

        let accountStatements = [];
        let totalCashBalanceMonthly = {}
        try {
            const overalls_by_accounts = normalizedData['overalls_by_accounts'];
            overalls_by_accounts.forEach(account => {
                accountStatements.push({
                    'AccNmbr': account['AccNmbr'],
                    'INN': account['INN'],
                    'CurrencyCode': account['CurrencyCode'],
                    'Totals': account['Totals'],
                    'Statements': account['Statements'],
                })
            });
        } catch (e) {
            myDebugPrint(`error on proccessing summary
                statements in CashProjectClass.fromJSON: ${JSON.stringify(e)}`)
        }

        // try {
        //     const stSummary = normalizedData['statementssummary'];
        //     stSummary.forEach(statement => {
        //         // Loop through each key in the statement
        //         Object.keys(statement).forEach(key => {
        //             // Get the array of accounts for each key
        //             const accounts = statement[key];
        //             // myDebugPrint(`accounts = ${JSON.stringify(accounts)}`)
        //             // Loop through each record to extract the Inflow value
        //             accounts.forEach(account => {
        //                 if (!accountStatements[account.AccNmbr]) {
        //                     accountStatements[account.AccNmbr] = { 'totals': {}, 'data': [] };
        //                 }
        //                 const data = {};
        //                 Object.keys(account).forEach(accKey => {
        //                     data[accKey] = account[accKey];
        //                     const wasStartDate = accountStatements[account.AccNmbr]['totals']['DateStart'];
        //                     const wasEndDate = accountStatements[account.AccNmbr]['totals']['DateEnd'];
        //                     if (accKey === 'DateStart'
        //                         && (
        //                             !wasStartDate
        //                             || parseDate({ dateString: account[accKey] }) < parseDate({ dateString: wasStartDate })
        //                         )
        //                     ) {
        //                         accountStatements[account.AccNmbr]['totals']['DateStart'] = data[accKey]
        //                         accountStatements[account.AccNmbr]['totals']['WasOnStart'] = account['WasOnStart']
        //                     }
        //                     if (accKey === 'DateEnd'
        //                         && (
        //                             !wasEndDate
        //                             || parseDate({ dateString: account[accKey] }) > parseDate({ dateString: wasEndDate })
        //                         )
        //                     ) {
        //                         accountStatements[account.AccNmbr]['totals']['DateEnd'] = data[accKey]
        //                         accountStatements[account.AccNmbr]['totals']['WasOnEnd'] = account['WasOnEnd']
        //                     }
        //                 })
        //                 accountStatements[account.AccNmbr]['data'].push(data)
        //             });
        //         });
        //     });
        // } catch (e) {
        //     myDebugPrint(`error on proccessing summary 
        //         statements in CashProjectClass.fromJSON: ${JSON.stringify(e)}`)
        // }

        let project = new CashProjectClass({
            projectId: normalizedData['projectid'],
            projectName: normalizedData['projectname'],
            createdOn: parseDateFromNullableString(normalizedData['createdOn']),
            updatedOn: parseDateFromNullableString(normalizedData['updatedon']),
            processingStatus: normalizedData['proccessingstatus'],
            // statementsSummary: normalizedData['statementssummary'],
            accountStatements: accountStatements,
            overalls_by_accounts: normalizedData['overalls_by_accounts'],
            overalls_by_months: normalizedData['overalls_by_months'],
            transactiontype: normalizedData['transactiontype'],
            // transactiontype_counterpartytype_counterparty: normalizedData['transactiontype_counterpartytype_counterparty'],
            // counterparty: normalizedData['counterparty'],
            errorsReport: normalizedData['errorsreport'],
            myCompanies: [],
            collectedTaxNumbersAndAccounts: normalizedData['collectedtaxnumbersandaccounts'],
            risks: normalizedData['risks'],
            minDate: normalizedData['mindate'],
            maxDate: normalizedData['maxdate'],
            overalls_closing_balances_by_months: normalizedData['overalls_closing_balances_by_months'],
        });

        try {
            let entities = [];
            if (normalizedData['mycompanies'] && normalizedData['mycompanies'].length > 0) {
                normalizedData['mycompanies'].forEach((jsonCompany) => {
                    let entity = Entity.fromJson(jsonCompany);
                    entities.push(entity)
                })
            }
            if (entities.length > 0) {
                project.myCompanies = entities
            }
        } catch (e) {
            myDebugPrint(`error on parsing companies from CashProject list: ${e}`);
        }

        // try {
        //     if (project.overalls_by_accounts) {
        //         let newEarliestDate = new Date().getTime();
        //         let newLatestDate = new Date(1).getTime();
        //         let openingBalance = {};
        //         let closingBalance = {};
        //         let fixedOverallAccounts = {};
        //         project.overalls_by_accounts_reorg = {};
        //         Object.entries(project.overalls_by_accounts).map((account, index) => {
        //             const thisCurrency = account[1]["CurrencyCode"];
        //             const accountNumber = account[1]["MyAccountNumber"];
        //             if (!fixedOverallAccounts[accountNumber] || !fixedOverallAccounts[accountNumber]['MyAccountNumber']) {
        //                 fixedOverallAccounts[accountNumber] = {}
        //             }
        //             fixedOverallAccounts[accountNumber]['MyAccountNumber'] = accountNumber;
        //             fixedOverallAccounts[accountNumber]['CurrencyCode'] = thisCurrency;
        //             if (!fixedOverallAccounts[accountNumber]['Filename']) { fixedOverallAccounts[accountNumber]['Filename'] = [] }
        //             fixedOverallAccounts[accountNumber]['Filename'].push(account[1].Filename);
        //             fixedOverallAccounts[accountNumber]['Inflows'] =
        //                 (fixedOverallAccounts[accountNumber]['Inflows'] ?? 0) + (account[1].Inflows ?? 0) / 1;
        //             fixedOverallAccounts[accountNumber]['Outflows'] =
        //                 (fixedOverallAccounts[accountNumber]['Outflows'] ?? 0) + (account[1].Outflows ?? 0) / 1;
        //             fixedOverallAccounts[accountNumber]['Errorflows'] =
        //                 (fixedOverallAccounts[accountNumber]['Errorflows'] ?? 0) + (account[1].Errorflows ?? 0) / 1;
        //             fixedOverallAccounts[accountNumber]['Netflows'] =
        //                 fixedOverallAccounts[accountNumber]['Inflows'] - fixedOverallAccounts[accountNumber]['Outflows'];

        //             try {
        //                 const accountStatements = project.accountStatements[account[1]["MyAccountNumber"]]
        //                 if (!accountStatements || !accountStatements.data || accountStatements.data.length < 1) {
        //                 } else {
        //                     accountStatements.data.map((item, index) => {
        //                         const dateStart = parseDate({ dateString: item.DateStart, format: 'dd.MM.yyyy' }).getTime();
        //                         const dateEnd = parseDate({ dateString: item.DateEnd, format: 'dd.MM.yyyy' }).getTime();
        //                         newEarliestDate = Math.min(newEarliestDate, dateStart);
        //                         newLatestDate = Math.max(newLatestDate, dateEnd);
        //                         if (!openingBalance[thisCurrency]) {
        //                             openingBalance[thisCurrency] = []
        //                         }
        //                         openingBalance[thisCurrency].push({
        //                             'AccountNumber': accountNumber,
        //                             'DateStart': item.DateStart,
        //                             'WasOnStart': item.WasOnStart
        //                         })
        //                         if (!closingBalance[thisCurrency]) {
        //                             closingBalance[thisCurrency] = []
        //                         }
        //                         closingBalance[thisCurrency].push({
        //                             'AccountNumber': accountNumber,
        //                             'DateEnd': item.DateEnd,
        //                             'WasOnEnd': item.WasOnEnd
        //                         })
        //                         fixedOverallAccounts[accountNumber]['DateStart'] =
        //                             fixedOverallAccounts[accountNumber]['DateStart']
        //                                 ? Math.min(fixedOverallAccounts[accountNumber]['DateStart'], dateStart)
        //                                 : dateStart;
        //                         fixedOverallAccounts[accountNumber]['DateEnd'] = fixedOverallAccounts[accountNumber]['DateEnd']
        //                             ? Math.max(fixedOverallAccounts[accountNumber]['DateEnd'], dateEnd)
        //                             : dateEnd;
        //                         fixedOverallAccounts[accountNumber]['WasOnStart'] = fixedOverallAccounts[accountNumber]['DateStart'] === dateStart ? item.WasOnStart / 1 : (fixedOverallAccounts[accountNumber]['WasOnStart'] ?? 0);
        //                         fixedOverallAccounts[accountNumber]['WasOnEnd'] = fixedOverallAccounts[accountNumber]['DateEnd'] === dateEnd ? item.WasOnEnd / 1 : (fixedOverallAccounts[accountNumber]['WasOnEnd'] ?? 0);
        //                     })
        //                 }
        //             } catch (e) { }
        //             if (fixedOverallAccounts[accountNumber]['DateStart']) {
        //                 fixedOverallAccounts[accountNumber]['DateStart'] = formatDate(fixedOverallAccounts[accountNumber]['DateStart']);
        //                 fixedOverallAccounts[accountNumber]['DateEnd'] = formatDate(fixedOverallAccounts[accountNumber]['DateEnd']);
        //             }
        //         });
        //         project.overalls_by_accounts_reorg = { ...fixedOverallAccounts }
        //         myDebugPrint(`project.overalls_by_accounts_reorg is: ${JSON.stringify(project.overalls_by_accounts_reorg)}`)
        //         newEarliestDate = new Date(newEarliestDate);
        //         project.minDate = newEarliestDate;
        //         newEarliestDate = new Date(
        //             newEarliestDate.getFullYear(),
        //             newEarliestDate.getMonth(),
        //             1
        //         )
        //         newLatestDate = new Date(newLatestDate);
        //         project.maxDate = newLatestDate;
        //         newLatestDate = new Date(
        //             newLatestDate.getFullYear(),
        //             newLatestDate.getMonth() + 1,
        //             0
        //         )
        //         project.earliestDate = newEarliestDate;
        //         project.latestDate = newLatestDate;
        //         project.openingBalance = openingBalance;
        //         project.closingBalance = closingBalance;
        //     }
        // } catch (e) { }

        return project;
    }
}

export default CashProjectClass;
