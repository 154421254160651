import React from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, LineElement, PointElement, LinearScale, Title, Tooltip, Legend, CategoryScale } from 'chart.js';
import { shortenCashNumbers } from '../../utils/utils';
import { indicatorsMapCharts, indicatorsSimplifiedMapCharts } from '../../classes/FinancialIndicators';
import { myDebugPrint } from '../../utils/debug';

ChartJS.register(LineElement, PointElement, LinearScale, Title, Tooltip, Legend, CategoryScale);

export const chartFinRepBarColors = [
    '#2196F3', // blue
    '#66BB6A', // green
    '#FFEB3B', // yellow
    '#FF9800', // orange
    '#FF5252', // red
    '#9C27B0', // purple
    '#1DE9B6', // teal
];


const FinRepChart = ({ years, indicators, entity, simplified }) => {
    const getFinRepRowVals = (indicator) => {
        if (simplified && entity.finRepSimplifiedRows[indicator]) {
            return entity.finRepSimplifiedRows[indicator];
        } else if (!simplified && entity.finRepRows[indicator]) {
            return entity.finRepRows[indicator];
        }
        return null;
    };

    const dataSets = indicators.map((zeroedIndicator, index) => {
        let indicator = zeroedIndicator.replace('0_', '');
        const finRepRowVals = getFinRepRowVals(indicator);
        if (!finRepRowVals) return null;

        const data = years.map(year => finRepRowVals.mapValuesByYears[year] ?? 0);

        let label;
        if (!simplified) {
            label = indicatorsMapCharts[indicator];
        } else {
            label = indicatorsSimplifiedMapCharts[indicator];
        }

        return {
            label: label,
            data,
            borderColor: chartFinRepBarColors[index],
            fill: false,
            tension: 0.4,
        };
    }).filter(dataset => dataset !== null);

    const data = {
        labels: years,
        datasets: dataSets,
    };

    const options = {
        responsive: true,
        scales: {
            x: {
                title: {
                    display: false,
                    text: 'Годы',
                },
            },
            y: {
                title: {
                    display: false,
                    text: 'Значене',
                },
                ticks: {
                    callback: function (value) {
                        return shortenCashNumbers({ number: value });
                    }
                }
            },
        },
        plugins: {
            tooltip: {
                callbacks: {
                    label: function (context) {
                        const label = context.dataset.label || '';
                        const value = context.raw;
                        return `${label}: ${shortenCashNumbers({ number: value })}`;
                    },
                },
            },
            datalabels: { display: false },
        },
    };

    return <Line data={data} options={options} />;
};

export default FinRepChart;
