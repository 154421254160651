import { parseDateFromNullableString } from '../utils/utils';
import LicenseAndTrademarkValidity from './LicenseAndTrademarkValidity'; // Assuming this is an existing enum

class License {
    constructor({
        number,
        date,
        issueDate,
        validTill,
        issuerName,
        activities,
        validity = LicenseAndTrademarkValidity.undefined,
    }) {
        this.number = number;
        this.date = date;
        this.issueDate = issueDate;
        this.validTill = validTill;
        this.issuerName = issuerName;
        this.activities = activities;
        this.validity = validity;
    }

    static fromJson(jsonData) {
        return new License({
            number: jsonData['Номер'],
            date: parseDateFromNullableString(jsonData['Дата']),
            issueDate: parseDateFromNullableString(jsonData['ДатаНач']),
            validTill: parseDateFromNullableString(jsonData['ДатаОконч']),
            issuerName: jsonData['ЛицОрг'],
            activities: Array.isArray(jsonData['ВидДеят']) ? jsonData['ВидДеят'] : [],
        });
    }
}

export default License;
