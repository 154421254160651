import React from 'react';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Box, Typography } from '@mui/material';
import { formatDoubleToPaddedString } from '../../../utils/utils';

// Register necessary elements for the chart
ChartJS.register(ArcElement, Tooltip, Legend);

const CounterpartiesPieChart = ({ counterparties, direction, totals }) => {
    if (!counterparties || !counterparties.length) {
        return <Typography variant="body2">Нет данных для отображения</Typography>;
    }

    // Calculate the total values based on direction
    const totalValue = direction === 'Inflows'
        ? totals?.TotalInflows || 0
        : direction === 'Outflows'
            ? totals?.TotalOutflows || 0
            : totals?.TotalSumflows || 0;

    // Extract selected counterparties and their data
    const selectedDataValues = counterparties.map((counterparty) =>
        direction === 'Inflows'
            ? counterparty.Inflows
            : direction === 'Outflows'
                ? counterparty.Outflows
                : counterparty.Sumflows
    );
    const selectedLabels = counterparties.map((counterparty) =>
        (
            (counterparty.Counterparty && counterparty.Counterparty != 'ИНН не указан')
                ? counterparty.Counterparty
                : ''
        ) + (
            (counterparty.Counterparty && counterparty.Counterparty != 'ИНН не указан' &&
                counterparty.Counterparty != counterparty.CounterpartyIdentifier) ?
                ', ИНН ' : ''
        ) + (
            (counterparty.Counterparty != counterparty.CounterpartyIdentifier
                ? counterparty.CounterpartyIdentifier : '')
        ) + (
            counterparty.Counterparty && counterparty.Counterparty == 'ИНН не указан' ?
                ', ИНН не указан'
                : ''
        )
    );

    // Calculate "Прочее" as the remaining amount
    const selectedTotal = selectedDataValues.reduce((sum, value) => sum + value, 0);
    const otherValue = totalValue - selectedTotal;

    // Include "Прочее" in the dataset if there are remaining values
    const dataValues = otherValue > 0 ? [...selectedDataValues, otherValue] : selectedDataValues;
    const labels = otherValue > 0 ? [...selectedLabels, 'Другие контрагенты'] : selectedLabels;

    // Chart data configuration
    const chartData = {
        labels,
        datasets: [
            {
                data: dataValues,
                backgroundColor: [
                    '#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF',
                    '#FF9F40', '#FFCD56', '#C9CBCF', '#4D5360', '#36A2EB'
                ],
                hoverBackgroundColor: [
                    '#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF',
                    '#FF9F40', '#FFCD56', '#C9CBCF', '#4D5360', '#36A2EB'
                ],
            },
        ],
    };

    const options = {
        plugins: {
            tooltip: {
                callbacks: {
                    label: (tooltipItem) => {
                        const label = tooltipItem.label || '';
                        const value = dataValues[tooltipItem.dataIndex];
                        const formattedValue = formatDoubleToPaddedString({ number: value, roundDigits: 2 });
                        return `${label}: ${formattedValue}`;
                    }
                }
            },
            legend: {
                display: false,
                position: 'right',
                labels: {
                    boxWidth: 10,
                }
            }
        },
        responsive: true,
        maintainAspectRatio: false,
    };

    return (
        <Box sx={{ width: '100%', maxWidth: 400, mx: 'auto', textAlign: 'center', mt: 3 }}>
            <Typography variant="h6">
                {direction === 'Inflows' ? 'Распределение поступлений'
                    : direction === 'Outflows' ? 'Распределение платежей'
                        : 'Распределение оборота'}
            </Typography>
            <Box sx={{ width: '100%', height: 400 }}>
                <Pie data={chartData} options={options} />
            </Box>
        </Box>
    );
};

export default CounterpartiesPieChart;
