import { parseDateFromNullableString } from '../utils/utils';
import KadParty from './KadParty';

class Kad {
    constructor({
        caseNumber,
        link,
        dateOpened,
        court,
        kadPartyType,
        plaintiffs,
        defendants,
        sum,
    }) {
        this.caseNumber = caseNumber;
        this.link = link;
        this.dateOpened = dateOpened;
        this.court = court;
        this.kadPartyType = kadPartyType;
        this.plaintiffs = plaintiffs;
        this.defendants = defendants;
        this.sum = sum;
    }

    static fromJson(jsonData, partyType) {
        return new Kad({
            kadPartyType: partyType,
            caseNumber: jsonData['Номер'],
            link: jsonData['СтрКАД'],
            dateOpened: parseDateFromNullableString(jsonData['Дата']),
            court: jsonData['Суд'],
            plaintiffs: Kad.getKadPartiesFromJson(jsonData['Ист']),
            defendants: Kad.getKadPartiesFromJson(jsonData['Ответ']),
            sum: parseFloat(jsonData['СуммИск']) || null,
        });
    }

    static getKadPartiesFromJson(jsonData) {
        if (!Array.isArray(jsonData)) return [];

        return jsonData.map((element) => {
            return new KadParty({
                taxNumber: element['ИНН'],
                name: element['Наим'],
                address: element['Адрес'],
            });
        });
    }
}

export default Kad;
