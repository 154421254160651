import React, { useState, useEffect } from 'react';
import { Box, TextField, Button, IconButton, useMediaQuery, Tooltip, Typography, Card, CardHeader, CardContent } from '@mui/material';
import { ThemeProvider, useTheme } from '@mui/material/styles';
import ClearIcon from '@mui/icons-material/Clear';
import { formatDate, formatDateToGivenFormat } from '../../../utils/utils';
import { slicerTheme } from '../../../theme';
import ToggleIconButton from '../../common/ToggleIconButton';
import { DoneAll } from '@mui/icons-material';
import { myDebugPrint } from '../../../utils/debug';
import CircleLoader from 'react-spinners/CircleLoader';
import { useSnackbar } from '../../../context/SnackbarContext';

const DateFilter = ({ onFilter, filterTitle = '', filteredDateStart = '', filteredDateEnd = '', minDate, maxDate, filteringInProgress }) => {
    const showSnackbar = useSnackbar();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    // States for start and end dates
    const [startDate, setStartDate] = useState(filteredDateStart ? formatDateToGivenFormat({ date: filteredDateStart, format: 'yyyy-MM-dd' }) : '');
    const [endDate, setEndDate] = useState(filteredDateEnd ? formatDateToGivenFormat({ date: filteredDateEnd, format: 'yyyy-MM-dd' }) : '');

    // Handle change of date fields
    const handleStartDateChange = (event) => setStartDate(event.target.value);
    const handleEndDateChange = (event) => setEndDate(event.target.value);

    // Handle filter button click
    const handleFilter = () => {
        if (endDate < startDate) {
            showSnackbar('Проверьте диапазон: дата конца - не ранее даты начала, а введеные значения - правильные даты', 'error');
        } else if (
            startDate.length < 10 || endDate.length < 10 ||
            startDate.slice(0, 4) < '2000' || startDate.slice(0, 4) > '2100' ||
            endDate.slice(0, 4) < '2000' || endDate.slice(0, 4) > '2100'
        ) {
            showSnackbar('Исправьте указанные даты', 'error');
        } else if (onFilter) {
            onFilter(startDate, endDate);
        }
    };

    const resetFilter = () => {
        if ((minDate != filteredDateStart || maxDate != filteredDateEnd)) {
            setStartDate(minDate);
            setEndDate(maxDate);
            onFilter(minDate, maxDate);
        } else {
            showSnackbar('Фильтр по дате не применяется', 'warning');
        }
    }

    useEffect(() => {
        setStartDate(filteredDateStart);
        setEndDate(filteredDateEnd);
    }, [filteredDateStart, filteredDateEnd]);

    return (
        <ThemeProvider theme={slicerTheme}>
            <Card style={{ width: '100%' }} variant='slicer'>
                <CardHeader
                    variant='slicer'
                    title={filterTitle}
                    action={
                        <ToggleIconButton
                            icon={<DoneAll />}
                            isOn={!(minDate === filteredDateStart && maxDate === filteredDateEnd)}
                            onSelectAll={resetFilter}
                            onDeselectAll={resetFilter}
                        />
                    }
                />
                <CardContent>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: isSmallScreen ? 'column' : 'row',
                            alignItems: 'center',
                            gap: 2,
                            my: 0,
                        }}
                    >
                        {/* Start Date Input */}
                        <TextField
                            label="с"
                            type="date"
                            value={startDate}
                            onChange={handleStartDateChange}
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                style: { fontSize: 'small', },
                            }}
                            sx={{ width: isSmallScreen ? '100%' : 'auto', }} // Full width on small screens
                        />
                        {/* End Date Input */}
                        <TextField
                            label="по"
                            type="date"
                            value={endDate}
                            onChange={handleEndDateChange}
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                style: { fontSize: 'small' },
                            }}
                            sx={{
                                width: isSmallScreen ? '100%' : 'auto',
                            }} // Full width on small screens
                        />
                        {
                            filteringInProgress &&
                            <CircleLoader color="#8A4B9A" size={45} />
                        }

                        {/* Filter Button */}
                        {!filteringInProgress && ((startDate != filteredDateStart) || (endDate != filteredDateEnd)) &&
                            <Button
                                variant="slicerOn"
                                onClick={handleFilter}
                                sx={{ width: isSmallScreen ? '100%' : 'auto', fontSize: 'small' }} // Full width on small screens
                            >
                                Применить
                            </Button>
                        }
                    </Box>
                    {minDate && maxDate &&
                        <Box sx={{ mt: '10px' }}>
                            <Typography variant='body2' sx={{ fontSize: 'small' }}>
                                Максимальный период анализа - {formatDate(minDate)}-{formatDate(maxDate)}
                            </Typography>
                        </Box>

                    }
                </CardContent>
            </Card>
        </ThemeProvider>
    );
};

export default DateFilter;
