import React from 'react';
import { Typography, List, ListItem } from '@mui/material';
import '../dashboard/styles/Dashboard.css';
import { formatDoubleToPaddedString } from '../../utils/utils';
import { getEmail } from '../../utils/storage';
import { myDebugPrint } from '../../utils/debug';


const StatementsProcessingResults = ({ content }) => {
    myDebugPrint(`content of processing result is: ${content}`)

    return (
        <List>
            {Object.entries(content).map((item, index) => (
                <ListItem key={`${item[0]}-${index}`}>
                    <Typography variant='body2' sx={{ color: 'black' }}>
                        {item[0]}:
                        {item[1] === "Не удалось прочитать файл" || item[1] === "Этот файл не является .txt файлом"
                            || item[1].includes('Выписка в формате')
                            ? <span style={{ color: 'red' }}>&nbsp;{item[1].replace('{user_email}', getEmail())}</span>
                            : (
                                item[1].includes('Excel')
                                    ? <span>
                                        &nbsp;{item[1].replace('{user_email}', getEmail())}
                                    </span>
                                    : Object.entries(item[1]).map((acc, index) => (
                                        <span key={`${acc[1]['AccNmbr']}-sp-${index}`}>
                                            <br />
                                            <span >
                                                &nbsp;счет №{acc[1]['AccNmbr']}.&nbsp;
                                            </span>
                                            <span >
                                                {acc[1]['DateStart']}-{acc[1]['DateEnd']},
                                                поступления {formatDoubleToPaddedString(
                                                    {
                                                        number: acc[1]['Inflow'] * 1, roundDigits: 2
                                                    })},
                                                списания {formatDoubleToPaddedString(
                                                    {
                                                        number: acc[1]['Outflow'] * 1, roundDigits: 2
                                                    })};
                                            </span>
                                        </span>
                                    ))
                            )
                        }
                    </Typography>
                </ListItem>
            )
            )}
        </List>
    );
}

export default StatementsProcessingResults;