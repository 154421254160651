// components/AnalysisBenefits.js
import React from 'react';
import { Container, Grid, Typography, Box, Paper } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { CheckBoxOutlined, CheckCircleOutlineOutlined } from '@mui/icons-material';

const benefits = [
    {
        title: 'Арбитражному управляющему и кредиторам должника',
        description:
            <span>За пару кликов вы
                <br />• узнаете о крупнейших платежах: кому, от кого, за что, когда и в каких суммах;
                <br />• получите отчет по взаиморасчетам с аффилированными контрагентами;
                <br />• узнаете, с какими организациями может существовать экономическая взаимозависимость;
                <br />• увидите платежи на КДЛ
                <br />• и многое другое.
            </span>,
    },
    {
        title: 'Кредитным брокерам и инвестиционным платформам',
        description:
            <span>С помощью сервиса вы
                <br />• быстро разберетесь в особенностях бизнеса клиента - на чем и как зарабатывает, кто ключевые контрагенты;
                <br />• сможете оценить потенциальный кредитный лимит;
                <br />• будете готовы к вопросам о крупнейших плательщиках и зависимости от них;
                <br />• и многое другое.
            </span>,
    },
    {
        title: 'Банкам и лизинговым компаниям',
        description:
            <span>Анализ выписок поможет вам
                <br />• проводить скоринг более точно;
                <br />• верифицировать управленческую и финансовую отчетность реальным денежным оборотом;
                <br />• уменьшить риски при определении кредитного лимита;
                <br />• улучшить контроль в рамках текущего мониторинга и оперативно видеть проблемы заемщика;
                <br />• и многое другое.
            </span>,

    },
    {
        title: 'Крупному бизнесу',
        description:
            <span>Анализ оборотов и движений по счетам поможет вам
                <br />• оценивать добросовестность контрагентов, особенно МСП;
                <br />• прогнозировать способность контрагента исполнять обязательства по контрактам;
                <br />• определять надежный лимит авансирования поставщиков и лимит дебиторской задолженности покупателей;
                <br />• и многое другое.
            </span>,

    },
];

function AnalysisBenefits() {
    return (
        <Box>
            <Typography variant="h2" gutterBottom
                sx={{
                    fontSize: '2.1em',
                    color: '#5E5C5C',
                    fontWeight: 'bold',
                }}>
                Когда и кому необходим анализ выписки
            </Typography>
            <Grid container spacing={4}>
                {benefits.map((benefit, index) => (
                    <Grid item xs={12} md={6} key={index}>
                        <Box
                            component={Paper}
                            elevation={0}
                            sx={{
                                p: 2,
                                display: 'flex',
                                flexDirection: 'column',
                                height: '100%',
                            }}
                        >
                            <Box sx={{ display: 'flex', alignItems: 'flex-start', mb: 2 }}>
                                <CheckBoxOutlined fontSize="large" color="primary" />
                                <Typography variant="h6" sx={{ ml: 1 }}>
                                    {benefit.title}
                                </Typography>
                            </Box>
                            <Typography variant="body2" sx={{ flexGrow: 1 }}>{benefit.description}</Typography>
                        </Box>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
}


export default AnalysisBenefits;
