import React from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Box, Typography, ListItem, Card, CardContent, Grid, } from '@mui/material';
import { formatDate, formatDoubleToPaddedString, getCurrencyName, parseDate, shortenCashNumbers } from '../../../utils/utils';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { inflowsColor, outflowsColor } from '../../../utils/vars';
import StatementDatesChart from './StatementDatesChart';
import { myDebugPrint } from '../../../utils/debug';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);



const AccountOverallData = ({
    index, inn, currency, accountNumber, earliestDate, totals, statements }) => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const currencyName = ' ' + getCurrencyName(currency);

    const data = {
        labels: ['Поступления', 'Платежи'],
        datasets: [
            {
                label: 'Value',
                data: [
                    totals.Inflow ?? 0,
                    totals.Outflow ?? 0
                ],
                backgroundColor: [inflowsColor, outflowsColor],
            },
        ],
    };

    const options = {
        indexAxis: 'y',
        responsive: true,
        plugins: {
            legend: {
                display: false,
            },
            title: {
                display: true,
                text: `Обороты,${currencyName}`,
            },
            datalabels: { display: false },
        },
        scales: {
            x: {
                beginAtZero: true,
                ticks: {
                    callback: function (value) {
                        return shortenCashNumbers({ number: value });
                    }
                }
            },
        },
    };

    return (
        <ListItem key={'li' + accountNumber + '-' + index} style={{ padding: 0 }}>
            <Card style={{ width: '100%', backgroundColor: '#fcf9f9', marginBottom: '30px' }}>
                <CardContent>
                    <Grid container spacing={2} direction={isSmallScreen ? 'column' : 'row'}>
                        <Grid item xs={12} sm={6}>
                            <Typography>
                                <strong>
                                    ИНН {inn ?? 'нет данных'}, счет №{accountNumber}
                                </strong>
                                <br />
                                Поступления: {formatDoubleToPaddedString(
                                    { number: totals.Inflow ?? 0, roundDigits: 2 }) + currencyName}
                                <br />
                                Платежи: {formatDoubleToPaddedString(
                                    { number: totals.Outflow ?? 0, roundDigits: 2 }) + currencyName}
                                {Math.round((totals.Errorflows ?? 0) * 100) / 100 == 0 ? '' :
                                    <span>
                                        <br />
                                        Нераспознанные потоки: {formatDoubleToPaddedString(
                                            { number: totals.Errorflows, roundDigits: 2 }) + currencyName}
                                    </span>
                                }
                                <br />
                                Изменение денежных средств: {formatDoubleToPaddedString(
                                    { number: (totals.Inflow - totals.Outflow), roundDigits: 2 }) + currencyName}
                                {totals.DateStart &&
                                    <span>
                                        <br />
                                        Остаток на {formatDate(totals.DateStart)}: {formatDoubleToPaddedString(
                                            { number: totals.WasOnStart, roundDigits: 2 }) + currencyName}
                                    </span>

                                }
                                {totals.DateEnd &&
                                    <span>
                                        <br />
                                        Остаток на {formatDate(totals.DateEnd)}: {formatDoubleToPaddedString(
                                            { number: totals.WasOnEnd, roundDigits: 2 }) + currencyName}
                                    </span>

                                }
                                {(Math.round(
                                    totals.TotalsError * 100
                                ) / 100 != 0
                                ) &&
                                    <span style={{ color: '#d81462' }}>
                                        <br />
                                        Данные об оборотах не сходятся с остатками на {
                                            formatDoubleToPaddedString(
                                                {
                                                    number: totals.TotalsError, roundDigits: 2
                                                }) + currencyName}!
                                    </span>

                                }
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Box sx={{ maxHeight: '150px' }}>
                                <Bar data={data} options={options} />
                            </Box>
                        </Grid>
                    </Grid>
                    {statements && statements.length > 0 && earliestDate &&
                        <StatementDatesChart statements={statements} earliestDate={earliestDate} />
                    }
                </CardContent>

            </Card>
        </ListItem>
    );
}

export default AccountOverallData;