import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { styled } from '@mui/system';

const backColorFilterOn = 'rgba(0, 128, 0, 0.2)'; // Green with 20% opacity
const backColorFilterOff = 'rgba(128, 128, 128, 0.2)'; // Grey with 20% opacity

const pstyleTooltip = {
    fontSize: 11,
    fontFamily: 'Roboto Mono',
};

const MyFilterButton = ({ onClick, title, active, backColor }) => {
    const backgroundColor = backColor || (active ? backColorFilterOn : backColorFilterOff);

    return (
        <Box sx={{ padding: '0 0 10px 10px' }}>
            <Button
                onClick={onClick}
                sx={{
                    backgroundColor: backgroundColor,
                    borderRadius: '12px',
                    padding: '6px 12px',
                    textTransform: 'none',
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                <Typography
                    variant="body2"
                    sx={{
                        ...pstyleTooltip,
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    {active && '✔ '} {title}
                </Typography>
            </Button>
        </Box>
    );
};

export default MyFilterButton;
