import { roundTo, formatDoubleToPaddedString, maxKeyInObject } from '../utils/utils';
import RecentFinance from './RecentFinance';
import { totalsCodes, indicatorsSimplifiedMap, indicatorsMap } from './FinancialIndicators';
import { myDebugPrint } from '../utils/debug';

class FinRepRowVals {
    constructor({ indicatorCode, mapValuesByYears }) {
        this.indicatorCode = indicatorCode;
        this.mapValuesByYears = mapValuesByYears || {};
    }

    min() {
        let res;
        Object.values(this.mapValuesByYears).forEach((value) => {
            res = res === undefined ? value : Math.min(res, value);
        });
        return res;
    }

    max() {
        let res;
        Object.values(this.mapValuesByYears).forEach((value) => {
            res = res === undefined ? value : Math.max(res, value);
        });
        return res;
    }

    firstMax({ signedOne = 1 } = {}) {
        if (!this.mapValuesByYears) return null;
        let yearWithMax = null;
        let maxValue = null;

        Object.entries(this.mapValuesByYears).forEach(([key, value]) => {
            if (maxValue === null || (value !== null && maxValue * signedOne < value * signedOne)) {
                yearWithMax = key;
                maxValue = value;
            }
        });
        if (maxValue === null || yearWithMax === null) return null;
        return { [yearWithMax]: maxValue };
    }

    addYearVal(year, value) {
        this.mapValuesByYears[year] = value;
    }

    buildRowMap({ yearsListFull, division, roundDigits, simplified }) {
        const res = { [("0_" + this.indicatorCode)]: this.indicatorName({ simplified }) };
        const copiedValues = {};
        let empty = true;

        yearsListFull.forEach((year) => {
            let tmpVal = this.mapValuesByYears[year];
            if (tmpVal !== null && tmpVal !== undefined) {
                tmpVal = roundTo(tmpVal / division, roundDigits);
                if (tmpVal !== 0 || totalsCodes.includes(this.indicatorCode.toString())) empty = false;
            }
            copiedValues[('y_' + year.toString())] = tmpVal;
        });

        if (empty) return null;

        const sortedMap = Object.entries(copiedValues).sort((a, b) => a[0] - b[0]);
        sortedMap.forEach(([key, value]) => {
            res[key] = value;
        });

        return res;
    }

    buildRecent({ compare = false, negativeImpact = false, lastYearProposition, prevYearProposition }) {
        if (!this.mapValuesByYears || Object.keys(this.mapValuesByYears).length === 0) return null;
        try {
            const lastYearNumber = maxKeyInObject(this.mapValuesByYears);
            const prevYearNumber = lastYearNumber - 1;
            const prevExists = !(!this.mapValuesByYears[prevYearNumber]);

            const recent = new RecentFinance({
                indicatorName: this.indicatorName({ simplified: false }),
                measureName: this.mapValuesByYears[lastYearNumber] < 5000 * 1000 ? 'тыс.руб.' : 'млн.руб.',
                division: this.mapValuesByYears[lastYearNumber] < 5000 * 1000 ? 1000 : 1000 * 1000,
                roundDigits: 0,
                lastYearNumber: lastYearNumber,
                lastYearVal: this.mapValuesByYears[lastYearNumber],
                prevYearNumber: prevExists ? prevYearNumber : null,
                prevYearVal: prevExists ? this.mapValuesByYears[prevYearNumber] : null,
                lastYearProposition,
                prevYearProposition,
            });

            return recent.buildFullString();
        } catch (e) {
            myDebugPrint(`error on building financial reports: ${e}`);
            return '';
        }
    }

    indicatorName({ simplified }) {
        if (!simplified) return indicatorsMap.find(item => item.key === this.indicatorCode).label || `Строка #${this.indicatorCode}`;
        return indicatorsSimplifiedMap.find(item => item.key === this.indicatorCode).label || `Строка #${this.indicatorCode}`;
    }
}

export default FinRepRowVals;
