// components/NavBar.js
import React, { useState } from 'react';
import { Menu, MenuItem, IconButton, Typography, Box } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

const menuItems = [
    {
        label: 'Войти',
        url: '/signin',
    },
];

function NavBar() {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    return (
        <Box>
            <IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={handleMenuOpen}
                sx={{ display: { xs: 'none', md: 'none' } }}
            >
                <MenuIcon />
            </IconButton>
            <Box sx={{ display: { xs: 'flex', md: 'flex' } }}>
                {menuItems.map((item, index) => (
                    <Box key={index} sx={{ position: 'relative' }}>
                        <Typography component="a" href={item.url} sx={{ color: 'white', textDecoration: 'none', mx: 2 }}>
                            {item.label}
                        </Typography>
                        {item.subMenu && (
                            <Menu
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl)}
                                onClose={handleMenuClose}
                            >
                                {item.subMenu.map((subItem, subIndex) => (
                                    <MenuItem key={subIndex} component="a" href={subItem.url}>
                                        {subItem.label}
                                    </MenuItem>
                                ))}
                            </Menu>
                        )}
                    </Box>
                ))}
            </Box>
        </Box>
    );
}

export default NavBar;
