import React from 'react';
import { Container, Grid, Typography, Box, Card, CardContent, Button } from '@mui/material';
import Spacer from './Spacer';
import { Link as ScrollLink } from 'react-scroll';

function PricingCard({ title, subheading, price, period, features, buttonText, buttonHref }) {
    return (
        <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
            <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                <Box>
                    <Typography variant="h5" component="div">
                        {title}
                    </Typography>
                    <Typography variant="subtitle1" color="text.secondary" gutterBottom>
                        {subheading}
                    </Typography>
                    <Typography variant="h3" component="div">
                        {price}
                    </Typography>
                    <Typography variant="subtitle2" color="text.secondary">
                        {period}
                    </Typography>
                    <ul>
                        {features.map((feature, index) => (
                            <li key={index}>
                                <Typography variant="body2" color="text.secondary">
                                    {feature}
                                </Typography>
                            </li>
                        ))}
                    </ul>
                </Box>
                <ScrollLink to="orderForm" smooth={true} duration={500}>
                    <Button variant="contained" color="primary" sx={{ mt: 2 }}>
                        {buttonText}
                    </Button>
                </ScrollLink>
            </CardContent>
        </Card>
    );
}

function PricingSection() {
    const pricingPlans = [
        {
            title: 'Простой',
            subheading: <span>Анализ выписок: по мере надобности<br />Проверка ЮЛ и ИП</span>,
            price: '1 500 руб.',
            period: 'в месяц',
            features: [
                'Анализ выписок: 1 200 руб./10 000 транзакций',
                'Актуальные данные ЮЛ и ИП: 1000 в месяц',
                'Исторические данные ЮЛ и ИП: 100 в месяц',
            ],
            buttonText: 'Оформить >>>',
            buttonHref: '#form',
        },
        {
            title: 'Полный',
            subheading: <span>Анализ выписок: выгодный пакет<br />Проверка ЮЛ и ИП</span>,
            price: '5 000 руб.',
            period: 'в месяц',
            features: [
                'Анализ выписок: первые 100 000 транзакций в месяц - 0 рублей.',
                'Далее - 480 руб./10 000 транзакций',
                'Актуальные данные ЮЛ и ИП: 1000 в месяц',
                'Исторические данные ЮЛ и ИП: 100 в месяц',
            ],
            buttonText: 'Оформить >>>',
            buttonHref: '#form',
        },
        {
            title: 'Проф',
            subheading: <span>Индивидуальные условия<br />&nbsp;</span>,
            price: 'от 3 000 руб.',
            period: 'в месяц / пользователь',
            features: [
                'Личный менеджер',
                'Интеграция индивидуальных маркеров',
                'Настройка индивидуальных отчетов',
                'Совместный доступ к проектам анализа выписок',
                'От 10 пользователей',
            ],
            buttonText: 'Оформить >>>',
            buttonHref: '#form',
        },
    ];

    const additionalOptions = [
        'Во всех тарифах',
        'работа с машинно-сформированными файлами txt для 1C, Excel – не тарифицируется',
        'работа с машинно-сформированными файлами Word и PDF – зависит от способа формирования файла: бесплатно или 3 рубля / страница. Оценивается при загрузке файла в сервис',
        'работа с отсканированными документами. Зависит от качества файла. От 10 рублей / страница. Оценивается при загрузке файла в сервис',
        'Скидка 5% при оплате за квартал. 20% при оплате за год',
    ];

    return (
        <Box sx={{ backgroundColor: 'white', py: 4 }}>
            <Container maxWidth="lg">
                <Spacer height="20px" />
                <Typography variant="h2" gutterBottom
                    sx={{
                        fontSize: '2.1em',
                        color: '#5E5C5C',
                        fontWeight: 'bold',
                    }}>
                    Тарифы на ФинРадар
                </Typography>
                <Spacer height="20px" />
                <Grid container spacing={4}>
                    {pricingPlans.map((plan, index) => (
                        <Grid item xs={12} sm={6} md={4} key={index}>
                            <PricingCard {...plan} />
                        </Grid>
                    ))}
                </Grid>
                <Spacer height="40px" />
                <Typography variant="h3" gutterBottom>
                    Дополнительные опции к любому тарифу анализа выписок
                </Typography>
                <ul>
                    {additionalOptions.map((option, index) => (
                        <li key={index}>
                            <Typography variant="body2" color="text.secondary">
                                {option}
                            </Typography>
                        </li>
                    ))}
                </ul>
                <Spacer height="40px" />
            </Container>
        </Box>
    );
}

export default PricingSection;
