// components/AnalysisDetailsItem.js
import React from 'react';
import { Paper, Typography, Box } from '@mui/material';
import { faUserFriends, faBezierCurve, faSpider, faListAlt, faRetweet, faBiohazard, faShower, faBoxesPacking } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const icons = {
    'fas fa-shower': faShower,
    'fas fa-boxes-packing': faBoxesPacking,
    'fas fa-user-friends': faUserFriends,
    'fas fa-bezier-curve': faBezierCurve,
    'fas fa-spider': faSpider,
    'far fa-list-alt': faListAlt,
    'fas fa-retweet': faRetweet,
    'fas fa-biohazard': faBiohazard,
};

function AnalysisDetailsItem({ icon, title, description }) {
    return (
        <Paper elevation={0} sx={{ p: 2 }}>
            <Box sx={{ display: 'flex', alignItems: 'flex-start', mb: 2 }}>
                <FontAwesomeIcon icon={icons[icon]} size="2x" color="primary" />
                <Typography variant="h6" sx={{ ml: 2 }}>
                    {title}
                </Typography>
            </Box>
            <Typography variant="body2">{description}</Typography>
        </Paper>
    );
}

export default AnalysisDetailsItem;
