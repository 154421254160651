import React from 'react';
import { CardContent, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Box, Tooltip } from '@mui/material';
import CashCardTemplate from './CashCardTemplate';
import { formatDoubleToPaddedString, getCurrencyName, parseAndFormatDate, parseDateFromNullableString } from '../../../utils/utils';
import { myDebugPrint } from '../../../utils/debug';
import { Check, HelpOutline, Warning } from '@mui/icons-material';

const validateINN = (inn) => {
    const cleanedINN = inn ? inn.replace(/\D/g, '') : '';
    return cleanedINN.length === 10 || cleanedINN.length === 12 ? cleanedINN : 'нет данных';
};

const validateAccNmbr = (accNmbr) => {
    const cleanedAccNmbr = accNmbr ? accNmbr.replace(/\D/g, '') : '';
    return cleanedAccNmbr.length === 20 ? cleanedAccNmbr : 'нет данных';
};

const validateDate = (date) => {
    let res = 'нет данных'
    try {
        res = parseAndFormatDate({ dateString: date, sourceFormat: 'yyyy-MM-dd' })
    } catch { }
    return res;
};

const validateNumber = (value, roundDigits = 2) => {
    return formatDoubleToPaddedString({ number: value, roundDigits: roundDigits, onErrorReturn: 'нет данных' });
};

const formatErrorMessage = (value) => {
    if (value === null || value === undefined) {
        return 'нет данных'; // Handle null or undefined cases
    }
    if (String(value).toLowerCase() === 'данные сошлись') {
        return <span style={{ color: 'green', fontSize: 'x-large' }}>✓</span>
    }
    return <span style={{ color: 'red' }}>{value}</span>
}

const formatErrorStatement = (eIn, eOut) => {
    if ((eIn === null || eIn === undefined) || (eOut === null || eOut === undefined)) {
        return <span style={{ color: 'orange', fontSize: 'x-large' }}>⁉</span>
    }
    if (String(eIn).toLowerCase() === 'данные сошлись' && String(eOut).toLowerCase() === 'данные сошлись') {
        return <span style={{ color: 'green', fontSize: 'x-large' }}>✓</span>
    }
    return <span style={{ color: 'red', fontSize: 'x-large' }}>⚠</span>

}

const FileProcessingResults = ({ accountStatements }) => {
    const allDataCorrect = () => {
        let error = false
        accountStatements.forEach(account => {
            account.Statements.forEach(Statement => {
                if (Statement.Unprocessed != 0) {
                    myDebugPrint(`Statement.Unprocessed: ${Statement.Unprocessed}`)
                    error = true
                }
            })

            if (Math.round(account.Totals.Errorflows * 100) / 100 != 0 || Math.round(account.Totals.TotalsError * 100) / 100 != 0) {
                myDebugPrint(`Math.round(account.Totals.Errorflows * 100) / 100 != 0: ${Math.round(account.Totals.Errorflows * 100) / 100 != 0}`)
                myDebugPrint(`Math.round(account.Totals.TotalsError * 100) / 100 != 0: ${Math.round(account.Totals.TotalsError * 100) / 100 != 0}`)
                error = true
            }

            account.Statements.forEach((statement, index) => {
                if (Math.round(statement.Errorflows * 100) / 100 != 0 || Math.round(statement.TotalsError * 100) / 100 != 0) {
                    myDebugPrint(`Math.round(statement.Errorflows * 100) / 100 != 0: ${Math.round(statement.Errorflows * 100) / 100 != 0}`)
                    myDebugPrint(`Math.round(statement.TotalsError * 100) / 100 != 0: ${Math.round(statement.TotalsError * 100) / 100 != 0}`)
                    error = true
                }
            }
            )
        })
        return !error;
    };
    const checkFinradarFeedback = () => {
        let isFeedback = false
        accountStatements.forEach(account => {
            account.Statements.forEach(Statement => {
                if (Statement.FinradarFeedback) {
                    isFeedback = true
                    return
                }
            })
        })


        return isFeedback;
    };

    const isFinradarFeedback = checkFinradarFeedback();

    const renderTableRows = () => {
        const rows = [];
        let index = -1;

        myDebugPrint(`accountStatements in FileProcessingResults: ${JSON.stringify(accountStatements)}`)
        accountStatements.forEach(account => {
            myDebugPrint(`account in FileProcessingResults: ${JSON.stringify(account)}`)

            const accNumber = account['AccNmbr']
            const INN = account['INN']
            const CurrencyCode = account['CurrencyCode']

            const Statements = account['Statements']


            Statements.forEach(statement => {
                index = index + 1

                const Unprocessed = statement['Unprocessed'] != 0
                const isError = (Math.round(statement['Errorflows'] * 100) / 100 != 0 || Math.round(statement['TotalsError'] * 100) / 100 != 0)

                rows.push(
                    <TableRow variant={(index % 2 !== 0) ? "oddCashAnalysis" : "evenCashAnalysis"} key={`${statement['UniqueFileId']}-${index}`}>
                        <TableCell variant="cashAnalysis">
                            {
                                isError &&
                                <span style={{ color: 'red', fontSize: 'x-large' }}>⚠</span>
                            }
                            {
                                !isError && Unprocessed &&
                                <span style={{ color: 'orange', fontSize: 'x-large' }}>⁉</span>
                            }
                            {
                                !isError && !Unprocessed &&
                                <span style={{ color: 'green', fontSize: 'x-large' }}>✓</span>
                            }
                        </TableCell>
                        <TableCell variant="cashAnalysis">
                            {statement.Filename}
                        </TableCell>
                        {isFinradarFeedback &&
                            <TableCell variant="cashAnalysis">
                                {statement.FinradarFeedback ?? ''}
                            </TableCell>
                        }
                        <TableCell variant="cashAnalysis">{validateINN(INN)}</TableCell>
                        <TableCell variant="cashAnalysis">{validateAccNmbr(accNumber)}</TableCell>
                        <TableCell variant="cashAnalysis">
                            {getCurrencyName(account.CurrencyCode)}
                        </TableCell>
                        <TableCell variant="cashAnalysis" sx={{ textAlign: 'center' }}>
                            {`${validateDate(statement.DateStart)} - ${validateDate(statement.DateEnd)}`}
                        </TableCell>
                        <TableCell variant="cashAnalysis" sx={{ textAlign: 'center' }}>{validateNumber(statement.WasOnStart)}</TableCell>
                        <TableCell variant="cashAnalysis" sx={{ textAlign: 'center' }}>{validateNumber(statement.Outflow)}</TableCell>
                        <TableCell variant="cashAnalysis" sx={{ textAlign: 'center' }}>{validateNumber(statement.Inflow)}</TableCell>
                        <TableCell variant="cashAnalysis" sx={{ textAlign: 'center' }}>{validateNumber(statement.WasOnEnd)}</TableCell>
                        <TableCell variant="cashAnalysis" sx={{ textAlign: 'center' }}>
                            {Math.round((statement.TotalsError) * 100) / 100 != 0
                                ? <span style={{ color: 'red' }}>
                                    Расхождение {formatDoubleToPaddedString({ number: statement.TotalsError, roundDigits: 2 })}
                                </span>
                                :
                                <span style={{ color: 'green', fontSize: 'x-large' }}>✓</span>
                            }
                        </TableCell>
                        <TableCell variant="cashAnalysis" sx={{ textAlign: 'center' }}>{validateNumber(statement.transactionsNumberOutflows, 0)} шт.</TableCell>
                        <TableCell variant="cashAnalysis" sx={{ textAlign: 'center' }}>{validateNumber(statement.transactionsNumberInflows, 0)} шт.</TableCell>
                        <TableCell variant="cashAnalysis" sx={{ textAlign: 'center' }}>
                            {Math.round((statement.Outflow - statement.Outflow_in_transactions) * 100) / 100 != 0
                                ? <span style={{ color: 'red' }}>
                                    Расхождение {formatDoubleToPaddedString({ number: statement.Outflow - statement.Outflow_in_transactions, roundDigits: 2 })}
                                </span>
                                :
                                <span style={{ color: 'green', fontSize: 'x-large' }}>✓</span>
                            }
                        </TableCell>
                        <TableCell variant="cashAnalysis" sx={{ textAlign: 'center' }}>
                            {Math.round((statement.Inflow - statement.Inflow_in_transactions) * 100) / 100 != 0
                                ? <span style={{ color: 'red' }}>
                                    Расхождение {formatDoubleToPaddedString({ number: statement.Inflow - statement.Inflow_in_transactions, roundDigits: 2 })}
                                </span>
                                :
                                <span style={{ color: 'green', fontSize: 'x-large' }}>✓</span>
                            }
                        </TableCell>
                    </TableRow>
                );
            });
        });

        return rows;
    };

    const tableContent = (
        <CardContent>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell variant="cashAnalysisHead" rowSpan={2}></TableCell>
                            <TableCell variant="cashAnalysisHead" rowSpan={2}>Файл</TableCell>
                            {isFinradarFeedback &&
                                <TableCell variant="cashAnalysisHead" rowSpan={2}>Сообщение&nbsp;от&nbsp;Финрадара</TableCell>
                            }
                            <TableCell variant="cashAnalysisHead" rowSpan={2}>
                                <Tooltip title='В выписках может быть не указан ИНН или указан в машинно-нечитаемом виде. Даже в таких случаях мы определяем ИНН с точностью 98,7%. Если что-то не так, напишите на info@brpadvice.ru'>
                                    <span>ИНН <HelpOutline sx={{ color: 'blue', fontSize: 'medium' }} /></span>
                                </Tooltip>
                            </TableCell>
                            <TableCell variant="cashAnalysisHead" rowSpan={2}>Счет</TableCell>
                            <TableCell variant="cashAnalysisHead" rowSpan={2}>Валюта</TableCell>
                            <TableCell variant="cashAnalysisHead" rowSpan={2} sx={{ textAlign: 'center' }}>Период</TableCell>
                            <TableCell variant="cashAnalysisHead" colSpan={5} sx={{ textAlign: 'center' }}>Данные по итогам</TableCell>
                            <TableCell variant="cashAnalysisHead" colSpan={2} sx={{ textAlign: 'center' }}>Операций в выписке</TableCell>
                            <TableCell variant="cashAnalysisHead" colSpan={2} sx={{ textAlign: 'center' }}>Сверка по операциям</TableCell>
                        </TableRow>
                        <TableRow>
                            {/* These cells correspond to the sub-headers under "Данные по итогам" */}
                            <TableCell variant="cashAnalysisHead" sx={{ textAlign: 'center' }}>Остаток на начало (по итогам)</TableCell>
                            <TableCell variant="cashAnalysisHead" sx={{ textAlign: 'center' }}>Расходы (по итогам)</TableCell>
                            <TableCell variant="cashAnalysisHead" sx={{ textAlign: 'center' }}>Приходы (по итогам)</TableCell>
                            <TableCell variant="cashAnalysisHead" sx={{ textAlign: 'center' }}>Остаток на конец (по итогам)</TableCell>
                            <TableCell variant="cashAnalysisHead" sx={{ textAlign: 'center' }}>Сверка остатка на конец</TableCell>
                            <TableCell variant="cashAnalysisHead" sx={{ textAlign: 'center' }}>Платежи</TableCell>
                            <TableCell variant="cashAnalysisHead" sx={{ textAlign: 'center' }}>Поступления</TableCell>
                            <TableCell variant="cashAnalysisHead" sx={{ textAlign: 'center' }}>Платежи</TableCell>
                            <TableCell variant="cashAnalysisHead" sx={{ textAlign: 'center' }}>Поступления</TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {renderTableRows()}
                    </TableBody>
                </Table>
            </TableContainer>
        </CardContent>
    );

    const correct = allDataCorrect()
    return (
        <CashCardTemplate
            title={<Box>
                <span>{(
                    correct
                        ? <span style={{ color: 'green', fontSize: 'larger' }}>✓</span>
                        : <span style={{ color: 'red', fontSize: 'larger' }}>⚠</span>
                )}
                    &nbsp;Результаты обработки файлов
                </span>
            </Box>
            }
            subheader="Сверка оборотов и операций по каждой выписке"
            cardcontent={tableContent}
            initalFolded={correct}
        />
    );
};

export default FileProcessingResults;
