import React, { useState, useRef } from 'react';
import { Typography, Box, Icon, Tooltip } from '@mui/material';
import { formatDoubleToPaddedString, buildTextLine } from '../../utils/utils';
import KadPartyType from '../../classes/KadPartyType';
import BusinessDetailsSkeleton from './BusinessDetailsSkeleton';
import MyFilterButton from '../common/MyFilterButton';
import Entity from '../../classes/Entity';
import './styles/Dashboard.css';

const KadSection = ({ entity, analytics }) => {
    const [kadFilter, setKadFilter] = useState(KadPartyType.UNDEFINED);
    const scrollController = useRef(null);

    const scrollToStart = () => {
        scrollController.current?.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const toggleFilter = (newFilter) => {
        setKadFilter((prev) => (newFilter === prev || newFilter == null ? KadPartyType.UNDEFINED : newFilter));
        scrollToStart();
    };

    const buildKadWidgets = () => {
        const widgets = [];
        let noData;
        const { kad_update_date, kad_records_num, kad_defendant, kad_plaintiff, kad_allRecords } = entity;
        let nextKadUpdateDate = kad_update_date ? new Date(kad_update_date) : new Date();
        nextKadUpdateDate = new Date(nextKadUpdateDate.setDate(nextKadUpdateDate.getDate() + (kad_records_num == null ? 1 : 7)));

        const records = kadFilter === KadPartyType.UNDEFINED ? kad_allRecords : kadFilter === KadPartyType.DEFENDANT ? kad_defendant : kad_plaintiff;

        if (kad_records_num == null) {
            noData = `Нет данных о делах в картотеке арбитражных дел. \n\nВ следующий раз проверим ${nextKadUpdateDate.toLocaleDateString()}`;
        } else if (kad_records_num === 0) {
            noData = `В картотеке арбитражных дел записи не найдены${kad_update_date ? ` по состоянию на ${new Date(kad_update_date).toLocaleDateString()}` : ''}\n\nВ следующий раз проверим ${nextKadUpdateDate.toLocaleDateString()}`;
        } else if ((!records || records.length === 0) && kadFilter === KadPartyType.UNDEFINED) {
            noData = 'При обработке данных КАД возникла ошибка. Мы уже исправляем ее.\n\nВы можете запросить актуальную информацию по email info@brpadvice.ru';
        } else if ((!records || records.length === 0) && kad_records_num > 100) {
            noData = `В 100 последних записях не нашлись дела, в которых искомое лицо является ${kadFilter === KadPartyType.DEFENDANT ? 'ответчиком' : 'истцом'}.`;
        } else if (!records || records.length === 0) {
            noData = `Дела, в которых искомое лицо является ${kadFilter === KadPartyType.DEFENDANT ? 'ответчиком' : 'истцом'}, не найдены в КАД.`;
        }

        if (noData) {
            widgets.push(buildTextLine({ bulletShow: false, content: noData }));
        } else if (records) {
            records.forEach((record) => {
                widgets.push(
                    buildTextLine({
                        bulletShow: true,
                        title: `${record.kadPartyType === KadPartyType.PLAINTIFF ? 'Истец' : record.kadPartyType === KadPartyType.DEFENDANT ? 'Ответчик' : 'Роль не определена'} по делу №${record.caseNumber} `,
                        content: `${record.dateOpened ? `от ${new Date(record.dateOpened).toLocaleDateString()}` : ''}`,
                    })
                );
                if (record.sum && record.sum > 0) {
                    widgets.push(
                        buildTextLine({
                            bulletShow: false,
                            content: `начальная сумма иска ${formatDoubleToPaddedString({ number: record.sum, roundDigits: 0 })} руб.`,
                        })
                    );
                }
                widgets.push(
                    buildTextLine({
                        bulletShow: false,
                        url: record.link,
                        content: 'Открыть на kad.arbitr.ru',
                    })
                );

                const plaintiffs = (record.plaintiffs || []).map((plaintiff) => plaintiff.getPartyNameInn());
                const defendants = (record.defendants || []).map((defendant) => defendant.getPartyNameInn());

                widgets.push(buildTextLine({
                    bulletShow: false,
                    title: plaintiffs.length > 1 ? 'Истцы' : 'Истец',
                    content: plaintiffs.length === 0 ? ' не указан' : `: ${plaintiffs.join(', ')}`,
                }));
                widgets.push(buildTextLine({
                    bulletShow: false,
                    title: defendants.length > 1 ? 'Ответчики' : 'Ответчик',
                    content: defendants.length === 0 ? ' не указан' : `: ${defendants.join(', ')}`,
                }));
                widgets.push(<Box height={20} />);
            });
        }

        return widgets;
    };

    const buildWidgetsAfterTitleBeforeFilters = () => {
        const widgets = [];
        const { kad_records_num } = entity;

        if (kad_records_num > 0) {
            widgets.push(buildTextLine({
                bulletShow: false,
                title: `Записей в картотеке арбитражных дел: ${kad_records_num}`,
                content: '',
            }));
        }
        if (kad_records_num > 100) {
            widgets.push(
                <Box display="flex">
                    <Typography variant="body2" color="orange">
                        Мы показываем 100 последних дел
                    </Typography>
                </Box>
            );
        }
        return widgets;
    };

    const buildFilteringWidgets = () => {
        const widgets = [];
        const { kad_records_num } = entity;

        if (kad_records_num > 1) {
            widgets.push(
                <Box display="flex">
                    <MyFilterButton
                        onClick={() => toggleFilter(KadPartyType.UNDEFINED)}
                        title="Все"
                        active={kadFilter === KadPartyType.UNDEFINED}
                    />
                    <MyFilterButton
                        onClick={() => toggleFilter(KadPartyType.DEFENDANT)}
                        title="Ответчик"
                        active={kadFilter === KadPartyType.DEFENDANT}
                    />
                    <MyFilterButton
                        onClick={() => toggleFilter(KadPartyType.PLAINTIFF)}
                        title="Истец"
                        active={kadFilter === KadPartyType.PLAINTIFF}
                    />
                </Box>
            );
        }

        return widgets;
    };

    return (
        <BusinessDetailsSkeleton
            entityName={entity.name}
            h1="Арбитражные дела"
            h1tooltip="В связи с особенностями 
                раскрытия Арбитражными судами информации о делах 
                (например, есть ИНН, нет ОГРН, или наоборот, опечатки 
                    в материалах дела) информация может быть неполной. 
                    Мы активно работаем, чтобы улучшить качество этого модуля."
            widgetsAfterTitleBeforeFilters={buildWidgetsAfterTitleBeforeFilters()}
            filteringWidgets={buildFilteringWidgets()}
            scrollController={scrollController}
            mainContent={buildKadWidgets()}
        />
    );
};

export default KadSection;
