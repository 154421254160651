// components/Header.js
import React from 'react';
import { AppBar, Toolbar, Container, Box } from '@mui/material';
import NavBar from './NavBar';

function Header() {
    return (
        <AppBar position="static" sx={{ backgroundColor: '#132036' }}>
            <Container maxWidth="lg">
                <Toolbar disableGutters>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            textDecoration: 'none',
                            color: 'inherit',
                            flexGrow: 1,
                            paddingTop: '10px',
                            paddingBottom: '10px',
                        }}
                    >
                        <Box
                            component="a"
                            href="https://finradar.brpadvice.ru/"
                        >
                            <img
                                src="/assets/images/finradar - logo  white.png"
                                alt="ФИНРАДАР"
                                width="200"
                            />
                        </Box>
                    </Box>
                    <NavBar />
                </Toolbar>
            </Container>
        </AppBar>
    );
}

export default Header;
