import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

const PrivateRoute = ({ element: Component, ...rest }) => {
    const { isAuthenticated, setIntended } = useAuth();

    // useEffect(() => {
    //     if (!isAuthenticated) {
    //     }
    // }, [isAuthenticated, setIntended]);

    if (!isAuthenticated) {
        setIntended(window.location.pathname + window.location.search);
        return <Navigate to="/signin" replace />;
    }

    return <Component {...rest} />;
};

export default PrivateRoute;


// import React from 'react';
// import { Navigate } from 'react-router-dom';
// import { useAuth } from '../context/AuthContext';

// // const PrivateRoute = ({ element: Component, ...rest }) => {
// //     const { isAuthenticated } = useAuth();

// //     return isAuthenticated ? <Component {...rest} /> : <Navigate to="/" />;
// // };

// // export default PrivateRoute;


// const PrivateRoute = ({ element: Component, ...rest }) => {
//     const { isAuthenticated, setIntended } = useAuth();

//     if (!isAuthenticated) {
//         setIntended(window.location.pathname + window.location.search);
//         return <Navigate to="/signin" replace />;
//     }

//     return <Component {...rest} />;
// };

// export default PrivateRoute;

