import BadSupply from './BadSupply';
import Trademark from './Trademark';
// import { parseDateFromNullableString } from '../utils/utils';

class PersonData {
    constructor({
        taxNumber,
        fullName,
        trademarks,
        badSupplier,
        badSupplierList,
        massCEO,
        massShares,
        sanctions,
        sanctionsList,
    }) {
        this.taxNumber = taxNumber;
        this.fullName = fullName;
        this.trademarks = trademarks;
        this.badSupplier = badSupplier;
        this.badSupplierList = badSupplierList;
        this.massCEO = massCEO;
        this.massShares = massShares;
        this.sanctions = sanctions;
        this.sanctionsList = sanctionsList;
    }

    static fromJson(jsonData) {
        const personData = new PersonData({
            taxNumber: jsonData['TaxNumber'] || '',
            fullName: jsonData['FullName'] || '',
            trademarks: [],
            badSupplier: jsonData['BadSupplier']?.toString() === '1',
            massCEO: jsonData['MassCEO']?.toString() === '1',
            massShares: jsonData['MassShares']?.toString() === '1',
            sanctions: jsonData['Sanctions']?.toString() === '1',
            badSupplierList: [],
            sanctionsList: [],
        });

        if (Array.isArray(jsonData['trademarks'])) {
            personData.trademarks = jsonData['trademarks'].map((item) => {
                try {
                    return Trademark.fromJson(item);
                } catch (e) {
                    return null;
                }
            }).filter(Boolean);
        }

        if (Array.isArray(jsonData['BadSupplierList'])) {
            personData.badSupplierList = jsonData['BadSupplierList'].map((item) => {
                try {
                    return BadSupply.fromJson(item);
                } catch (e) {
                    return null;
                }
            }).filter(Boolean);
        }

        if (Array.isArray(jsonData['SanctionsList'])) {
            personData.sanctionsList = jsonData['SanctionsList'].map((item) => {
                try {
                    return item.toString() || '';
                } catch (e) {
                    return '';
                }
            }).filter(Boolean);
        }

        return personData;
    }
}

export default PersonData;
