import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Header from '../components/index/Header';
import Intro from '../components/index/Intro';
import AnalysisSection from '../components/index/AnalysisSection';
import AnalysisUsageSection from '../components/index/AnalysisUsageSection';
import AnalysisDetailsSection from '../components/index/AnalysisDetailsSection';
import ReportStepsSection from '../components/index/ReportStepsSection';
import ExperienceSection from '../components/index/ExperienceSection';
import ReportExampleSection from '../components/index/ReportExampleSection';
import PricingSection from '../components/index/PricingSection';
import FAQSection from '../components/index/FAQSection';
import OrderFormSection from '../components/index/OrderFormSection';
import ContactSection from '../components/index/ContactSection';
import { Box } from '@mui/material';
import { Element } from 'react-scroll';

function IndexPage() {
    return (
        <Box>
            <CssBaseline />
            <div className="App">
                <Header />
                <Intro />
                <AnalysisSection />
                <AnalysisUsageSection />
                <AnalysisDetailsSection />
                <ReportStepsSection />
                {/* <ExperienceSection />
                <ReportExampleSection /> */}
                <PricingSection />
                {/* <FAQSection /> */}
                <Element name="orderForm">
                    <OrderFormSection />
                </Element>
                <ContactSection />
            </div>
        </Box>
    );
}

export default IndexPage;
