// components/ReportStepsSection.js
import React from 'react';
import { Container, Grid, Typography, Box, Paper } from '@mui/material';
import ReportStep from './ReportStep';
import Spacer from './Spacer';

const steps = [
    {
        stepNumber: '1',
        title: 'Загрузите выписки в новый проект',
        description:
            <span>
                Подойдут форматы 1С или Excel,
                которые есть в любом банке.
                <br />
                <br />
                Можно загрузить один счет одной организации,
                много счетов одной организации и даже много
                счетов многих организаций по одной группе компаний.
                <br />
                <br />
                Если есть только pdf или другие форматы,
                процессинг займет больше времени,
                и может потребоваться доплата.
            </span>,
    },
    {
        stepNumber: '2',
        title: 'Удобный дашборд готов',
        description:
            <span>
                В дашборде уже собраны нужные отчеты:
                < br />• по счетам
                < br />• по чистым оборотам
                < br />• по типам транзакций
                < br />• по крупнейшим плательщикам
                < br />• по крупнейшим получателям средств
                < br />• можно быстро посмотреть подробности вплоть до любой платежки и конкретного назначения платежей
            </span>,
    },
];

function ReportStepsSection() {
    return (
        <Box sx={{ backgroundColor: 'white', py: 4 }}>
            <Container maxWidth="lg">
                <Box>
                    <Spacer height="20px" />
                    <Typography variant="h2" gutterBottom
                        sx={{
                            fontSize: '2.1em',
                            color: '#5E5C5C',
                            fontWeight: 'bold',
                        }}>
                        Анализ выписки в ФинРадаре - это всего два шага
                    </Typography>
                    <Spacer height="20px" />
                    <Grid container spacing={2}>
                        {steps.map((step, index) => (
                            <Grid item xs={12} md={6} key={index}>
                                <Box
                                    component={Paper}
                                    elevation={0}
                                    sx={{
                                        p: 2,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        height: '100%',
                                    }}
                                >
                                    <Box sx={{ display: 'flex', alignItems: 'flex-start', mb: 2 }}>
                                        <ReportStep key={index} {...step} />
                                    </Box>
                                </Box>
                            </Grid>
                        ))}
                    </Grid>
                    <Spacer height="40px" />
                </Box>
            </Container>
        </Box>
    );
}

export default ReportStepsSection;
