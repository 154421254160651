import React from 'react';
import { Tooltip } from '@mui/material';
import { CheckCircleOutline, ErrorOutline, HourglassEmpty, WarningAmber } from '@mui/icons-material';
import { shareOfTransactions } from '../../utils/utils';

export const statusIcons = {
    ready: <CheckCircleOutline style={{ color: 'green' }} />,
    inProgress: <HourglassEmpty style={{ color: 'grey' }} />,
    errors: <ErrorOutline style={{ color: 'red' }} />,
    attention: <WarningAmber style={{ color: 'orange' }} />,
};

const MyProcessingStatusIcon = ({ processingStatus }) => {

    let proccessingIcon = statusIcons.attention;
    if (!processingStatus) {
        processingStatus = 'Обработка проекта остановлена. Свяжитесь с поддержкой info@brpadvice.ru'
        proccessingIcon = statusIcons.attention;
    } else if (processingStatus === 'done') {
        processingStatus = 'Готово'
        proccessingIcon = statusIcons.ready
    } else if (processingStatus.includes('/')) {
        processingStatus = 'Обработано ' + shareOfTransactions(processingStatus);
        proccessingIcon = statusIcons.inProgress
    } else if (processingStatus.includes('error')) {
        processingStatus = 'В процессе обработки произошла ошибка. Свяжитесь с info@brpadvice.ru';
        proccessingIcon = statusIcons.errors
    }

    return (
        <Tooltip title={processingStatus}>
            {proccessingIcon}
        </Tooltip>
    );

}

export default MyProcessingStatusIcon;