
import React from 'react';
import { Box, Divider } from '@mui/material';

const CounterpartyLinks = ({ filterCompany, entity, counterparty }) => {
    let ogrn;
    let counterpartyINN;
    let shortName;
    if (entity) {
        ogrn = entity.OGRN ? entity.OGRN : entity.stateNumber;
        counterpartyINN = entity.CounterpartyINN ? entity.CounterpartyINN : entity.taxNumber;
        shortName = entity.ShortName ? entity.ShortName : (entity.name ? entity.name : (entity.FullName ? entity.FullName : entity.fullName))
    } else {
        ogrn = counterparty.OGRN ? counterparty.OGRN : null;
        shortName = counterparty.Counterparty;
        counterpartyINN = counterparty.CounterpartyIdentifier;
    }

    return (
        <Box>
            <Divider />
            <span style={{ color: '#1976d2', cursor: 'pointer' }} onClick={() => filterCompany({ TaxNumber: counterpartyINN, ShortName: shortName })}>
                Платежи
            </span>
            &nbsp;|&nbsp;
            <span>
                <a
                    href={ogrn ? `/${ogrn}` : `/search?q=${counterpartyINN}`}
                    target='_blank'
                    style={{ textDecoration: 'none', color: '#1976d2' }}
                >
                    {ogrn ? 'Карточка' : 'Найти в ЕГРЮЛ'}
                </a>
            </span>
        </Box>
    );
}

export default CounterpartyLinks;