import React, { useState, useRef } from 'react';
import { Typography, Box, Tooltip } from '@mui/material';
import { formatDoubleToPaddedString, buildTextLine, formatDate } from '../../utils/utils';
import LicenseAndTrademarkValidity from '../../classes/LicenseAndTrademarkValidity';
import BusinessDetailsSkeleton from './BusinessDetailsSkeleton';
import MyFilterButton from '../common/MyFilterButton';
import Entity from '../../classes/Entity';
import './styles/Dashboard.css';

const LicenseSection = ({ entity, analytics }) => {
    const [licenseFilter, setLicenseFilter] = useState(LicenseAndTrademarkValidity.UNDEFINED);
    const scrollController = useRef(null);

    const scrollToStart = () => {
        scrollController.current?.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const toggleFilter = (newFilter) => {
        setLicenseFilter((prev) => (newFilter === prev || newFilter == null ? LicenseAndTrademarkValidity.UNDEFINED : newFilter));
        scrollToStart();
    };

    const buildLicensesWidgets = () => {
        const widgets = [];
        const { licenses_all, licenses_valid, licenses_passed, licenses_unknown } = entity;

        const licenses_filtered = licenseFilter === LicenseAndTrademarkValidity.UNDEFINED
            ? [...(licenses_valid ?? []), ...(licenses_passed ?? []), ...(licenses_unknown ?? [])]
            : licenseFilter === LicenseAndTrademarkValidity.VALID
                ? licenses_valid
                : licenseFilter === LicenseAndTrademarkValidity.PASSED
                    ? licenses_passed
                    : licenses_unknown;

        let noData;
        if (!licenses_all || licenses_all.length === 0) {
            noData = 'Записи о выдаче лицензий не найдены.\nЭто значит, что, скорее всего, действующих лицензий нет.';
        }

        if (noData) {
            widgets.push(buildTextLine({ bulletShow: false, content: noData }));
        } else if (licenses_filtered) {
            licenses_filtered.forEach((license) => {
                const isValid = license.validity === LicenseAndTrademarkValidity.VALID;
                const isPassed = license.validity === LicenseAndTrademarkValidity.PASSED;
                const title = isValid
                    ? `Действует до ${formatDate(license.validTill)}`
                    : isPassed
                        ? `Истекла ${formatDate(license.validTill)}`
                        : 'Срок не указан';

                widgets.push(buildTextLine({
                    bulletShow: true,
                    bulletColor: isValid ? 'green' : isPassed ? 'red' : null,
                    title: title,
                    content: '',
                }));

                if (license.activities && license.activities.length > 0) {
                    license.activities.forEach((activity, index) => {
                        widgets.push(buildTextLine({
                            bulletShow: false,
                            title: null,
                            content: `${index === 0 ? 'Деятельность: ' : ''}${activity}`,
                        }));
                    });
                }

                widgets.push(buildTextLine({
                    bulletShow: false,
                    title: null,
                    content: `Выдана: ${license.issuerName || 'лицензирующий орган не указан'}`,
                }));

                widgets.push(buildTextLine({
                    bulletShow: false,
                    title: null,
                    content: `№${license.number}${license.issueDate ? `. Действует с ${formatDate(license.issueDate)}` : ''}`,
                }));

                widgets.push(<Box height={20} />);
            });
        }

        return widgets;
    };

    const buildWidgetsAfterTitleBeforeFilters = () => {
        const widgets = [];
        const { licenses_all } = entity;

        if (licenses_all && licenses_all.length > 0) {
            widgets.push(buildTextLine({
                bulletShow: false,
                title: `Найдено лицензий: ${licenses_all.length}`,
                content: '',
            }));
        }
        return widgets;
    };

    const buildFilteringWidgets = () => {
        const widgets = [];
        const { licenses_all } = entity;

        if (licenses_all && licenses_all.length > 1) {
            widgets.push(
                <Box display="flex">
                    <MyFilterButton
                        onClick={() => toggleFilter(LicenseAndTrademarkValidity.UNDEFINED)}
                        title="Все"
                        active={licenseFilter === LicenseAndTrademarkValidity.UNDEFINED}
                    />
                    <MyFilterButton
                        onClick={() => toggleFilter(LicenseAndTrademarkValidity.VALID)}
                        title="Действует"
                        active={licenseFilter === LicenseAndTrademarkValidity.VALID}
                    />
                    <MyFilterButton
                        onClick={() => toggleFilter(LicenseAndTrademarkValidity.PASSED)}
                        title="Истекла"
                        active={licenseFilter === LicenseAndTrademarkValidity.PASSED}
                    />
                    <MyFilterButton
                        onClick={() => toggleFilter(LicenseAndTrademarkValidity.UNKNOWN)}
                        title="Срок не указан"
                        active={licenseFilter === LicenseAndTrademarkValidity.UNKNOWN}
                    />
                </Box>
            );
        }
        return widgets;
    };

    return (
        <BusinessDetailsSkeleton
            entityName={entity.name}
            h1="Лицензии"
            h1tooltip={null}
            widgetsAfterTitleBeforeFilters={buildWidgetsAfterTitleBeforeFilters()}
            filteringWidgets={buildFilteringWidgets()}
            scrollController={scrollController}
            mainContent={buildLicensesWidgets()}
        />
    );
};

export default LicenseSection;
