import React, { useState } from 'react';
import { Card, CardContent, CardActions, Collapse, Button, Typography, IconButton } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';
import CircleLoader from 'react-spinners/CircleLoader';

const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));

const RecalculateProjectCard = ({ fetchingReanalyzer, reanalyzePayments, type }) => {
    const [expanded, setExpanded] = useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const handleRecalculateClick = () => {
        // Call the function to recalculate the project
        reanalyzePayments(type);
    };

    const myTitle = {
        reanalyze: 'Повторный анализ платежей',
        retotal: 'Пересчитать итоги по проекту'
    }
    const myDescription = {
        reanalyze: <span>При повторном анализе платежей будет использована самая актуальная и мощная
            система автоматической разметки и анализа платежей. Однако все ручные
            пометки (если они были), будут удалены.
            <br />
            <br />
            Продолжить?
            <br />
            <br />
        </span>,
        retotal: <span>Если пересчитать итоги, результаты будут загружаться немного быстрее.
            Хотя сам пересчет займет пару минут.
            <br />
            <br />
            Продолжить?
            <br />
            <br />
        </span>
    }
    const myButtonText = {
        reanalyze: 'Да, провести анализ платежей',
        retotal: 'Да, пересчитать итоги'
    }

    return (
        <Card>
            <CardContent onClick={handleExpandClick}>
                <Typography variant="body2" component="div" onClick={handleExpandClick} style={{ cursor: 'pointer' }}>
                    {myTitle[type]}
                </Typography>
            </CardContent>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                {fetchingReanalyzer &&
                    <CardContent>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                            <CircleLoader color="#8A4B9A" className='spinner' size={45} />
                        </div>
                    </CardContent>
                }
                {!fetchingReanalyzer &&
                    <CardContent>
                        <Typography variant="body2" color="textSecondary" component="p">
                            {myDescription[type]}
                        </Typography>
                        <Button variant="contained" color="primary" onClick={handleRecalculateClick}>
                            {myButtonText[type]}
                        </Button>
                    </CardContent>
                }
            </Collapse>
        </Card>
    );
};

export default RecalculateProjectCard;
