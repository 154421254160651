import React from 'react';
import { Grid, Card, CardContent, CardHeader, Button, ThemeProvider, IconButton, Tooltip } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import DoneAll from '@mui/icons-material/DoneAll';
import ToggleIconButton from './ToggleIconButton';
import { slicerTheme } from '../../theme';

const Slicer = ({ title, items, selectedItems, onToggle, onSelectAll, onDeselectAll, order = false, columnsOnMD = 2, columnsOnXs = 2 }) => {
    const allSelected = items.length === selectedItems.length;

    function sortedItems() { return (order ? items.sort() : items) };

    return (
        <ThemeProvider theme={slicerTheme}>
            <Card style={{ width: '100%' }} variant='slicer'>
                <CardHeader
                    variant='slicer'
                    title={title}
                    action={
                        <ToggleIconButton
                            icon={<DoneAll />}
                            isOn={items.length == selectedItems.length}
                            onSelectAll={onSelectAll}
                            onDeselectAll={onDeselectAll}
                        />
                    }
                />
                <CardContent>
                    <Grid container spacing={0.2}>
                        {sortedItems().map((item) => (
                            <Grid item xs={12 / columnsOnXs} md={12 / columnsOnXs} key={item}>
                                <Tooltip title={item} arrow placement='top'>
                                    <Button
                                        variant={selectedItems.includes(item) ? 'slicerOn' : 'slicerOff'}
                                        onClick={() => onToggle(item)}
                                        fullWidth
                                    >
                                        {item}
                                    </Button>
                                </Tooltip>
                            </Grid>
                        ))}
                    </Grid>
                </CardContent>
            </Card>
        </ThemeProvider>
    );
};

export default Slicer;
