import React from 'react';
import { Box } from '@mui/material';

const MyOverlay = ({ children, justifyContent = 'center', alignItems = 'center' }) => {

    return (

        <Box sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(255, 255, 255, 0.7)',
            zIndex: 10,
            display: 'flex',
            justifyContent: justifyContent,
            alignItems: alignItems,
            backdropFilter: 'blur(2px)',
        }}>
            {children}
        </Box>
    );
}

export default MyOverlay;
