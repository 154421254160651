export const totalsCodes = ['1600', '1700', '2400', '2500', '2900'];

export const totalsCodesForSimpleRegularMapping = [
    '1100',
    '1200',
    '1400',
    '1500',
    '1600',
    '1700',
    '2100',
    '2200',
    '2300',
    '2400'
];

export const indicatorsMap = [
    { key: '1110', label: 'Нематериальные активы' },
    { key: '1120', label: 'Результаты исследований и разработок' },
    { key: '1130', label: 'Нематериальные поисковые активы' },
    { key: '1140', label: 'Материальные поисковые активы' },
    { key: '1150', label: 'Основные средства' },
    { key: '1160', label: 'Доходные вложения в материальные ценности' },
    { key: '1170', label: 'Финансовые вложения' },
    { key: '1180', label: 'Отложенные налоговые активы' },
    { key: '1190', label: 'Прочие внеоборотные активы' },
    { key: '1100', label: 'Итого внеоборотных активов' },
    { key: '1210', label: 'Запасы' },
    { key: '1220', label: 'Налог на добавленную стоимость по приобретенным ценностям' },
    { key: '1230', label: 'Дебиторская задолженность' },
    { key: '1231', label: 'в т.ч. долгосрочная дебиторская задолженность' },
    { key: '1240', label: 'Финансовые вложения (за исключением денежных эквивалентов)' },
    { key: '1250', label: 'Денежные средства и денежные эквиваленты' },
    { key: '1260', label: 'Прочие оборотные активы' },
    { key: '1200', label: 'Итого оборотных активов' },
    { key: '1600', label: 'Баланс (актив)' },
    { key: '1310', label: 'Уставный капитал (складочный капитал, уставный фонд, вклады товарищей)' },
    { key: '1320', label: 'Собственные акции, выкупленные у акционеров' },
    { key: '1340', label: 'Переоценка внеоборотных активов' },
    { key: '1350', label: 'Добавочный капитал (без переоценки)' },
    { key: '1360', label: 'Резервный капитал' },
    { key: '1370', label: 'Нераспределенная прибыль (непокрытый убыток)' },
    { key: '1300', label: 'Итого капитал' },
    { key: '1410', label: 'Долгосрочные заемные средства' },
    { key: '1420', label: 'Отложенные налоговые обязательства' },
    { key: '1430', label: 'Оценочные обязательства' },
    { key: '1450', label: 'Прочие долгосрочные обязательства' },
    { key: '1400', label: 'Итого долгосрочных обязательств' },
    { key: '1510', label: 'Краткосрочные заемные обязательства' },
    { key: '1520', label: 'Краткосрочная кредиторская задолженность' },
    { key: '1530', label: 'Доходы будущих периодов' },
    { key: '1540', label: 'Оценочные обязательства' },
    { key: '1550', label: 'Прочие краткосрочные обязательства' },
    { key: '1500', label: 'Итого краткосрочных обязательств' },
    { key: '1700', label: 'Баланс (пассив)' },
    { key: '2110', label: 'Выручка' },
    { key: '2120', label: 'Себестоимость продаж' },
    { key: '2100', label: 'Валовая прибыль (убыток)' },
    { key: '2210', label: 'Коммерческие расходы' },
    { key: '2220', label: 'Управленческие расходы' },
    { key: '2200', label: 'Прибыль (убыток) от продаж' },
    { key: '2310', label: 'Доходы от участия в других организациях' },
    { key: '2320', label: 'Проценты к получению' },
    { key: '2330', label: 'Проценты к уплате' },
    { key: '2340', label: 'Прочие доходы' },
    { key: '2350', label: 'Прочие расходы' },
    { key: '2300', label: 'Прибыль (убыток) до налогообложения' },
    { key: '2410', label: 'Налог на прибыль' },
    { key: '2411', label: 'Текущий налог на прибыль' },
    { key: '2412', label: 'Отложенный налог на прибыль' },
    { key: '2421', label: 'Постоянные налоговые обязательства' },
    { key: '2430', label: 'Изменение отложенных налоговых обязательств' },
    { key: '2450', label: 'Изменение отложенных налоговых активов' },
    { key: '2460', label: 'Прочее' },
    { key: '2400', label: 'Чистая прибыль (убыток)' },
    { key: '2510', label: 'Результат от переоценки внеоборотных активов, не включаемый в чистую прибыль (убыток) периода' },
    { key: '2520', label: 'Результат от прочих операций, не включаемый в чистую прибыль (убыток) периода' },
    { key: '2530', label: 'Налог на прибыль от операций, результат которых не включается в чистую прибыль (убыток) периода' },
    { key: '2500', label: 'Совокупный финансовый результат периода' },
    { key: '2910', label: 'Разводненная прибыль (убыток) на акцию' },
    { key: '2900', label: 'Базовая прибыль (убыток) на акцию' }
];


export const indicatorsSimplifiedMap = [
    { key: '1150', label: 'Материальные внеоборотные активы' },
    { key: '1110', label: `Нематериальные, финансовые и другие внеоборотные активы [с наибольшей долей показателя ${indicatorsMap.find(item => item.key === '1110').label}]` },
    { key: '1120', label: `Нематериальные, финансовые и другие внеоборотные активы [с наибольшей долей показателя ${indicatorsMap.find(item => item.key === '1120').label}]` },
    { key: '1130', label: `Нематериальные, финансовые и другие внеоборотные активы [с наибольшей долей показателя ${indicatorsMap.find(item => item.key === '1130').label}]` },
    { key: '1140', label: `Нематериальные, финансовые и другие внеоборотные активы [с наибольшей долей показателя ${indicatorsMap.find(item => item.key === '1140').label}]` },
    { key: '1160', label: `Нематериальные, финансовые и другие внеоборотные активы [с наибольшей долей показателя ${indicatorsMap.find(item => item.key === '1160').label}]` },
    { key: '1170', label: `Нематериальные, финансовые и другие внеоборотные активы [с наибольшей долей показателя ${indicatorsMap.find(item => item.key === '1170').label}]` },
    { key: '1180', label: `Нематериальные, финансовые и другие внеоборотные активы [с наибольшей долей показателя ${indicatorsMap.find(item => item.key === '1180').label}]` },
    { key: '1190', label: `Нематериальные, финансовые и другие внеоборотные активы [с наибольшей долей показателя ${indicatorsMap.find(item => item.key === '1190').label}]` },
    { key: '1210', label: 'Запасы' },
    { key: '1250', label: 'Денежные средства и денежные эквиваленты' },
    { key: '1220', label: `Финансовые и другие оборотные активы [с наибольшей долей показателя ${indicatorsMap.find(item => item.key === '1220').label}]` },
    { key: '1230', label: `Финансовые и другие оборотные активы [с наибольшей долей показателя ${indicatorsMap.find(item => item.key === '1230').label}]` },
    { key: '1231', label: 'в т.ч. долгосрочная дебиторская задолженность' },
    { key: '1240', label: `Финансовые и другие оборотные активы [с наибольшей долей показателя ${indicatorsMap.find(item => item.key === '1240').label}]` },
    { key: '1260', label: `Финансовые и другие оборотные активы [с наибольшей долей показателя ${indicatorsMap.find(item => item.key === '1260').label}]` },
    { key: '1600', label: 'Баланс (актив)' },
    { key: '1300', label: 'Итого капитал' },
    { key: '1410', label: 'Долгосрочные заемные средства' },
    { key: '1420', label: `Другие долгосрочные обязательства [с наибольшей долей показателя ${indicatorsMap.find(item => item.key === '1420').label}]` },
    { key: '1430', label: `Другие долгосрочные обязательства [с наибольшей долей показателя ${indicatorsMap.find(item => item.key === '1430').label}]` },
    { key: '1450', label: `Другие долгосрочные обязательства [с наибольшей долей показателя ${indicatorsMap.find(item => item.key === '1450').label}]` },
    { key: '1510', label: 'Краткосрочные заемные обязательства' },
    { key: '1520', label: `Другие краткосрочные обязательства [с наибольшей долей показателя ${indicatorsMap.find(item => item.key === '1520').label}]` },
    { key: '1530', label: `Другие краткосрочные обязательства [с наибольшей долей показателя ${indicatorsMap.find(item => item.key === '1530').label}]` },
    { key: '1540', label: `Другие краткосрочные обязательства [с наибольшей долей показателя ${indicatorsMap.find(item => item.key === '1540').label}]` },
    { key: '1550', label: `Другие краткосрочные обязательства [с наибольшей долей показателя ${indicatorsMap.find(item => item.key === '1550').label}]` },
    { key: '1700', label: 'Баланс (пассив)' },
    { key: '2110', label: 'Выручка' },
    { key: '2120', label: `Расходы по обычной деятельности [с наибольшей долей показателя ${indicatorsMap.find(item => item.key === '2120').label}]` },
    { key: '2210', label: `Расходы по обычной деятельности [с наибольшей долей показателя ${indicatorsMap.find(item => item.key === '2210').label}]` },
    { key: '2220', label: `Расходы по обычной деятельности [с наибольшей долей показателя ${indicatorsMap.find(item => item.key === '2220').label}]` },
    { key: '2330', label: 'Проценты к уплате' },
    { key: '2310', label: `Прочие доходы [с наибольшей долей показателя ${indicatorsMap.find(item => item.key === '2310').label}]` },
    { key: '2320', label: `Прочие доходы [с наибольшей долей показателя ${indicatorsMap.find(item => item.key === '2320').label}]` },
    { key: '2340', label: 'Прочие доходы' },
    { key: '2350', label: 'Прочие расходы' },
    { key: '2410', label: 'Налоги на прибыль (доходы) (с кодом 2410)' },
    { key: '2411', label: 'Налоги на прибыль (доходы) (с кодом 2411)' },
    { key: '2460', label: 'Налоги на прибыль (доходы) (с кодом 2460)' },
    { key: '2400', label: 'Чистая прибыль (убыток)' }
];

export const simpleCasualMapping = {
    '1110': ['1110', '1120', '1130', '1140', '1160', '1170', '1180', '1190'],
    '1120': ['1110', '1120', '1130', '1140', '1160', '1170', '1180', '1190'],
    '1130': ['1110', '1120', '1130', '1140', '1160', '1170', '1180', '1190'],
    '1140': ['1110', '1120', '1130', '1140', '1160', '1170', '1180', '1190'],
    '1150': ['1150'],
    '1160': ['1110', '1120', '1130', '1140', '1160', '1170', '1180', '1190'],
    '1170': ['1110', '1120', '1130', '1140', '1160', '1170', '1180', '1190'],
    '1180': ['1110', '1120', '1130', '1140', '1160', '1170', '1180', '1190'],
    '1190': ['1110', '1120', '1130', '1140', '1160', '1170', '1180', '1190'],
    '1100': [
        '1110',
        '1120',
        '1130',
        '1140',
        '1150',
        '1160',
        '1170',
        '1180',
        '1190'
    ],
    '1210': ['1210'],
    '1220': ['1220', '1230', '1240', '1260'],
    '1230': ['1220', '1230', '1240', '1260'],
    '1231': ['1231'],
    '1240': ['1220', '1230', '1240', '1260'],
    '1250': ['1250'],
    '1260': ['1220', '1230', '1240', '1260'],
    '1200': ['1210', '1220', '1230', '1240', '1250', '1260'],
    '1600': ['1600'],
    '1300': ['1300'],
    '1410': ['1410'],
    '1420': ['1420', '1430', '1450'],
    '1430': ['1420', '1430', '1450'],
    '1450': ['1420', '1430', '1450'],
    '1400': ['1410', '1420', '1430', '1450'],
    '1510': ['1510'],
    '1520': ['1520', '1530', '1540', '1550'],
    '1530': ['1520', '1530', '1540', '1550'],
    '1540': ['1520', '1530', '1540', '1550'],
    '1550': ['1520', '1530', '1540', '1550'],
    '1500': ['1510', '1520', '1530', '1540', '1550'],
    '1700': ['1700'],
    '2110': ['2110'],
    '2120': ['2120', '2210', '2220'],
    '2100': ['2110', '-2120'],
    '2210': ['2120', '2210', '2220'],
    '2220': ['2120', '2210', '2220'],
    '2200': ['2110', '-2120', '-2210', '-2220'],
    '2330': ['2330'],
    '2310': ['2310', '2320', '2340'],
    '2320': ['2310', '2320', '2340'],
    '2340': ['2310', '2320', '2340'],
    '2350': ['2350'],
    '2300': [
        '2110',
        '-2120',
        '-2210',
        '-2220',
        '2310',
        '2320',
        '-2330',
        '2340',
        '-2350'
    ],
    '2410': ['2410'],
    '2411': ['2411'],
    '2460': ['2460'],
    '2400': ['2400'],
    '2500': ['2400', '2400', '-2400'],
};

export const indicatorsMapCharts = {
    '1110': 'НМА',
    '1120': 'НИОКР',
    '1130': 'НМПА',
    '1140': 'МПА',
    '1150': 'ОС',
    '1160': 'ДВМЦ',
    '1170': 'ФВ',
    '1180': 'ОНА',
    '1190': 'ПВА',
    '1100': 'Итого ВА',
    '1210': 'Запасы',
    '1220': 'НДС',
    '1230': 'ДЗ',
    '1231': 'ДДЗ',
    '1240': 'КФВ',
    '1250': 'ДС',
    '1260': 'ПОА',
    '1200': 'Итого ОФ',
    '1600': 'Активы',
    '1310': 'УК',
    '1320': 'СА',
    '1340': 'Переоценка',
    '1350': 'ДК',
    '1360': 'РК',
    '1370': 'НРП',
    '1300': 'Итого КиР',
    '1410': 'ДЗС',
    '1420': 'ОНО',
    '1430': 'ДОО',
    '1450': 'ПДО',
    '1400': 'Итого ДО',
    '1510': 'КЗС',
    '1520': 'КЗ',
    '1530': 'ДБП',
    '1540': 'КОО',
    '1550': 'ПКО',
    '1500': 'Итого КО',
    '1700': 'Пассивы',
    '2110': 'Выручка',
    '2120': 'Себес.',
    '2100': 'Вал.приб.',
    '2210': 'Ком.рас.',
    '2220': 'Упр.рас.',
    '2200': 'Приб.продаж',
    '2310': 'Долевые дох.',
    '2320': 'Дох.проценты',
    '2330': 'Расх.проценты',
    '2340': 'Пр.дох.',
    '2350': 'Пр.рас.',
    '2300': 'Приб.до налога',
    '2410': 'НнП',
    '2411': 'Тек.НнП',
    '2412': 'Отл.НнП',
    '2421': 'Пост.НО',
    '2430': 'Изм.ОНО',
    '2450': 'Изм.ОНА',
    '2460': 'Прочее',
    '2400': 'ЧП',
    '2510': 'Переоценка, за ЧП',
    '2520': 'Прочее, за ЧП',
    '2530': 'НнП по прочему',
    '2500': 'Совокуп.Финрез',
    '2910': 'Развод.EPS',
    '2900': 'Базовая EPS'
};

export const indicatorsSimplifiedMapCharts = {
    '1150': 'МВА',
    '1110': 'НВА',
    '1120': 'НВА',
    '1130': 'НВА',
    '1140': 'НВА',
    '1160': 'НВА',
    '1170': 'НВА',
    '1180': 'НВА',
    '1190': 'НВА',
    '1210': 'Запасы',
    '1250': 'ДС',
    '1220': 'ФОА и пр.',
    '1230': 'ФОА и пр.',
    '1231': 'ДДЗ',
    '1240': 'ФОА и пр.',
    '1260': 'ФОА и пр.',
    '1600': 'Активы',
    '1300': 'Итого КиР',
    '1410': 'ДЗС',
    '1420': 'ДДО',
    '1430': 'ДДО',
    '1450': 'ДДО',
    '1510': 'КЗС',
    '1520': 'ДКО',
    '1530': 'ДКО',
    '1540': 'ДКО',
    '1550': 'ДКО',
    '1700': 'Пассивы',
    '2110': 'Выручка',
    '2120': 'Об.расходы',
    '2210': 'Об.расходы',
    '2220': 'Об.расходы',
    '2330': 'Расх.проценты',
    '2310': 'Пр.дох.',
    '2320': 'Пр.дох.',
    '2340': 'Пр.дох.',
    '2350': 'Пр.рас.',
    '2410': 'НнП/УСН',
    '2411': 'НнП/УСН',
    '2460': 'НнП/УСН',
    '2400': 'ЧП',
};

export const defaultFinRepChartIndicators = ['0_1600', '0_2110', '0_2400'];
