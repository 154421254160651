import React, { useState, useEffect } from 'react';
import { Typography, Box, Button, Divider } from '@mui/material';
import {
    formatDoubleToPaddedString, buildTextLine,
} from '../../utils/utils';
import {
    getDivisionProperty, Divisions, DivisionProperty
} from '../../utils/vars';

import BusinessDetailsSkeleton from './BusinessDetailsSkeleton';
import Entity from '../../classes/Entity';
import MyFilterButton from '../common/MyFilterButton';
import './styles/Dashboard.css';

const TaxesSection = ({ entity, analytics }) => {
    const [violationFilter, setViolationFilter] = useState(null);
    const [division, setDivision] = useState(getDivisionProperty(Divisions.THS, DivisionProperty.NUMBER));
    const [measure, setMeasure] = useState(getDivisionProperty(Divisions.THS, DivisionProperty.SHORT_NAME));

    const scrollController = React.useRef(null);

    const toggleDivision = (newDivision) => {
        setDivision(newDivision);
        setMeasure(
            newDivision === 1
                ? getDivisionProperty(Divisions.ONE, DivisionProperty.SHORT_NAME)
                : newDivision === 1000
                    ? getDivisionProperty(Divisions.THS, DivisionProperty.SHORT_NAME)
                    : newDivision === 1000000
                        ? getDivisionProperty(Divisions.MLN, DivisionProperty.SHORT_NAME)
                        : ''
        );
    };

    const scrollToStart = () => {
        scrollController.current?.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const toggleFilter = (newFilter) => {
        setViolationFilter((prev) => (newFilter === prev || newFilter == null ? null : newFilter));
        scrollToStart();
    };

    const buildWidgetsByTaxMap = (title, valuesByTaxes) => {
        const res = [];
        res.push(<Typography variant="h4">{title}</Typography>);
        if (!valuesByTaxes || Object.keys(valuesByTaxes).length === 0) {
            res.push(buildTextLine({ bulletShow: false, content: 'Такие налоги не уплачивались' }));
        } else {
            let totalPaid = 0;
            Object.entries(valuesByTaxes).forEach(([taxName, amount]) => {
                totalPaid += amount || 0;
            });
            if (totalPaid === 0) {
                res.push(buildTextLine({ bulletShow: false, content: 'Такие налоги не уплачивались' }));
            } else {
                if (Object.keys(valuesByTaxes).length > 1) {
                    res.push(
                        buildTextLine({
                            bulletShow: false,
                            content: `Всего: ${formatDoubleToPaddedString({ number: totalPaid / division })} ${measure} В т.ч.:`,
                        })
                    );
                }
                Object.entries(valuesByTaxes).forEach(([taxName, amount]) => {
                    res.push(
                        buildTextLine({
                            bulletShow: true,
                            content: `${taxName}: ${formatDoubleToPaddedString({ number: amount / division })} ${measure}`,
                        })
                    );
                });
            }
        }
        res.push(<Box height={10} />);
        return res;
    };

    const buildTaxesWidgets = () => {
        const widgets = [];
        let noData;

        if (entity.type === 'businessIndividual') {
            noData =
                'Информация об уплате налогов, сборов и взносов предпринмателями не раскрывается налоговыми органами. Вы можете запросить копии налоговых деклараций у предпринимателя.';
        } else if (!entity.taxesPaid || entity.taxesPaid.length === 0) {
            noData = 'Сведения об уплаченных налогах не найдены';
        }

        if (noData) {
            widgets.push(buildTextLine({ bulletShow: false, content: noData }));
        } else {
            entity.taxesPaid.forEach((taxesPerYear) => {
                if (taxesPerYear.year) {
                    widgets.push(<Typography variant="h3">{taxesPerYear.year}</Typography>);
                    widgets.push(
                        buildTextLine({
                            bulletShow: false,
                            title: 'Всего уплачено: ',
                            content: `${formatDoubleToPaddedString({ number: taxesPerYear.totalPaid / division })} ${measure}`,
                        })
                    );
                }
                widgets.push(
                    buildTextLine({
                        bulletShow: false,
                        title: 'Недоимка: ',
                        titleColor: taxesPerYear.totalOverdue > 0 ? 'red' : null,
                        content: `${formatDoubleToPaddedString({ number: taxesPerYear.totalOverdue / division })} ${measure}${taxesPerYear.overdueDate ? ` на ${new Date(taxesPerYear.overdueDate).toLocaleDateString()}` : ''
                            }`,
                    })
                );
                widgets.push(<Box height={10} />);
                if (taxesPerYear.year) {
                    widgets.push(...buildWidgetsByTaxMap('Налоги на прибыль (или УСН)', taxesPerYear.profitTaxes));
                    widgets.push(...buildWidgetsByTaxMap('НДС', taxesPerYear.vatTaxes));
                    widgets.push(...buildWidgetsByTaxMap('Имущество', taxesPerYear.propertyTaxes));
                    widgets.push(...buildWidgetsByTaxMap('Транспорт', taxesPerYear.transportTaxes));
                    widgets.push(...buildWidgetsByTaxMap('Страховые взносы', taxesPerYear.socialTaxes));
                    widgets.push(...buildWidgetsByTaxMap('Прочие налоги и взносы', taxesPerYear.otherTaxes));
                }
            });
        }

        return widgets;
    };


    const buildWidgetsAfterTitleBeforeFilters = () => {
        const widgets = [];

        if (entity.type === 'businessIndividual') {
            widgets.push(buildTextLine({
                content:
                    'Сервис пока не раскрывает данные о специальных налоговых режимах предпринимателей.\nМы активно работаем над этим функционалом и скоро его реализуем. Пока вы можете написать на info@brpadvice.ru, чтобы получить актуальную информацию.',
            }));
        } else {
            widgets.push(buildTextLine({
                title:
                    entity.taxSpecialRegimes && entity.taxSpecialRegimes.length > 1
                        ? 'Специальные налоговые режимы: '
                        : 'Специальный налоговый режим: ',
                content:
                    entity.taxSpecialRegimes && entity.taxSpecialRegimes.length >= 1
                        ? entity.taxSpecialRegimes.join(', ')
                        : 'записи не найдены (не применяется).',
            }));
        }
        return widgets;
    };

    const buildFilteringWidgets = () => {
        const widgets = [];
        if (entity.taxesPaid && entity.taxesPaid.length > 0) {
            widgets.push(
                <Box display="flex">
                    <MyFilterButton
                        onClick={() => toggleDivision(getDivisionProperty(Divisions.ONE, DivisionProperty.NUMBER))}
                        title={getDivisionProperty(Divisions.ONE, DivisionProperty.SHORT_NAME)}
                        active={division === getDivisionProperty(Divisions.ONE, DivisionProperty.NUMBER)}
                    />
                    <MyFilterButton
                        onClick={() => toggleDivision(getDivisionProperty(Divisions.THS, DivisionProperty.NUMBER))}
                        title={getDivisionProperty(Divisions.THS, DivisionProperty.SHORT_NAME)}
                        active={division === getDivisionProperty(Divisions.THS, DivisionProperty.NUMBER)}
                    />
                    <MyFilterButton
                        onClick={() => toggleDivision(getDivisionProperty(Divisions.MLN, DivisionProperty.NUMBER))}
                        title={getDivisionProperty(Divisions.MLN, DivisionProperty.SHORT_NAME)}
                        active={division === getDivisionProperty(Divisions.MLN, DivisionProperty.NUMBER)}
                    />
                </Box>
            );
        };
        return widgets;
    };


    return (
        <BusinessDetailsSkeleton
            entityName={entity.name}
            h1="Налоги"
            widgetsAfterTitleBeforeFilters={buildWidgetsAfterTitleBeforeFilters()}
            filteringWidgets={buildFilteringWidgets()}
            scrollController={scrollController}
            mainContent={buildTaxesWidgets()}
        />
    );
};

export default TaxesSection;
