// components/Intro.js
import React, { useState } from 'react';
import { Container, Grid, Typography, Button, Box } from '@mui/material';
import Spacer from './Spacer';
import { Link as ScrollLink } from 'react-scroll';

function Intro() {
    const [rotation, setRotation] = useState({ x: 0, y: 0 });

    const handleMouseMove = (event) => {
        const { clientX, clientY, currentTarget } = event;
        const { offsetWidth, offsetHeight } = currentTarget;
        const xRotation = ((clientY / offsetHeight) * 30) - 10;
        const yRotation = -(((clientX / offsetWidth) * 30) - 10);
        setRotation({ x: xRotation, y: yRotation });
    };

    return (
        <Box sx={{ backgroundColor: '#132036', color: 'white', py: 4 }} onMouseMove={handleMouseMove}>
            <Spacer height="50px"></Spacer>
            <Container maxWidth="lg">
                <Grid container spacing={4} alignItems="center">
                    <Grid item xs={12} md={7}>
                        <Typography variant="h1" component="h1" gutterBottom sx={{
                            color: 'white',
                            fontSize: '2.4em',
                            fontWeight: 'bold',
                            marginBottom: '40px',
                        }}>
                            Анализ банковских выписок и&nbsp;проверка контрагентов
                        </Typography>
                        <Typography variant="body1" gutterBottom sx={{ color: 'white', fontSize: '1.4em' }}>
                            Выявляйте подозрительные сделки, рассчитывайте кредитный лимит, определяйте реальное финансовое положение компании и ИП. Онлайн за одну минуту
                        </Typography>
                        <ScrollLink to="orderForm" smooth={true} duration={500}>
                            <Button
                                variant="contained"
                                sx={{
                                    marginTop: '40px',
                                    backgroundColor: "#072AA9",
                                    color: "white",
                                    padding: '25px 50px 25px 50px',
                                    fontSize: '20px'
                                }}
                            >
                                Зарегистрироваться
                            </Button>
                        </ScrollLink>
                    </Grid>
                    <Grid item xs={12} md={5}>
                        <Box sx={{ position: 'relative', perspective: '1000px' }}>
                            <img
                                src="https://brpadvice.ru/wp-content/uploads/2023/01/Bank-Logo-PNG-Clipart-Background-768x480.png"
                                alt=""
                                style={{
                                    width: '100%',
                                    transform: `rotateX(${rotation.x}deg) rotateY(${rotation.y}deg)`,
                                    transition: 'transform 0.1s',
                                }}
                            />
                        </Box>
                    </Grid>
                </Grid>
            </Container>
            <Spacer height="50px"></Spacer>
        </Box>
    );
}

export default Intro;
