import React, { useState } from 'react';
import {
    Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
    Tooltip
} from '@mui/material';
import { formatDoubleToPaddedString, formatMonth } from '../../../utils/utils';
import { ThemeProvider } from '@emotion/react';
import { createTheme } from '@mui/material/styles';
import MyFilterButton from '../../common/MyFilterButton';
import { Help } from '@mui/icons-material';

const OverallsByTransactionTypesTable = ({ inflows, outflows, months, }) => {
    const [division, setDivision] = useState(1000);

    const toggleDivision = (newDivision) => {
        setDivision(newDivision);
    };

    // Calculate netflows
    const netflows = months.map((month, index) => inflows[index] - outflows[index]);

    // Calculate 3-month sliding average
    const calculateSlidingAverage = (data) => {
        return data.map((value, index, array) => {
            if (index < 2) return 'н/д';
            const average = (array[index] + array[index - 1] + array[index - 2]) / 3;
            return Math.round(average);
        });
    };

    const inflowsAverage = calculateSlidingAverage(inflows);
    const outflowsAverage = calculateSlidingAverage(outflows);
    const netflowsAverage = calculateSlidingAverage(netflows);

    // Calculate totals and overall averages
    const totalInflow = inflows.reduce((acc, value) => acc + value, 0);
    const totalOutflow = outflows.reduce((acc, value) => acc + value, 0);
    const totalNetflow = netflows.reduce((acc, value) => acc + value, 0);

    const averageInflows = totalInflow / months.length;
    const averageOutflows = totalOutflow / months.length;
    const averageNetflows = totalNetflow / months.length;

    const firstColumnStyle = {
        position: 'sticky',
        left: 0,
        zIndex: 1,
        background: 'lavender', // or any background color to match the rest of the table
        borderRight: '1px solid #ddd',
        fontWeight: 'bold',
    };
    const firstColumnTotalStyle = {
        ...firstColumnStyle,
        background: '#c4c4c4', // different background for total row
    };

    const theme = createTheme({
        components: {
            MuiTableRow: {
                variants: [
                    {
                        props: { variant: 'odd' },
                        style: {
                            backgroundColor: '#f9f9f9',
                            fontStyle: 'italic',
                        },
                    },
                    {
                        props: { variant: 'total' },
                        style: {
                            backgroundColor: '#c4c4c4',
                            fontWeight: 'bold',
                        },
                    },
                    {
                        props: { variant: 'even' },
                        style: {
                            backgroundColor: 'transparent',
                        },
                    },
                ],
            },
            MuiTableCell: {
                styleOverrides: {
                    root: {
                        fontSize: 'small',
                        backgroundColor: null,
                    },
                    head: {
                        fontWeight: '800',
                        backgroundColor: 'lavender',
                    }
                },
            },
        },
    });

    return (
        <ThemeProvider theme={theme}>
            <Box sx={{ marginTop: '40px', marginBottom: '40px' }}>
                <Box>
                    <Box display="flex" mt={2}>
                        <MyFilterButton onClick={() => toggleDivision(1)} title="в руб." active={division === 1} />
                        <MyFilterButton onClick={() => toggleDivision(1000)} title="в тыс. руб." active={division === 1000} />
                        <MyFilterButton onClick={() => toggleDivision(1000000)} title="в млн. руб." active={division === 1000000} />
                    </Box>
                </Box>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell style={firstColumnStyle}> </TableCell>
                                {months.map((month, index) => (
                                    <TableCell key={index} align="center">
                                        {formatMonth(month)}
                                    </TableCell>
                                ))}
                                <TableCell style={firstColumnTotalStyle}>Итого / среднее</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell style={firstColumnStyle}>Поступления</TableCell>
                                {inflows.map((inflow, index) => (
                                    <TableCell key={index} align="center">
                                        {formatDoubleToPaddedString({
                                            number: Math.round(inflow) / division,
                                            roundDigits: 0,
                                        })}
                                    </TableCell>
                                ))}
                                <TableCell style={firstColumnTotalStyle} align="center">
                                    {formatDoubleToPaddedString({
                                        number: Math.round(totalInflow) / division,
                                        roundDigits: 0,
                                    })}
                                </TableCell>
                            </TableRow>
                            <TableRow variant='odd'>
                                <TableCell style={firstColumnStyle}>
                                    <Tooltip
                                        title={
                                            <span>
                                                По месяцам: скользящая средняя = сумма за последние три месяца,
                                                включая отчетный, деленная на три.
                                                <br />
                                                Итого: среднее за все отчетные месяцы.
                                            </span>
                                        }
                                    >
                                        <span style={{ fontStyle: 'italic', color: '#1976d2' }}>
                                            - среднее за три месяца
                                        </span>
                                    </Tooltip>
                                </TableCell>
                                {inflowsAverage.map((average, index) => (
                                    <TableCell key={index} align="center">
                                        {average === 'н/д' ? average : formatDoubleToPaddedString({
                                            number: average / division,
                                            roundDigits: 0,
                                        })}
                                    </TableCell>
                                ))}
                                <TableCell style={firstColumnTotalStyle} align="center">
                                    {formatDoubleToPaddedString({
                                        number: Math.round(averageInflows) / division,
                                        roundDigits: 0,
                                    })}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell style={firstColumnStyle}>Платежи</TableCell>
                                {outflows.map((outflow, index) => (
                                    <TableCell key={index} align="center">
                                        {formatDoubleToPaddedString({
                                            number: Math.round(outflow) / division,
                                            roundDigits: 0,
                                        })}
                                    </TableCell>
                                ))}
                                <TableCell style={firstColumnTotalStyle} align="center">
                                    {formatDoubleToPaddedString({
                                        number: Math.round(totalOutflow) / division,
                                        roundDigits: 0,
                                    })}
                                </TableCell>
                            </TableRow>
                            <TableRow variant='odd'>
                                <TableCell style={firstColumnStyle}>
                                    <Tooltip
                                        title={
                                            <span>
                                                По месяцам: скользящая средняя = сумма за последние три месяца,
                                                включая отчетный, деленная на три.
                                                <br />
                                                Итого: среднее за все отчетные месяцы.
                                            </span>
                                        }
                                    >
                                        <span style={{ fontStyle: 'italic', color: '#1976d2' }}>
                                            - среднее за три месяца
                                        </span>
                                    </Tooltip>
                                </TableCell>
                                {outflowsAverage.map((average, index) => (
                                    <TableCell key={index} align="center">
                                        {average === 'н/д' ? average : formatDoubleToPaddedString({
                                            number: average / division,
                                            roundDigits: 0,
                                        })}
                                    </TableCell>
                                ))}
                                <TableCell style={firstColumnTotalStyle} align="center">
                                    {formatDoubleToPaddedString({
                                        number: Math.round(averageOutflows) / division,
                                        roundDigits: 0,
                                    })}
                                </TableCell>
                            </TableRow>
                            <TableRow variant='total'>
                                <TableCell style={firstColumnTotalStyle}>Итого</TableCell>
                                {netflows.map((netflow, index) => (
                                    <TableCell key={index} align="center">
                                        <span style={{
                                            fontWeight: 'bold',
                                            color:
                                                Math.round(netflow) / division < 0
                                                    ? 'red'
                                                    : (
                                                        Math.round(netflow) / division === 0
                                                            ? 'grey'
                                                            : ''
                                                    )
                                        }}>
                                            {formatDoubleToPaddedString({
                                                number: Math.round(netflow) / division,
                                                roundDigits: 0,
                                            })}
                                        </span>
                                    </TableCell>
                                ))}
                                <TableCell style={firstColumnTotalStyle} align="center">
                                    <span style={{
                                        fontWeight: 'bold',
                                        color:
                                            Math.round(totalNetflow) / division < 0
                                                ? 'red'
                                                : (
                                                    Math.round(totalNetflow) / division === 0
                                                        ? 'grey'
                                                        : ''
                                                )
                                    }}>
                                        {formatDoubleToPaddedString({
                                            number: Math.round(totalNetflow) / division,
                                            roundDigits: 0,
                                        })}
                                    </span>
                                </TableCell>
                            </TableRow>
                            <TableRow variant='total'>
                                <TableCell style={firstColumnTotalStyle}>
                                    <Tooltip
                                        title={
                                            <span>
                                                По месяцам: скользящая средняя = сумма за последние три месяца,
                                                включая отчетный, деленная на три.
                                                <br />
                                                Итого: среднее за все отчетные месяцы.
                                            </span>
                                        }
                                    >
                                        <span style={{ fontStyle: 'italic', color: '#1976d2' }}>
                                            - среднее за три месяца
                                        </span>
                                    </Tooltip>
                                </TableCell>
                                {netflowsAverage.map((average, index) => (
                                    <TableCell key={index} align="center">
                                        <span style={{
                                            fontStyle: 'italic',
                                            fontWeight: 'bold',
                                            color:
                                                Math.round(average) / division < 0
                                                    ? 'red'
                                                    : (
                                                        Math.round(average) / division === 0
                                                            ? 'grey'
                                                            : ''
                                                    )
                                        }}>
                                            {average === 'н/д' ? average : formatDoubleToPaddedString({
                                                number: average / division,
                                                roundDigits: 0,
                                            })}
                                        </span>
                                    </TableCell>
                                ))}
                                <TableCell style={firstColumnTotalStyle} align="center">
                                    <span style={{
                                        fontStyle: 'italic',
                                        fontWeight: 'bold',
                                        color:
                                            Math.round(averageNetflows) / division < 0
                                                ? 'red'
                                                : (
                                                    Math.round(averageNetflows) / division === 0
                                                        ? 'grey'
                                                        : ''
                                                )
                                    }}>
                                        {formatDoubleToPaddedString({
                                            number: Math.round(averageNetflows) / division,
                                            roundDigits: 0,
                                        })}
                                    </span>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box >
        </ThemeProvider >
    );
};

export default OverallsByTransactionTypesTable;
