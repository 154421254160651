import React, { useState } from 'react';
import { Box, Card, CardHeader, } from '@mui/material';
import ToggleIconButton from '../../common/ToggleIconButton';
import UnfoldLess from '@mui/icons-material/UnfoldLess';
import UnfoldMore from '@mui/icons-material/UnfoldMore';

const CashCardTemplate = ({ title, subheader, action, cardcontent, width = '100%', initalFolded = false }) => {
    const [isFolded, setIsFolded] = useState(initalFolded);

    return (
        <Card style={{ width: width, marginBottom: '30px' }} >
            <CardHeader
                onClick={() => {
                    if (isFolded) {
                        setIsFolded(!isFolded)
                    }
                }
                }
                title={title}
                subheader={subheader}
                action={
                    <Box sx={{ display: 'flex' }}>
                        {!isFolded &&
                            action
                        }
                        <ToggleIconButton
                            icon={isFolded ? <UnfoldMore /> : <UnfoldLess />}
                            isOn={!isFolded}
                            onSelectAll={() => setIsFolded(!isFolded)}
                            onDeselectAll={() => setIsFolded(!isFolded)}
                            backgroundColorOn='#fdbc30'
                            borderColorOn='#fdbc30'
                            backgroundColorOff='#d0e2cd'
                            borderColorOff='#d0e2cd'
                        />
                    </Box>
                }
            />
            {!isFolded &&
                cardcontent
            }
        </Card>
    );
};

export default CashCardTemplate;