import React from 'react';
import { Tooltip, Typography } from '@mui/material';



// Build text line
const MyDataLine = ({
    bulletShow = false,
    bullet = '● ',
    bulletColor,
    tooltipShow = false,
    tooltipText = '',
    tooltipTitle,
    tooltipUnbold = false,
    title,
    titleColor,
    titleLink,
    onTitleTap,
    content,
    url,
    moreSpans,
}) => {
    return (
        <Typography variant="body2" component="div">
            {bulletShow && <span style={{ color: bulletColor }}>{bullet}</span>}
            {tooltipShow && (tooltipTitle || title) ? (
                <Tooltip title={<span>{tooltipText}</span>} placement="top">
                    <span
                        className='pHeaderBusinessDetailsTooltip decorationNone'
                        style={{
                            fontWeight: tooltipUnbold ? 'normal' : '',
                        }}
                    >
                        {tooltipTitle || title}
                    </span>
                </Tooltip>
            ) : (
                <>
                    {title && (
                        titleLink ?
                            <a
                                href={titleLink}
                                className='pHeaderBusinessDetails pseudoHrefWithColor decorationNone'
                                target='_blank'
                            >
                                {title}
                            </a>
                            : <span
                                onClick={onTitleTap}
                                className={onTitleTap
                                    ? 'pHeaderBusinessDetails pseudoHrefWoColor decorationNone'
                                    : 'pHeaderBusinessDetails'
                                }
                                style={{
                                    color: titleColor || '',
                                }}
                            >
                                {title}
                            </span>
                    )}
                    {!url && <span>{content}</span>}
                    {url && (
                        <a
                            href={url}
                            target="_blank"
                            rel="noopener noreferrer"
                            className='pseudoHrefWithColor decorationNone'
                        >
                            {title || content}
                        </a>
                    )}
                    {moreSpans}
                </>
            )
            }
        </Typography >
    );
}

export default MyDataLine;