import { TextField } from '@mui/material';
import React, { useState, useRef, useEffect } from 'react';

const DynamicWidthTextField = ({
    label,
    variant,
    value,
    onChange,
    sx,
}) => {
    const [inputWidth, setInputWidth] = useState('100px');
    const inputRef = useRef(null);

    useEffect(() => {
        const calcWidth = () => {
            const textLength = value.length;
            const newWidth = textLength * 10 + 20; // You can adjust the multiplier and the added value based on font-size and padding.
            const availableWidth = window.innerWidth * 0.8;
            setInputWidth(Math.max(200, Math.min(newWidth, availableWidth)) + 'px');
        };

        calcWidth();
    }, [value]);

    return (
        <TextField
            label={label}
            variant={variant}
            value={value}
            onChange={(e) => {
                onChange(e.target.value)
            }}
            sx={sx}
            style={{ width: inputWidth }}
        />
    );
};

export default DynamicWidthTextField;
