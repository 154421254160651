import BusinessType from './BusinessType';
import { parseDateFromNullableString } from '../utils/utils';

class Shareholder {
    constructor({
        shareholderID,
        emitentOGRN,
        shareholderType,
        name,
        fullName,
        shareNominalValue,
        sharePercentage,
        since,
        accessRestricted = false,
        shareholderTill,
        shareholderINN,
        shareholderOGRN,
        registrationDate,
        country,
        address,
        registrationNumber,
        inaccuracy = false,
        inaccuracyDescription,
        massShareholderIndicator = false,
        regionCode,
        regionName
    }) {
        this.shareholderID = shareholderID;
        this.emitentOGRN = emitentOGRN;
        this.shareholderType = shareholderType;
        this.name = name;
        this.fullName = fullName;
        this.shareNominalValue = shareNominalValue;
        this.sharePercentage = sharePercentage;
        this.since = since;
        this.accessRestricted = accessRestricted;
        this.shareholderTill = shareholderTill;
        this.shareholderINN = shareholderINN;
        this.shareholderOGRN = shareholderOGRN;
        this.registrationDate = registrationDate;
        this.country = country;
        this.address = address;
        this.registrationNumber = registrationNumber;
        this.inaccuracy = inaccuracy;
        this.inaccuracyDescription = inaccuracyDescription;
        this.massShareholderIndicator = massShareholderIndicator;
        this.regionCode = regionCode;
        this.regionName = regionName;
    }

    static fromJson(jsonShareholder) {
        const parseBusinessType = (type) => {
            switch (type.toLowerCase()) {
                case 'individual':
                    return BusinessType.PERSON;
                case 'domesticorg':
                    return BusinessType.CORPORATION;
                case 'foreignorg':
                    return BusinessType.FOREIGN_ENTITY;
                case 'investmentfund':
                    return BusinessType.INVESTMENT_FUND;
                case 'russtate':
                    return BusinessType.RUS_STATE;
                case 'rusregion':
                    return BusinessType.RUS_REGION;
                case 'rusmunicip':
                    return BusinessType.RUS_MUNIC;
                default:
                    return BusinessType.UNKNOWN;
            }
        };

        return new Shareholder({
            shareholderID: parseInt(jsonShareholder['ShareholderID'], 10),
            emitentOGRN: jsonShareholder['EmitentOGRN'],
            shareholderType: parseBusinessType(jsonShareholder['ShareholderType']),
            name: jsonShareholder['Name'],
            fullName: jsonShareholder['FullName'],
            shareNominalValue: parseFloat(jsonShareholder['NominalValue']) ?? -1,
            sharePercentage: parseFloat(jsonShareholder['Percentage']) ?? -1,
            since: parseDateFromNullableString(jsonShareholder['RecordDate']),
            accessRestricted: (parseInt(jsonShareholder['AccessRestricted'], 10) ?? 0) === 1,
            shareholderTill: parseDateFromNullableString(jsonShareholder['ShareholderTill']),
            shareholderINN: jsonShareholder['ShareholderINN'],
            shareholderOGRN: jsonShareholder['ShareholderOGRN'],
            registrationDate: parseDateFromNullableString(jsonShareholder['RegistrationDate']),
            country: jsonShareholder['Country'],
            address: jsonShareholder['Address'],
            registrationNumber: jsonShareholder['RegistrationNumber'],
            inaccuracy: (parseInt(jsonShareholder['Inaccuracy'], 10) ?? 0) === 1,
            inaccuracyDescription: jsonShareholder['InaccuracyDescription'],
            massShareholderIndicator: (parseInt(jsonShareholder['MassShareholderIndicator'], 10) ?? 0) === 1,
            regionCode: jsonShareholder['RegionCode'],
            regionName: jsonShareholder['RegionName'],
        });
    }
}

export default Shareholder;
