// components/AnalysisUsageSection.js
import React from 'react';
import { Container, Box } from '@mui/material';
import AnalysisReasons from './AnalysisReasons';
import AnalysisBenefits from './AnalysisBenefits';
import Spacer from './Spacer';

function AnalysisUsageSection() {
    return (
        <Box sx={{ backgroundColor: 'white', py: 4 }}>
            <Container maxWidth="lg">
                <Spacer height="20px" />
                {/* <AnalysisReasons />
                <Spacer height="20px" /> */}
                <AnalysisBenefits />
                <Spacer height="20px" />
            </Container>
        </Box>
    );
}

export default AnalysisUsageSection;
