import React, { useState, useEffect, } from 'react';
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination,
    Typography,
    Tooltip, Box,
    TableSortLabel,
} from '@mui/material';
import CounterpartyLinks from '../elements/CounterpartyLinks';
import { formatDoubleToPaddedString, parseAndFormatDate } from '../../../utils/utils';

const validateNumber = (value, roundDigits = 0) => {
    return formatDoubleToPaddedString({ number: value, roundDigits: roundDigits, onErrorReturn: 'нет данных' });
};

const RisksMainTable = ({
    filteredRisks, setSelectedCompany, dropPage, filteredByDates, filteredDateStart, filteredDateEnd, filteredByCompany,
}) => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const [sortConfig, setSortConfig] = useState({ key: 'outflows', direction: 'desc' });

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0); // Reset to the first page
    };

    useEffect(() => {
        setPage(0);
    }, [dropPage])

    // Sorting Logic
    const handleSort = (columnKey) => {
        setSortConfig((prevConfig) => {
            const newDirection = prevConfig.key === columnKey && prevConfig.direction === 'desc' ? 'asc' : 'desc';
            return { key: columnKey, direction: newDirection };
        });
    };

    const sortedData = [...filteredRisks].sort((a, b) => {
        if (!sortConfig.key) return 0;
        const columnA = a[sortConfig.key] ?? 0;
        const columnB = b[sortConfig.key] ?? 0;

        if (columnA < columnB) return sortConfig.direction === 'asc' ? -1 : 1;
        if (columnA > columnB) return sortConfig.direction === 'asc' ? 1 : -1;
        return 0;
    });

    function getFiltersList() {
        let filtersList = []
        if (filteredByCompany) { filtersList.push('по ИНН') }
        if (filteredByDates) { filtersList.push('по датам') }
        return (filtersList);
    }


    const renderTableRows = () => {

        const rows = [];
        const startIndex = (filteredRisks && filteredRisks.length == 1) ? 0 : page * rowsPerPage;
        const endIndex = Math.min(startIndex + rowsPerPage, sortedData.length);

        for (let i = startIndex; i < endIndex; i++) {
            const entity = sortedData[i];
            // const entity = cashProject.risks[i];
            rows.push(
                <TableRow variant={(i % 2 !== 0) ? "oddCashAnalysis" : "evenCashAnalysis"} key={`${entity.CounterpartyINN}`}>
                    <TableCell variant="cashAnalysis">
                        {entity.ShortName} (ИНН {entity.CounterpartyINN})
                        <span style={{ color: 'red' }}>
                            {((entity.InvalidityScore || entity.BadStatusScore)
                                && (entity.InvalidityScore > 0 || entity.BadStatusScore > 0)) ? <span><br />• Недостоверные данные в ЕГРЮЛ</span> : null}
                            {(entity.MassAddressScore && entity.MassAddressScore > 0) ? <span><br />• Массовый адрес</span> : null}
                            {(entity.BankruptcyScore && entity.BankruptcyScore > 0) ? <span><br />• Идет банкротство</span> : null}
                            {(entity.LiquidationScore && entity.LiquidationScore > 0) ? <span><br />• Ликвидировано</span> : null}
                            {(entity.inflows && entity.outflows && (Math.min(entity.inflows, entity.outflows) / Math.max(entity.inflows, entity.outflows)) > 0.1) ?
                                <span>
                                    <br />
                                    <Tooltip title="Как счиитаем: если получили больше, чем заплатили, то оплаты / поступления; если заплатили больше, то поступления / оплаты">
                                        <span>• Встречные обороты &gt;10% &#65533;</span>
                                    </Tooltip>
                                </span> : null}
                            {(entity.Revenue && (Math.max(entity.inflows, entity.outflows) / (entity.Revenue * 1000) > 0.6)) ?
                                <span>
                                    <br />
                                    <Tooltip title="При сопоставлении ДДС с выручкой контрагента сранивается больший поток
                    (платежи или постуления) за все время анализа против выручки контрагента за последний
                    доступный год. Важные моменты: выручка может быть без НДС,
                    а денежный поток с НДС. Выручка и денежные потоки могли по-разному меняться во времени.">
                                        <span>• ДДС сопоставимы с выручкой контрагента &#65533;</span>
                                    </Tooltip>
                                </span> : null}
                        </span>
                        <CounterpartyLinks
                            filterCompany={setSelectedCompany}
                            entity={entity}
                        />
                        {/* <Divider />
                        <span style={{ color: '#1976d2', cursor: 'pointer' }} onClick={() => filterCompany({ TaxNumber: entity.CounterpartyINN, ShortName: entity.ShortName })}>
                            Платежи
                        </span>
                        &nbsp;|&nbsp;
                        <span>
                            {entity.OGRN &&
                                <a
                                    href={entity.OGRN ? `/${entity.OGRN}` : `/search?q=${entity.CounterpartyINN}`}
                                    target='_blank'
                                    style={{ textDecoration: 'none', color: '#1976d2' }}
                                >
                                    Карточка
                                </a>
                            }
                        </span> */}
                    </TableCell>
                    <TableCell variant="cashAnalysis">
                        {entity.StatusName}
                        {entity.DateLiquidation ? ' ' + parseAndFormatDate({ dateString: entity.DateLiquidation, sourceFormat: 'yyyy-MM-dd' }) : null}
                    </TableCell>
                    <TableCell variant="cashAnalysis">{validateNumber(entity.outflows / 1000)}</TableCell>
                    <TableCell variant="cashAnalysis">{validateNumber(entity.inflows / 1000)}</TableCell>
                    <TableCell variant="cashAnalysis">
                        {!entity.Revenue
                            ? <span>Нет данных</span>
                            : <span>{validateNumber(entity.Revenue)} в {entity.RevenueYear}</span>
                        }
                    </TableCell>
                    <TableCell variant="cashAnalysis">
                        {!entity.Headcount
                            ? <span>Нет данных</span>
                            : <span>{entity.Headcount} на {parseAndFormatDate({ dateString: entity.HeadcountDate, sourceFormat: 'yyyy-MM-dd' })}</span>
                        }
                    </TableCell>
                    <TableCell variant="cashAnalysis">{entity.ActualAddress}</TableCell>
                    <TableCell variant="cashAnalysis">{entity.OkvedName}</TableCell>
                    <TableCell variant="cashAnalysis">
                        {
                            (entity.CEOPositionName)
                                ? entity.CEOPositionName + ': '
                                : null
                        }
                        {
                            (entity.CEOFullName)
                                ? entity.CEOFullName + ' '
                                : null
                        }
                        {
                            (entity.CEOTaxNumber)
                                ? entity.CEOTaxNumber + ' '
                                : null
                        }
                    </TableCell>
                    <TableCell variant="cashAnalysis">
                        {entity.MassAddressRegister ? <p>Адрес массовой регистрации</p> : null}
                        {entity.InvalidAddressText ? <p>{entity.InvalidAddressText}</p> : null}
                        {entity.InvalidCEOInfoText ? <p>{entity.InvalidCEOInfoText}</p> : null}
                        {entity.CEODisqualificationEndDate ? <p>Дисквалификация руководителя до&nbsp;{parseAndFormatDate({ dateString: entity.CEODisqualificationEndDate, sourceFormat: 'yyyy-MM-dd' })}</p> : null}
                        {entity.InvalidFounderInfoText ? <p>{entity.InvalidFounderInfoText}</p> : null}
                    </TableCell>
                </TableRow>
            );
        }

        return rows;
    };
    return (
        <Box>
            {getFiltersList().length > 0 &&
                <Typography variant='body2' sx={{ my: '10px' }}>
                    Применены фильтры {getFiltersList().join(', ')}
                </Typography>
            }

            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell variant="cashAnalysisHead" sx={{ textAlign: 'center', minWidth: '250px', }}>Контрагент</TableCell>
                            <TableCell variant="cashAnalysisHead" sx={{ textAlign: 'center' }}>Статус на дату проверки</TableCell>
                            <TableCell variant="cashAnalysisHead" sx={{ textAlign: 'center' }}>
                                <TableSortLabel
                                    active={sortConfig.key === 'outflows'}
                                    direction={sortConfig.direction}
                                    onClick={() => handleSort('outflows')}
                                >
                                    Контрагенту перечислено (тыс.руб.)
                                </TableSortLabel>
                            </TableCell>
                            <TableCell variant="cashAnalysisHead" sx={{ textAlign: 'center' }}>
                                <TableSortLabel
                                    active={sortConfig.key === 'inflows'}
                                    direction={sortConfig.direction}
                                    onClick={() => handleSort('inflows')}
                                >
                                    От контрагента получено (тыс.руб.)
                                </TableSortLabel>
                            </TableCell>
                            <TableCell variant="cashAnalysisHead" sx={{ textAlign: 'center' }}>Выручка контрагента (тыс.руб.)</TableCell>
                            <TableCell variant="cashAnalysisHead" sx={{ textAlign: 'center' }}>Численность персонала (чел.)</TableCell>
                            <TableCell variant="cashAnalysisHead" sx={{ textAlign: 'center', minWidth: '250px', }}>Адрес контрагента</TableCell>
                            <TableCell variant="cashAnalysisHead" sx={{ textAlign: 'center', minWidth: '250px', }}>Основной ОКВЭД</TableCell>
                            <TableCell variant="cashAnalysisHead" sx={{ textAlign: 'center', minWidth: '250px', }}>Руководитель</TableCell>
                            <TableCell variant="cashAnalysisHead" sx={{ textAlign: 'center', minWidth: '250px', }}>Особые отметки</TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {renderTableRows()}
                    </TableBody>
                </Table>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 20]}
                    labelRowsPerPage='Компаний на странице'
                    component="div"
                    count={filteredRisks.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </TableContainer>
        </Box>
    );
}

export default RisksMainTable;