import React, { useState, useEffect } from 'react';
import { Box, Card, CardContent, CardHeader, Grid, Typography, CircularProgress, Tooltip, IconButton } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import ApiManager from '../utils/api';
import { format } from 'date-fns';
import { useSnackbar } from '../context/SnackbarContext';
import { myDebugPrint } from '../utils/debug';
import TablePagination from '@mui/material/TablePagination';
import CircleLoader from 'react-spinners/CircleLoader';
import { formatDateToGivenFormat, parseAndFormatDate, shareOfTransactions } from '../utils/utils';
import { createSearchParams, useNavigate } from "react-router-dom";
import { statusIcons } from './common/MyProcessingStatusIcon';


const CashProjectsInner = ({ projects, newtab = false }) => {
    const navigate = useNavigate();

    const handleInternalLink = (({ goal, searchParams }) => {
        if (!searchParams) {
            navigate({
                pathname: `/${goal}`,
            })
        } else {
            navigate({
                pathname: `/${goal}`,
                search: createSearchParams(searchParams).toString(),
            });
        }
    })

    if (!projects) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                <CircularProgress />
            </div>
        );
    }

    return (
        <Grid container spacing={2}>
            {projects.map((project) => {
                const createdAsString = project.CreatedOn ?
                    parseAndFormatDate({
                        dateString: (project.CreatedOn).slice(0, 10),
                        sourceFormat: 'yyyy-MM-dd'
                    }) : null;
                const updatedAsString = project.UpdatedOn ?
                    parseAndFormatDate({
                        dateString: (project.UpdatedOn).slice(0, 10),
                        sourceFormat: 'yyyy-MM-dd'
                    }) : null;
                let processingStatus = project.ProccessingStatus;
                let proccessingIcon = statusIcons.attention;
                if (!processingStatus) {
                    processingStatus = 'Обработка проекта остановлена. Свяжитесь с поддержкой info@brpadvice.ru'
                    proccessingIcon = statusIcons.attention;
                } else if (processingStatus === 'done') {
                    processingStatus = 'Готово'
                    proccessingIcon = statusIcons.ready
                } else if (processingStatus.includes('/')) {
                    processingStatus = 'Обработано ' + shareOfTransactions(processingStatus);
                    proccessingIcon = statusIcons.inProgress
                } else if (processingStatus.includes('error')) {
                    processingStatus = 'В процессе обработки произошла ошибка. Свяжитесь с info@brpadvice.ru';
                    proccessingIcon = statusIcons.errors
                }

                return (
                    <Grid item xs={12} key={project.ProjectId}>
                        <Card sx={{ marginTop: '10px' }}>
                            <CardHeader sx={{ marginBottom: 0, paddingBottom: 0 }}
                                avatar={
                                    <Tooltip title={processingStatus}>
                                        {proccessingIcon}
                                    </Tooltip>

                                }
                                title={
                                    <Typography variant='h6' color="primary" >
                                        <a
                                            style={{ textDecoration: 'none', cursor: 'pointer', color: '#1976D2' }}
                                            href={'\cash?project=' + project.ProjectId}
                                            target={newtab ? '_blank' : '_self'}
                                        >
                                            {project.ProjectName}
                                        </a>
                                    </Typography>
                                }
                            />
                            <CardContent>
                                {(project.CreatedOn || project.UpdatedOn) &&
                                    <Typography variant="body2">
                                        {project.CreatedOn &&
                                            <span><strong>Проект создан:</strong> {
                                                parseAndFormatDate({
                                                    dateString: project.CreatedOn.slice(0, 10),
                                                    sourceFormat: 'yyyy-MM-dd',
                                                    targetFormat: 'dd.MM.yyyy'
                                                })
                                            }
                                            </span>
                                        }
                                        {project.CreatedOn && project.UpdatedOn && <span>. </span>}
                                        {project.UpdatedOn &&
                                            <span><strong>Обновлен:</strong> {
                                                parseAndFormatDate({
                                                    dateString: project.UpdatedOn.slice(0, 10),
                                                    sourceFormat: 'yyyy-MM-dd',
                                                    targetFormat: 'dd.MM.yyyy'
                                                })
                                            }
                                            </span>
                                        }
                                    </Typography>
                                }
                                {project.MyTaxNumbersAndOgrnsAndNames &&
                                    <Typography variant="body2">
                                        <strong>Объект отчета:</strong> {project.MyTaxNumbersAndOgrnsAndNames}
                                    </Typography>
                                }
                                {!project.MyTaxNumbersAndOgrnsAndNames && project.MyTaxNumbers &&
                                    <Typography variant="body2">
                                        <strong>ИНН объектов отчета:</strong> {project.MyTaxNumbers.replaceAll('"', '')}
                                    </Typography>
                                }
                            </CardContent>
                        </Card>
                    </Grid>);
            })}
        </Grid>
    );
};

const CashProjectsList = () => {
    const [projects, setProjects] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [page, setPage] = useState(0);
    const [loading, setLoading] = useState(true);
    const showSnackbar = useSnackbar();
    const recordsPerPage = 10;
    const apiPageSize = 50;

    const fetchProjects = async (apiPage) => {
        setLoading(true);
        try {
            const response = await ApiManager.cashProjectsList({ page: apiPage + 1 });
            if (response && response.data && response.totalCount) {
                setProjects((prevProjects) => {
                    const existingProjectIds = new Set(prevProjects.map(p => p.ProjectId));
                    const newProjects = response.data.filter(project => !existingProjectIds.has(project.ProjectId));
                    // myDebugPrint(`ok, new projects are: ${JSON.stringify(newProjects)}`);
                    return [...prevProjects, ...newProjects];
                });
                setTotalCount(response.totalCount);
            } else {
                // myDebugPrint(`Пришел пустой ответ от сервера при запросе списка проектов}`);
                showSnackbar(`Невозможно загрузить список проектов. Попробуйте снова через несколько минут`);
            }
        } catch (error) {
            myDebugPrint(`Error fetching projects: ${JSON.stringify(error)}`);
            showSnackbar(`Невозможно загрузить список проектов. Попробуйте снова через несколько минут`);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchProjects(0);
    }, []);

    const handleChangePage = (event, newPage) => {
        const totalFetchedPages = Math.ceil(projects.length / recordsPerPage);
        if (newPage >= totalFetchedPages && projects.length < totalCount) {
            fetchProjects(Math.floor(projects.length / apiPageSize));
        }
        setPage(newPage);
    };

    const getVisibleProjects = () => {
        const startIndex = page * recordsPerPage;
        const endIndex = startIndex + recordsPerPage;
        return projects.slice(startIndex, endIndex);
    };

    return (
        <div>
            {loading ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                    <CircleLoader color="#8A4B9A" className='spinner' size={45} />
                </div>
            ) : (
                <>
                    <TablePagination
                        component="div"
                        count={totalCount}
                        rowsPerPage={recordsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        rowsPerPageOptions={[recordsPerPage]}
                    />
                    <CashProjectsInner projects={getVisibleProjects()} />
                    <TablePagination
                        component="div"
                        count={totalCount}
                        rowsPerPage={recordsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        rowsPerPageOptions={[recordsPerPage]}
                    />
                </>
            )}
        </div>
    );
};

export default CashProjectsList;
