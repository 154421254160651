import React, { useState, useEffect, useRef } from 'react';
import {
    Typography, Box,
    Table, TableBody, TableCell, TableContainer,
    TableHead, TableRow, Paper, IconButton, Tooltip,
} from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { formatDoubleToPaddedString } from '../../utils/utils';
import BusinessDetailsSkeleton from './BusinessDetailsSkeleton';
import Entity from '../../classes/Entity';
import MyFilterButton from '../common/MyFilterButton';
import './styles/Dashboard.css';
import { getDivisionProperty, Divisions, DivisionProperty } from '../../utils/vars';
import { defaultFinRepChartIndicators } from '../../classes/FinancialIndicators';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ChartIcon from '@mui/icons-material/ShowChart';
import { myDebugPrint } from '../../utils/debug';
import FinRepChart, { chartFinRepBarColors } from './FinRepChart';



const FinRepSection = ({ entity, analytics }) => {
    const [showAllActivities, setShowAllActivities] = useState(false);
    const [division, setDivision] = useState(1000);
    const [years, setYears] = useState([]);
    const [mapTableHeaders, setMapTableHeaders] = useState({});
    const [balanceSheet, setBalanceSheet] = useState(null);
    const [plStatement, setPlStatement] = useState(null);
    const [simplified, setSimplified] = useState(
        (entity && entity.simplifiedReports && entity.simplifiedReports.length > 0)
    );
    const [chartIndicators, setChartIndicators] = useState([...defaultFinRepChartIndicators]);
    const scrollController = useRef(null);

    useEffect(() => {
        initializeYears({ newDivision: false, newSimplified: false });
    }, [division, simplified, chartIndicators, years, entity]);

    const initializeYears = ({ newDivision = false, newSimplified = false, newYears = false }) => {

        if (!entity.finRepRows) {
            return;
        }

        if (!newDivision) newDivision = division ?? 1000;
        if (!newSimplified) newSimplified = simplified ?? false;
        if (!newYears) newYears = !years || years.length == 0 ? getInitialYears() : years;
        const tmpYears = newYears;
        const tmpMapTableHeaders = { '0_Показатель': 'Показатель' };
        tmpYears.forEach(year => {
            tmpMapTableHeaders['y_' + year.toString()] = year.toString();
        });

        const tmpBalanceSheet = newSimplified
            ? entity.buildFinSimplifiedRepMap({
                firstDigitInIndCode: 1,
                years: tmpYears,
                division: newDivision,
                roundDigits: 0
            })
            : entity.buildFinRepMap({
                firstDigitInIndCode: 1,
                years: tmpYears,
                division: newDivision,
                roundDigits: 0
            });

        const tmpPLStatement = newSimplified
            ? entity.buildFinSimplifiedRepMap({
                firstDigitInIndCode: 2,
                years: tmpYears,
                division: newDivision,
                roundDigits: 0
            })
            : entity.buildFinRepMap({
                firstDigitInIndCode: 2,
                years: tmpYears,
                division: newDivision,
                roundDigits: 0
            });

        setYears(tmpYears);
        setMapTableHeaders(tmpMapTableHeaders);
        setBalanceSheet(tmpBalanceSheet);
        setPlStatement(tmpPLStatement);
    };

    const getInitialYears = () => {
        const reports = entity.existingReports ?? [];
        const elementsToShow = Math.min(4, reports.length);
        const initialYears = reports.slice(-elementsToShow);

        if (entity.nonExistingReports) {
            return initialYears.filter(year => !entity.nonExistingReports.includes(year));
        }
        return initialYears;
    };

    const updateChartIndicators = (newValue) => {
        setChartIndicators((prev) => {
            const updated = prev.includes(newValue)
                ? prev.filter(indicator => indicator !== newValue)
                : prev.length < 7
                    ? [...prev, newValue]
                    : [...prev.slice(0, 6), newValue];
            return updated;
        });
    };

    const toggleDivision = (newDivision) => {
        setDivision(newDivision);
        initializeYears({ newDivision: newDivision });
    };

    const toggleSimplifier = () => {
        const newSimplified = !simplified;
        setSimplified(newSimplified);
        setChartIndicators([...defaultFinRepChartIndicators]);
        initializeYears({ newDivision: division, newSimplified: newSimplified });
    };

    const shiftYears = ({ offset = 0, showAll = false }) => {
        const reports = entity.existingReports ?? [];
        const minParsedYear = entity.minParsedYear ?? (new Date().getFullYear() - 1);
        const maxParsedYear = entity.maxParsedYear ?? 2017;

        if (showAll) {
            const newYears = Array.from({ length: maxParsedYear - minParsedYear + 1 }, (_, index) => minParsedYear + index);
            setYears(newYears);
            return;
        }

        const firstShownIndex = reports.indexOf(years[0]);
        const lastShownIndex = reports.indexOf(years[years.length - 1]);

        if (offset !== 0) {
            const newFirstIndex = firstShownIndex + offset;
            const newLastIndex = newFirstIndex + years.length;

            if (newFirstIndex >= 0 && newLastIndex <= reports.length) {
                const newYears = reports.slice(newFirstIndex, newLastIndex);
                setYears(newYears);
            }
        } else if (offset == 0 && !showAll) {
            const newFirstIndex = Math.max(0, years.length - 4);
            const newLastIndex = Math.min(years.length, newFirstIndex + 4);

            if (newFirstIndex >= 0 && newLastIndex <= reports.length) {
                const newYears = reports.slice(newFirstIndex, newLastIndex);
                setYears(newYears);
            }

        }
    };



    const buildTableRow = ({
        rowData,
        fromKeys = false,
        skipFirstVal = false,
        roundDigits = 0,
        header = false,
        tableKeyPrefix = '',
    }) => {
        let cells;
        if (!header) {
            cells = Object.entries(rowData).map(([key, value], index) => {
                const content = fromKeys ? key : value ?? 'н/д';
                let displayValue;
                if (key.startsWith("0_") && skipFirstVal) {
                    displayValue = ''
                } else if (key.startsWith("0_")) {
                    displayValue = value;
                } else {
                    displayValue = formatDoubleToPaddedString({
                        number: content,
                        roundDigits: roundDigits,
                        onErrorReturn: simplified ? '0' : 'н/д',
                    })
                };
                return (
                    <TableCell
                        key={tableKeyPrefix + (rowData['0_Показатель'] ?? key) + index}
                        align={index > 0 ? 'right' : 'left'}
                    >
                        {index === 0 && !skipFirstVal ? (
                            <IconButton onClick={() => updateChartIndicators(key)}>
                                <ChartIcon
                                    sx={{
                                        color: chartIndicators.includes(key)
                                            ? chartFinRepBarColors[chartIndicators.findIndex((element) => {
                                                return element == key;
                                            })]
                                            : 'inherit'
                                    }}
                                />
                            </IconButton>
                        ) : null}
                        {displayValue}
                    </TableCell>
                );
            });
            const m = Object.keys(rowData).filter(key => key.startsWith("0_"));
            return (
                <TableRow key={tableKeyPrefix + m ?? '' + header}>
                    {cells}
                </TableRow>
            );
        } else {
            cells = Object.entries(rowData).map(([key, value], index) => {
                const content = fromKeys ? key : value ?? 'н/д';
                let displayValue;
                if (key.startsWith("0_") && skipFirstVal) {
                    displayValue = ''
                } else if (key.startsWith("0_")) {
                    displayValue = 'Показатель';
                } else {
                    displayValue = key.replace("y_", "");
                };
                return (
                    <TableCell key={index} align={index > 0 ? 'right' : 'left'}>
                        {index === 0 && !skipFirstVal ? (
                            <IconButton onClick={() => updateChartIndicators(key)}>
                                <ChartIcon color={chartIndicators.includes(key)
                                    ? chartFinRepBarColors[chartIndicators.findIndex(key)]
                                    : 'inherit'} />
                            </IconButton>
                        ) : null}
                        {displayValue}
                    </TableCell>
                );
            });
            return (
                <TableRow>
                    {cells}
                </TableRow>
            );
        }

    };

    function buildWidgetsAfterTitleBeforeFilters() {
        let widget = [];
        if (entity.type !== 'businessIndividual') {
            widget.push(
                <Box>
                    <Box display="flex" alignItems="center" justifyContent="space-between">
                        <Typography variant="h5">Визуализация</Typography>
                        <Tooltip title="Чтобы добавить линию на график (или удалить ее), нажмите на названии нужного показателя в таблице с отчетом.">
                            <IconButton>
                                <HelpOutlineIcon sx={{ color: 'blue' }} />
                            </IconButton>
                        </Tooltip>
                    </Box>
                    <FinRepChart
                        years={years}
                        indicators={chartIndicators}
                        entity={entity}
                        simplified={simplified}
                    />
                </Box>
            );
        }
        return widget;

    }


    function buildFilteringWidgets() {
        let widget = [];
        if (entity.type !== 'businessIndividual') {
            widget.push(
                <Box>
                    <Typography variant="h6">Отчеты в табличном виде</Typography>
                    <Box display="flex">
                        <MyFilterButton onClick={toggleSimplifier} title="Стандартный вид" active={!simplified} />
                        <MyFilterButton onClick={toggleSimplifier} title="Упрощенный вид" active={simplified} />
                    </Box>
                    <Box display="flex" mt={2}>
                        <MyFilterButton onClick={() => toggleDivision(1)} title="в руб." active={division === 1} />
                        <MyFilterButton onClick={() => toggleDivision(1000)} title="в тыс. руб." active={division === 1000} />
                        <MyFilterButton onClick={() => toggleDivision(1000000)} title="в млн. руб." active={division === 1000000} />
                    </Box>
                </Box>);
        };
        return widget;
    }

    function buildMainContent() {
        let widget = [];
        if (entity.type === 'businessIndividual') {
            widget.push(
                <Typography variant="body1">
                    ИП не сдают финансовую отчетность. Вы можете запросить у ИП копии последней сданной налоговой отчетности.
                </Typography>
            )
        } else if (!entity.finRepRows) {
            const createdYear = entity.dateOgrn.getFullYear();
            const thisYear = new Date().getFullYear();
            if (entity.dateOgrn && createdYear === thisYear) {
                widget.push(
                    <Typography variant="body1">
                        Организация создана в этом году и еще не сдавала годовую финансовую отчетность.
                    </Typography>
                )
            } else {
                widget.push(
                    <Typography variant="body1">
                        Актуальная финансовая отчетность не найдена в данных ФНС.
                    </Typography>
                )
            }
        } else {
            widget.push(
                <Box>
                    {buildYearsShiftLinks()}
                    <Typography variant="h5">Баланс</Typography>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                {buildTableRow({
                                    rowData: mapTableHeaders,
                                    header: true,
                                    skipFirstVal: true,
                                    tableKeyPrefix: 'balanceSheet'
                                })}
                            </TableHead>
                            <TableBody>
                                {balanceSheet && balanceSheet.map(row =>
                                    buildTableRow({
                                        rowData: row,
                                        roundDigits: 0,
                                        tableKeyPrefix: 'balanceSheet'
                                    })
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            );
            widget.push(
                <Box mt={4}>
                    <Typography variant="h5">Финансовые результаты</Typography>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                {buildTableRow({
                                    rowData: mapTableHeaders,
                                    header: true,
                                    skipFirstVal: true,
                                    tableKeyPrefix: 'pl'
                                })}
                            </TableHead>
                            <TableBody>
                                {plStatement && plStatement.map(row =>
                                    buildTableRow({
                                        rowData: row,
                                        roundDigits: 0,
                                        tableKeyPrefix: 'pl'
                                    })
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            )
        }
        return widget;

    }


    const buildYearsShiftLinks = () => {
        const minYear = entity.minParsedYear;
        const maxYear = entity.maxParsedYear;

        return (
            <Box display="flex" alignItems="center">
                {minYear != null && minYear < Math.min(...years) ? (
                    <IconButton onClick={() => shiftYears({ offset: -1 })}>
                        <ArrowBackIosIcon />
                    </IconButton>
                ) : (
                    <Box width={25} height={25} />
                )}
                {minYear != null && maxYear != null && (
                    <Box flex={1} display="flex" justifyContent="center">
                        {(minYear < Math.min(...years) || maxYear > Math.max(...years) || years.length > 4) && (
                            <Box display="flex" alignItems="center"
                                onClick={() => shiftYears({
                                    showAll: !(years.length > 4)
                                })}>
                                <Typography style={{ cursor: 'pointer' }}>
                                    {years.length > 4 ? 'Свернуть' : 'Развернуть'}
                                </Typography>
                            </Box>
                        )}
                    </Box>
                )}
                {maxYear != null && maxYear > Math.max(...years) ? (
                    <IconButton onClick={() => shiftYears({ offset: 1 })}>
                        <ArrowForwardIosIcon />
                    </IconButton>
                ) : (
                    <Box width={25} height={25} />
                )}
            </Box>
        );
    };

    return (
        <BusinessDetailsSkeleton
            entityName={entity.name}
            h1="Финансовая отчетность"
            scrollController={scrollController}
            widgetsAfterTitleBeforeFilters={
                entity.finRepRows ? buildWidgetsAfterTitleBeforeFilters() : null}
            mainContent={buildMainContent()}
            filteringWidgets={entity.finRepRows ? buildFilteringWidgets() : null}
        />
    );
};

export default FinRepSection;
