// components/AnalysisReasons.js
import React from 'react';
import { Container, Grid, Typography, Box } from '@mui/material';

function AnalysisReasons() {
    return (
        <Box>
            <Container maxWidth="lg">
                <Typography variant="h2" gutterBottom>
                    Зачем нужен анализ выписки
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Анализ банковских выписок позволяет увидеть, откуда приходили и как расходовались денежные средства. Анализ выявляет операции, совершенные с аффилированными контрагентами, подозрительные сделки, платежи организациям или ИП потенциально связанным с бенефициарами или менеджментом, а также особые категории операций – займы, цессии и другие.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Мы проводим анализ банковских выписок, в которых содержатся как тысячи, так и миллионы операций. Наши отчеты помогают осуществлять внутренний контроль и аудит. Результаты анализа можно использовать для отчетов о ходе процедуры банкротства, представлять в суд.
                </Typography>
            </Container>
        </Box>
    );
}

export default AnalysisReasons;
