import React from 'react';
import corpDrawerTaps from '../../../classes/CorpDrawerTaps';
import { Typography, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';


export default function CorpDrawer({ open, companyName, activeSection, onSectionChange }) {
    return (<div>
        <Typography
            variant="body2" // Adjust the variant as needed
            sx={{ fontSize: '1rem', marginLeft: '20px', marginTop: 1, textAlign: 'left' }} // This should affect the font size
        >
            {open ? companyName : "\u00A0"}
        </Typography>
        <List>
            {Object.entries(corpDrawerTaps).map(([key, { title, icon: Icon }]) => {
                return (
                    <ListItem key={key} disablePadding sx={{ display: 'block', }}>
                        <ListItemButton
                            sx={{
                                minHeight: 48,
                                justifyContent: open ? 'initial' : 'center',
                                px: 2.5,
                            }}
                            onClick={() => onSectionChange(key)}
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: open ? 3 : 'auto',
                                    justifyContent: 'center',
                                    color: key == activeSection ? 'green' : '',
                                }}
                            >
                                <Icon />
                            </ListItemIcon>
                            <ListItemText primary={<Typography
                                variant="body2"
                                sx={{
                                    fontSize: '0.8rem',
                                    color: key == activeSection ? 'green' : '',
                                    fontWeight: key == activeSection ? 'bold' : '',
                                }}
                            >
                                {title}
                            </Typography>} sx={{
                                opacity: open ? 1 : 0
                            }} />
                        </ListItemButton>
                    </ListItem>
                );
            })}
        </List>
    </div>);
}